import React, { CSSProperties, FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import theme from '../../style/Theme';
import Utils, { stringToDate } from '../../helper/Utils';
import {
  Badge,
  Button,
  ButtonProps,
  Chip,
  Container,
  DialogProps,
  Divider,
  IconButton,
  List,
  Paper,
  Popover,
  PopoverOrigin,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { IdLabel } from '../../interface/CommonType';
import './../../index.scss';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
//#region icons
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
//#endregion
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { CheckBoxOutlineBlank, CheckBoxOutlined, VisibilityOff } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import { GlobalContext } from '../../context/Global.context';
import CmsIcon from './CmsIcon';
import './shared.scss';
import { FieldValues, FormState } from 'react-hook-form';
import { Breakpoint } from '@mui/system';

/**
 * Conteneur de base surchargé pour abstraction des composants material-ui
 * @param children l'enfant à afficher
 * @param style Optionel, surcharge le css initial
 */
export const CmsContainer: FC<{ children: any; style?: object }> = ({ children, style = {} }) => (
  <Container style={{ padding: '1.5em', ...style }} maxWidth={false}>
    {children}
  </Container>
);

interface PaperProps {
  // Titre du papier, affiché en haut à gauche
  title?: string | ReactNode;
  // Type de papier, détermine le style (elevation, outlined)
  variant?: 'elevation' | 'outlined';
  // Type de contenu, détermine le style (standard, createForm, editForm, list)
  contentType?: 'standard' | 'createForm' | 'editForm' | 'list';
  // Type de titre, détermine le style (h1, h2, h3, h4, h5, h6)
  headerVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  // Contenu du papier, enfant à afficher
  children: ReactNode;
  // Classe css à appliquer au papier
  className?: string;
  // Actions (boutons ou info) à afficher en haut à droite
  actions?: ReactNode[];
  // Surcharge du style css
  style?: React.CSSProperties;
  // Si le papier est lié à un autre papier, détermine si le papier est collé au-dessus ou non
  tied?: boolean;
  // Si le papier est lié à un autre papier, détermine si le papier est de niveau 2 ou non
  secondLvl?: boolean;
  // Prend la hauteur de son parent moins la marge
  fluidHeight?: boolean;
  // Surcharge du style css du header
  headerStyle?: React.CSSProperties;
  // Si le papier est en chargement, affiche un loader
  isLoading?: boolean;
  // Si le papier est scrollable, affiche un scroll
  scrollable?: boolean;
  // Fonction à appeler au clic sur le titre
  onTitleClick?: any;
}

/**
 * Papier de base surchargé pour abstraction des composants material-ui
 * @param title Titre du papier, affiché en haut à gauche
 * @param variant Type de papier, détermine le style (elevation, outlined)
 * @param headerVariant Type de titre, détermine le style (h1, h2, h3, h4, h5, h6)
 * @param contentType Type de contenu, détermine le style (standard, createForm, editForm, list)
 * @param children Contenu du papier, enfant à afficher
 * @param className Classe css à appliquer au papier
 * @param actions Actions (boutons ou info) à afficher en haut à droite
 * @param style Surcharge du style css
 * @param tied Si le papier est lié à un autre papier, détermine si le papier est collé au-dessus ou non
 * @param secondLvl Si le papier est lié à un autre papier, détermine si le papier est de niveau 2 ou non
 * @param fluidHeight Prend la hauteur de son parent moins la marge
 * @param headerStyle Surcharge du style css du header
 * @param isLoading Si le papier est en chargement, affiche un loader
 * @param scrollable Si le papier est scrollable, affiche un scroll
 * @param onTitleClick Fonction à appeler au clic sur le titre
 */
export const CmsPaper: FC<PaperProps> = ({
  title,
  variant,
  headerVariant,
  contentType = 'standard',
  children,
  className = '',
  actions = [],
  style = {},
  tied = false,
  secondLvl = false,
  fluidHeight = false,
  headerStyle,
  isLoading = false,
  scrollable = false,
  onTitleClick,
}) => {
  const { theming } = useContext(GlobalContext);
  if (!!onTitleClick)
    title = (
      <div className="clickable" onClick={onTitleClick}>
        {title}
      </div>
    );
  let backgroundColor = secondLvl ? theming.get().cms.main.paperDif : theming.get().cms.main.paper;
  return (
    <Paper
      style={{
        marginBottom: tied ? '1em' : '1.5em',
        backgroundColor,
        height: fluidHeight ? `calc(100% - ${tied ? '1em' : '1.5em'})` : 'auto',
        ...style,
      }}
      elevation={secondLvl ? 0 : 3}
      className={className + ' flex-v paper-base'}
      variant={secondLvl ? 'outlined' : variant || 'elevation'}
    >
      {title && (
        <SectionTitle
          title={title}
          headerStyleSurcharge={headerStyle}
          variant={headerVariant}
          contentType={contentType}
          actions={actions.map((action, i) => (
            <span key={`action-${title}-${i}`} style={{ marginLeft: '0.5em', display: 'block' }}>
              {action}
            </span>
          ))}
        />
      )}
      {(!scrollable && children) || <div className="paper-scroll styled-scroll flex-v">{children}</div>}
      {isLoading && <PaperLoaderScreen />}
    </Paper>
  );
};

interface FormProps {
  // Contenu du formulaire, enfant à afficher
  children: any;
  // Si le formulaire est en chargement, affiche un skeleton
  isLoading: boolean;
  // Nombre de champs à afficher (utilisé pour le skeleton)
  fieldNumber: number;
  // Nombre de colonnes à afficher (utilisé pour le skeleton)
  columnNumber?: number;
  className?: string;
}

/**
 * Formulaire de base simple contenant un skeleton pour le chargement
 * @param children Contenu du formulaire, enfant à afficher
 * @param isLoading Si le formulaire est en chargement, affiche un skeleton
 * @param fieldNumber Nombre de champs à afficher (utilisé pour le skeleton)
 * @param columnNumber Nombre de colonnes à afficher (utilisé pour le skeleton)
 * @param className Classe css à appliquer au formulaire
 */
export const Form: FC<FormProps> = ({ children, isLoading, fieldNumber, columnNumber = 1, className }) => {
  if (!isLoading) return <div className={className}>{children}</div>;
  return (
    <div className={'skeleton-container ' + className}>
      {[...Array(columnNumber)].map((_, i) => (
        <span key={i} style={{ width: Math.floor(100 / columnNumber) + '%' }}>
          {[...Array(fieldNumber)].map((_, idx) => (
            <FormFieldSkeleton key={idx} />
          ))}
        </span>
      ))}
    </div>
  );
};

// Morceau de skeleton pour un champ de formulaire, à l'apparence d'un Formfield
const FormFieldSkeleton: FC = () => (
  <FormField>
    <Skeleton style={{ width: '15%', height: '20px' }} />
    <Skeleton style={{ width: '100%', height: '40px' }} />
  </FormField>
);

const PaperLoaderScreenCss: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: '4px',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.2)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

// Loader pour un papier, le loader est une animation de chargement circulaire centrée sur le papier
export const PaperLoaderScreen: FC = () => (
  <div style={PaperLoaderScreenCss}>
    <CircularProgress size={80} style={{ zIndex: 90 }} />
  </div>
);

// Équivalent à un HR html, affiche une ligne horizontale
export const CmsDivider: FC<{ style?: CSSProperties }> = ({ style = {} }) => (
  <Divider style={{ marginTop: '0.8em', marginBottom: '0.8em', ...style }} />
);

export const Waiting: FC<{ isLoading: boolean; debounce?: number }> = ({ isLoading, debounce = 330 }) => {
  const [debouncedLoading, setDebouncedLoading] = React.useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedLoading(true), debounce);
    if (!isLoading) {
      setDebouncedLoading(false);
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [debounce, isLoading]);
  if (!debouncedLoading) return <></>;
  return (
    <div className="cms-fixed-waiting">
      <CircularProgress size={200} />
    </div>
  );
};

// const labelValueStyle = css({
//   overflow: 'hidden',
//   whiteSpace: 'nowrap',
//   textOverflow: 'ellipsis',
//   '& .label': {
//     fontWeight: 'bold',
//   },
// });

interface LabelValueUIProps {
  // Libellé à afficher
  label: ReactNode;
  // Enfant à afficher
  children: ReactNode;
  // Si true, n'affiche pas de marge en bas
  noSpacing?: boolean;
  // Surcharge du style css
  style?: CSSProperties;
}

/**
 * Affiche un label et une valeur, la valeur peut être cacher si elle est trop longue (avec un ellipsis),
 * l'affichage est en ligne
 * @param label Libellé à afficher
 * @param children Enfant à afficher
 * @param noSpacing Si true, n'affiche pas de marge en bas
 * @param style Surcharge du style css
 */
export const LabelValueUI: FC<LabelValueUIProps> = ({ label, children, noSpacing = false, style = {} }) => (
  <span className="label-value-ui" style={{ marginBottom: noSpacing ? 'inherit' : '0.2em', ...style }}>
    <span className="label">{label} : </span>
    {children}
  </span>
);

/**
 * Affiche un label et une valeur, l'affichage est en colonne
 * @param label Libellé à afficher
 * @param children Enfant à afficher
 * @param style Surcharge du style css
 */
export const LabelBlockValue: FC<{ label: string; children: ReactNode; style?: object }> = ({
  label,
  children,
  style = {},
}) => (
  <div style={{ marginBottom: '0.2em', ...style }}>
    <div style={{ marginBottom: '0.2em', fontWeight: 'bold' }}>{label} :</div>
    <div style={{ paddingLeft: '1em' }}>{children}</div>
  </div>
);

/**
 * Affiche un label et une valeur, la valeur est binaire (true/false)
 * @param label Libellé à afficher
 * @param value Valeur booléenne
 * @param style Surcharge du style css
 */
export const BoolIconLabelValue: FC<{ label: string; value: boolean; style?: object }> = ({
  label,
  value,
  style = {},
}) => (
  <LabelValueUI label={label} style={{ display: 'flex', alignItems: 'center', ...style }}>
    <span style={{ marginLeft: '0.5em' }}>
      <BoolIcon value={value} />
    </span>
  </LabelValueUI>
);

/**
 * Affiche une icône en fonction de la valeur booléenne
 * @param value Valeur booléenne
 */
export const BoolIcon: FC<{ value: boolean }> = ({ value }) =>
  value ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />;

const ChipInfoStype = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid',
  borderRadius: '5px',
  padding: '0.2em 0.5em',
  marginBottom: '0.5em',
};

/**
 * Affiche un label et une icône, la couleur du label et de l'icône est définie en fonction de la valeur
 * @param icon Icône à afficher
 * @param label Libellé à afficher
 * @param isValid Si true, la couleur est verte, sinon elle est rouge
 */
export const ChipInfo: FC<{ icon: ReactNode; label: string; isValid?: boolean }> = ({ icon, label, isValid }) => {
  const { theming } = useContext(GlobalContext);
  const color = isValid ? theming.get().cms.severity.valid : theming.get().cms.severity.error;
  return (
    <span style={{ ...ChipInfoStype, borderColor: color, color: color }}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <span style={{ margin: '0 0.3em' }}>{` ${label}`}</span>
      </span>
      {isValid ? <CheckIcon style={{ color }} /> : <ClearIcon style={{ color }} />}
    </span>
  );
};

/**
 * Surcharge du composant Chip de Material UI
 * @param label Libellé à afficher
 * @param value Valeur à afficher
 * @param style Surcharge du style css
 */
export const CMSChip: FC<{ label: string; value: any; style?: React.CSSProperties }> = ({ label, value, style }) => (
  <Chip
    size="medium"
    className="cms-chip"
    style={{ ...style, fontSize: '1em' }}
    label={
      <>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>{label}</span>
        <span>{value}</span>
      </>
    }
  />
);

// export const NoContentMessage = styled.div({
//   textAlign: 'center',
//   fontStyle: 'italic',
//   marginTop: '1.5em',
//   marginBottom: '0.5em',
//   color: theme.palette.text.primary,
// });

export const NoContentMessage: FC<{ children: ReactNode }> = ({ children }) => {
  const { theming } = useContext(GlobalContext);
  return (
    <div
      style={{
        textAlign: 'center',
        fontStyle: 'italic',
        marginTop: '1.5em',
        marginBottom: '0.5em',
        color: theming.get().palette.text.primary,
      }}
    >
      {children}
    </div>
  );
};

/**
 * Affiche un titre de page formatter
 * @param children Enfant à afficher (titre de la page en string ou composant)
 */
export const PageTitle: FC<{ children: ReactNode }> = ({ children }) => {
  const { theming } = useContext(GlobalContext);
  return (
    <Typography
      variant="h4"
      style={{ textAlign: 'center', color: theming.get().palette.text.primary, marginBottom: '0.5em' }}
    >
      {children}
    </Typography>
  );
};

const headerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '-1em -1em 0 -1em',
  padding: '0.5em 1em',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  alignItems: 'center',
  marginBottom: '1em',
};

const titleIconStyle = {
  fontSize: '1.3em',
  margin: '-0.3em 0.3em -0.2em 0',
};

/**
 * Affiche un titre de section
 * @param variant Variant du titre (h1, h2, h3, h4, h5, h6)
 * @param title Titre à afficher
 * @param actions Actions à afficher (en haut à droite)
 * @param contentType Type de contenu (standard, createForm, editForm, list)
 * @param headerStyleSurcharge Surcharge du style css
 */
export const SectionTitle: FC<{
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  title: string | React.ReactNode;
  headerStyleSurcharge?: React.CSSProperties;
  actions?: ReactNode[];
  contentType?: 'standard' | 'createForm' | 'editForm' | 'list';
}> = ({ variant, title, actions = [], contentType = 'standard', headerStyleSurcharge = {} }) => {
  const isMobile = useMemo(() => Utils.isMobileBrowser(), []);
  const { theming } = useContext(GlobalContext);
  const backgroundColor = theming.get().cms.main.sectionTitle;
  let buttonsListStyle = 'flex-h item-center';
  if (isMobile) {
    buttonsListStyle = 'flex-v item-center';
    headerStyle.flexDirection = 'column';
  }
  return (
    <div style={{ ...headerStyle, backgroundColor, ...headerStyleSurcharge }}>
      <Typography variant={variant || 'h6'} style={{ color: theming.get().palette.text.primary, fontWeight: 600 }}>
        <TitleIcon contentType={contentType} style={titleIconStyle} />
        {title}
      </Typography>
      <span className={buttonsListStyle} style={{ textAlign: 'right' }}>
        {actions.map((action, i) => (
          <span style={{ marginBottom: isMobile ? '1em' : 0 }} key={`section-title-action-${i}`}>
            {action}
          </span>
        ))}
      </span>
    </div>
  );
};

/**
 * Affiche une icône en fonction du type de contenu
 * @param contentType Type de contenu (standard, createForm, editForm, list)
 * @param style Surcharge du style css
 */
const TitleIcon: FC<{ contentType: string; style: any }> = ({ contentType, style }) => {
  switch (contentType) {
    case 'createForm':
      return <AddIcon style={style} />;
    case 'editForm':
      return <EditIcon style={style} />;
    case 'list':
      return <LineWeightIcon style={style} />;
    default:
      return <></>;
  }
};

interface CmsDialogProps extends DialogProps {
  // Ajoute un fond à la modal
  withBackground?: boolean;
  // Définit la largeur de la modal, true = prends toute la largeur de l'écran
  fullWidth?: boolean;
  // Fonction à appeler lors de la fermeture de la modal
  onClose?: any;
  // Définit la largeur maximale de la modal
  maxWidth?: Breakpoint | false;
}

/**
 * Surcharge du composant Dialog de Material UI
 * @param children Enfant à afficher
 * @param props Props du composant, se référer à CmsDialogProps
 */
export const CmsDialog: FC<CmsDialogProps> = ({ children, ...props }) => {
  const { theming } = useContext(GlobalContext);
  return (
    <Dialog
      maxWidth={props.maxWidth ?? 'lg'}
      fullWidth={props?.fullWidth ?? false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={props.onClose}
      {...props}
    >
      {(props?.withBackground && (
        <DialogContent style={{ backgroundColor: theming.get().cms.main.paper }}>{children}</DialogContent>
      )) ||
        children}
    </Dialog>
  );
};

const errorPaperStyle = {
  padding: '1em',
  backgroundColor: 'rgba(255, 121, 97, 0.08)',
  borderColor: theme.palette.error.main,
};

/**
 * Affiche un message d'erreur
 * @param title Titre du message d'erreur
 * @param children Enfant à afficher
 * @param style Surcharge du style css
 */
export const ErrorPaper: FC<{ title: string; children: ReactNode; style?: object }> = ({
  title,
  children,
  style = {},
}) => {
  const { theming } = useContext(GlobalContext);
  return (
    <Paper style={{ ...errorPaperStyle, ...style, marginBottom: '1.5em' }} variant="outlined">
      <Typography
        variant="h6"
        style={{ color: theming.get().palette.text.primary, lineHeight: 'normal', fontSize: '1.2rem' }}
      >
        {title}
      </Typography>
      <CmsDivider style={{ marginTop: 0, marginBottom: '0.5em' }} />
      {children}
    </Paper>
  );
};

const infoPaperStyle = {
  padding: '1em',
  backgroundColor: 'rgba(97, 231, 255, 0.5)',
  borderColor: theme.palette.primary.main,
};

/**
 * Affiche un message d'information
 * @param title Titre du message d'information
 * @param style Surcharge du style css
 */
export const InfoPaper: FC<{ title: string; style?: object }> = ({ title, style = {} }) => {
  const { theming } = useContext(GlobalContext);
  return (
    <Paper style={{ ...infoPaperStyle, ...style, marginBottom: '1.5em' }} variant="outlined">
      <Typography
        variant="h6"
        style={{ color: theming.get().palette.text.primary, lineHeight: 'normal', fontSize: '1.2rem' }}
      >
        {title}
      </Typography>
    </Paper>
  );
};

// TODO: migrer vers Buttons.tsx
export interface CmsButtonProps extends ButtonProps {
  // Difine de type de composant dont le bouton sera fait (div, a, button, etc.)
  component?: any;
  // Lien hypertexte (équivalent à un href)
  to?: string;
  // Lien react-router (navigation programmatique sans rechargement de la page)
  target?: string;
  toolTip?: string;
  toolTipPlacement?: TooltipProps['placement'];
}

/**
 * TODO : migrer vers Buttons.tsx
 * Surcharge du composant Button de Material UI
 * @param children Enfant à afficher
 * @param size Taille du bouton (par défaut small)
 * @param style Surcharge du style css
 * @param color Couleur du bouton (par défaut default/gris)
 * @param startIcon Icône à afficher à gauche du bouton
 * @param toolTip Texte d'aide au survol
 * @param toolTipPlacement Position de l'aide
 * @param props Props du composant, se référer à ButtonProps
 */
export const CmsButton: FC<CmsButtonProps> = ({
  children,
  size,
  style,
  color,
  startIcon,
  toolTip,
  toolTipPlacement,
  ...props
}) => {
  const button = (
    <Button
      startIcon={startIcon || null}
      size={size || 'small'}
      color={color || undefined}
      variant="outlined"
      style={style || {}}
      {...props}
    >
      {children}
    </Button>
  );
  if (!toolTip) return button;
  return (
    <Tooltip title={toolTip} placement={toolTipPlacement}>
      {button}
    </Tooltip>
  );
};

export const SimplePre: FC<{ children: ReactNode }> = ({ children }) => (
  <pre style={{ fontFamily: 'inherit' }}>{children}</pre>
);

/**
 * Bandeau d'affichage d'informations en haut de page généralement (mais pas obligatoirement)
 * @param title Titre du bandeau
 * @param subtitle Sous-titre du bandeau
 * @param actions Actions à afficher à droite du bandeau
 * @param wrap Définit si les actions doivent être affichées sur plusieurs lignes ou non si elles ne tiennent pas sur une seule ligne
 */
export const HeaderPanel: FC<{ title?: string; subtitle?: string; actions?: ReactNode[]; wrap?: boolean }> = ({
  title,
  subtitle,
  actions = [],
  wrap = false,
}) => {
  const { theming } = useContext(GlobalContext);
  return (
    <CmsPaper
      className="header-panel"
      style={{ flexFlow: wrap ? 'row wrap' : 'row nowrap', backgroundColor: theming.get().cms.main.sectionTitle }}
    >
      <Typography variant="h4">
        {title} {subtitle && <small style={{ color: theming.get().palette.text.secondary }}>{subtitle}</small>}
      </Typography>
      <span className="header-actions-container" style={{ display: 'flex', alignItems: 'center' }}>
        {actions.map((action, i) => (
          <span className="header-actions" key={`header-pannel-action-${i}`}>
            {action}
          </span>
        ))}
      </span>
    </CmsPaper>
  );
};

interface DeployIconProps {
  // Valeur du bouton (true = flèche vers le haut, false = flèche vers le bas)
  value: boolean;
  // Fonction à appeler lors du clic sur le bouton
  onchange: any;
  // Surcharge du style css
  style?: CSSProperties;
  // Taille du bouton (par défaut medium)
  size?: 'small' | 'medium';
}

/**
 * Affiche un bouton avec une icône de déploiement (flèche vers le haut ou vers le bas)
 * @param value Valeur du bouton (true = flèche vers le haut, false = flèche vers le bas)
 * @param onchange Fonction à appeler lors du clic sur le bouton
 * @param style Surcharge du style css
 * @param size Taille du bouton (par défaut medium)
 */
export const DeployIcon: FC<DeployIconProps> = ({ value, onchange, style, size = 'medium' }) => (
  <CmsIcon icon={value ? 'arrowUp' : 'arrowDown'} style={style} onClick={() => onchange(!value)} />
);

/**
 * Affiche un bouton avec une icône de visibilité (oeil ou oeil barré)
 * @param is Définit si l'icône doit être un oeil ou un oeil barré (true: oeil ouvert)
 */
export const IsVisibleIcon: FC<{ is: boolean }> = ({ is }) => {
  return is ? <VisibilityIcon /> : <VisibilityOff />;
};

/**
 * Affiche un bouton avec une icône de de flèche pour faire comprendre que cela ouvert dans un nouvel onglet
 * @param title Titre du bouton (sera affiché au survol)
 * @param placement Position du titre par rapport au bouton
 * @param href Lien hypertexte
 * @param style Surcharge du style css
 */
export const OpenInNewIconButton: FC<{
  title?: string;
  placement?: TooltipProps['placement'];
  href: string;
  style?: object;
}> = ({ title = 'Ouvrir dans un nouvel onglet', placement = 'top', href, style = {} }) => (
  <Tooltip title={title} placement={placement} arrow>
    <Link href={href} target="_blank" style={{ verticalAlign: 'middle' }}>
      <OpenInNewIcon style={style} />
    </Link>
  </Tooltip>
);

interface ColorDateLimitProps {
  // Date à afficher et calculer
  date: string | Date;
  // Limite de couleur 1 (en jours)
  limit1?: number;
  // Limite de couleur 2 (en jours)
  limit2?: number;
  // Limite de couleur 3 (en jours)
  limit3?: number;
}

/**
 * Affiche une date avec une couleur en fonction de la date, si la date est dépassée, la couleur est rouge
 * @param date Date à afficher et calculer
 * @param limit1 Limite de couleur 1 (en jours) (par défaut 90 bleu)
 * @param limit2 Limite de couleur 2 (en jours) (par défaut 30 jaune)
 * @param limit3 Limite de couleur 3 (en jours) (par défaut 15 orange)
 */
export const ColorDateLimit: FC<ColorDateLimitProps> = ({ date, limit1 = 90, limit2 = 30, limit3 = 15 }) => {
  const dateValue = date;
  if (!date || date === '' || date === '01/01/1970') return <></>;
  if (typeof date == 'string') date = stringToDate(date);
  let backgroundColor = 'inherit';
  const dayTime = 86400000; // Number of Milliseconde in one day
  if (date.getTime() - dayTime * limit1 < Date.now()) backgroundColor = 'blue';
  if (date.getTime() - dayTime * limit2 < Date.now()) backgroundColor = 'yellow';
  if (date.getTime() - dayTime * limit3 < Date.now()) backgroundColor = 'orange';
  if (date.getTime() < Date.now()) backgroundColor = 'red';
  let color = 'inherit';
  if (backgroundColor === 'yellow' || backgroundColor === 'orange') color = 'black';
  else if (backgroundColor === 'blue' || backgroundColor === 'red') color = 'white';
  if (backgroundColor)
    return (
      <span className="colored-background" style={{ backgroundColor, color }}>
        {dateValue?.toString()}
      </span>
    );
  return <span>{dateValue?.toString()}</span>;
};

interface WarningBubbleProps {
  // Contenu de la bulle d'information avec un fond de couleur
  children: ReactNode;
  // Couleur de la bulle d'information
  customColor?: string;
  // Type de bulle d'information (info, error, warn)
  type?: 'info' | 'error' | 'warn';
  // Fonction à appeler lors du clic sur la bulle d'information
  onClick?: any;
  // Surcharge du style css
  style?: CSSProperties;
}

/**
 * Affiche une bulle d'information avec un fond de couleur
 * @param children Contenu de la bulle d'information avec un fond de couleur
 * @param customColor Couleur de la bulle d'information (surcharge le type)
 * @param type Type de bulle d'information (info, error, warn) => ()
 * @param onClick Fonction à appeler lors du clic sur la bulle d'information
 * @param style Surcharge du style css
 */
export const WarningBubble: FC<WarningBubbleProps> = ({ children, customColor, type, onClick, style }) => {
  const { theming } = useContext(GlobalContext);
  if (type) customColor = { info: '#34758e', error: '#8d2525', warn: '#aa9823' }[type];
  return (
    <div
      onClick={onClick}
      className={'warning-bubble ' + (type ?? 'warn')}
      style={{
        borderRadius: '16px',
        height: 'fit-content',
        border: '3px solid ' + (customColor ?? theming.get().palette.warning.main),
        backgroundColor: theming.get().cms.main.paper,
        padding: '6.5px 10px',
        margin: '0 6px',
        cursor: !!onClick ? 'pointer' : 'inherit',
        color: theming.get().palette.text.primary,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

interface SelectOptionsProps extends IdLabel {
  // Définit si le bouton doit avoir un badge
  badge?: boolean;
  // Valeur du badge
  badgeValue?: number;
}

interface SelectBetweenButtonProps {
  // Liste des options [ { id: number, label: string, badge: boolean, badgevalue: number } ]
  options: Array<SelectOptionsProps>;
  // Valeur sélectionnée
  value: any;
  // Fonction à appeler lors du changement de valeur
  onChange: any;
}

/**
 * Affiche une liste de boutons avec une valeur sélectionnée
 * @param options Liste des options [ { id: number, label: string, badge: boolean, badgevalue: number } ]
 * @param value Valeur sélectionnée
 * @param onChange Fonction à appeler lors du changement de valeur
 */
export const SelectBetweenButton: FC<SelectBetweenButtonProps> = ({ options, value, onChange }) => {
  if (!options) return <></>;
  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: any) => onChange(newAlignment);
  return (
    <ToggleButtonGroup color="primary" size="small" value={value} exclusive onChange={handleChange}>
      {options.map((x: SelectOptionsProps, i) => {
        if (!x.badge) {
          return (
            <ToggleButton key={i} value={x.id}>
              {x.label}
            </ToggleButton>
          );
        } else {
          return (
            <ToggleButton key={i} value={x.id}>
              <Badge key={i} color="secondary" badgeContent={x.badgeValue} max={99}>
                {x.label}
              </Badge>
            </ToggleButton>
          );
        }
      })}
    </ToggleButtonGroup>
  );
};

/**
 * Affiche une info-bulle carrée avec une couleur
 * @param color Couleur de la bulle
 * @param size Hauteur de l'info-bulle (1em par défaut)
 */
export const SquareChip: FC<{ color: string; size?: number | string }> = ({ color, size = '1em' }) => {
  return <span style={{ backgroundColor: color, height: size, width: size, marginRight: '.5em' }} />;
};

interface PopProps {
  parent: ReactNode;
  asClick?: boolean;
  children: ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  PopIcon?: ReactNode;
}

/**
 * Affiche un Popover (surcharge de Material UI)
 * @param parent Parent du Popover
 * @param children Contenu du Popover
 * @param anchorOrigin origine de la position par rapport au parent du Popover
 * @param transformOrigin origine de la transformation (position de l'ancre du Popover)
 * @param asClick Définit si le Popover doit s'afficher au clic ou au survol
 * @param popIcon Icône à afficher à gauche du parent lorsque le Popover est cliquable
 */
export const Pop: FC<PopProps> = ({ parent, children, anchorOrigin, transformOrigin, asClick, PopIcon }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  const icon = PopIcon || <CmsIcon icon="contentSearch" style={{ marginRight: '.4rem' }} />;
  return (
    <div>
      <div
        className={asClick ? 'clickable flex-h align-center' : ''}
        aria-owns={!!anchorEl ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={(e: any) => asClick && handlePopoverOpen(e)}
        onMouseEnter={asClick ? undefined : handlePopoverOpen}
        onMouseLeave={asClick ? undefined : handlePopoverClose}
      >
        {asClick && icon}
        <span>{parent}</span>
      </div>
      <Popover
        id={!!anchorEl ? 'simple-popover' : undefined}
        sx={asClick ? {} : { pointerEvents: 'none' }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin ?? { vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={transformOrigin ?? { vertical: 'top', horizontal: 'left' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {children}
      </Popover>
    </div>
  );
};

interface MenuListProps {
  // Titre du menu
  title: string | ReactNode;
  // Surcharge du style css
  style?: React.CSSProperties;
  // Surcharge de la classe css
  className?: string;
  children: any;
}

/**
 * Affiche une liste de menu (surcharge de Material UI du composant List)
 * @param title Titre du menu
 * @param className Surcharge de la classe css
 * @param style Surcharge du style css
 * @param children Contenu du menu (liste de UI.subMenuItem)
 */
export const MenuList: FC<MenuListProps> = ({ title, className, style, children }) => {
  return (
    <List
      style={style}
      className={className}
      aria-labelledby="nested-list-subheader"
      dense={true}
      subheader={<>{title}</>}
    >
      {children}
    </List>
  );
};

interface SubMenuItemProps {
  // Définit si le sous-menu est ouvert
  isOpen: boolean;
  // Identifiant du sous-menu
  id: number;
  // Fonction à appeler lors du clic sur le menu
  onMenuClick?: any;
  // Fonction à appeler lors du clic sur le texte
  onTextClick?: any;
  // Titre du sous-menu
  title: string | ReactNode;
  // Contenu du sous-menu
  children: ReactNode;
}

/**
 * Affiche un sous-menu (surcharge de Material UI du composant ListItem)
 * @param isOpen Définit si le sous-menu est ouvert
 * @param id Identifiant du sous-menu
 * @param onMenuClick Fonction à appeler lors du clic sur le menu
 * @param children Contenu du sous-menu
 * @param title Titre du sous-menu
 * @param onTextClick Fonction à appeler lors du clic sur le texte
 */
export const SubMenuItem: FC<SubMenuItemProps> = ({ isOpen, id, onMenuClick, children, title, onTextClick }) => {
  return (
    <>
      <ListItem button onClick={() => onMenuClick && onMenuClick(id)}>
        <ListItemIcon>{isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</ListItemIcon>
        <ListItemText
          className="menu-item-checkbox-text"
          onClick={() => onTextClick && onTextClick(id)}
          style={{ whiteSpace: 'nowrap' }}
          primary={title}
        />
      </ListItem>
      {children && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit className="sub-list">
          {children}
        </Collapse>
      )}
    </>
  );
};

interface MenuItemCheckBoxProps extends IdLabel {
  // Fonction à appeler lors du clic sur la case à cocher du menu
  onClick: any;
  // Définit si la case à cocher est cochée
  isCheck: boolean;
  // Surcharge du style css
  style?: React.CSSProperties;
}

/**
 * Affiche un menu avec une case à cocher (surcharge de Material UI du composant ListItem)
 * @param id Identifiant du menu
 * @param isCheck Définit si la case à cocher est cochée
 * @param label Titre du menu
 * @param onClick Fonction à appeler lors du clic sur la case à cocher du menu
 * @param style Surcharge du style css
 */
export const MenuItemCheckBox: FC<MenuItemCheckBoxProps> = ({ id, isCheck, label, onClick, style }) => {
  return (
    <ListItem style={style} button onClick={() => onClick(id)}>
      <ListItemIcon>{isCheck ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}</ListItemIcon>
      <ListItemText style={{ whiteSpace: 'nowrap' }} primary={label} />
    </ListItem>
  );
};

interface MenuItemNavigationProps {
  // Surcharge du style css
  style?: React.CSSProperties;
  // Icone du menu
  icon?: ReactNode;
  // Libellé du menu
  label: string;
  // Fonction à appeler lors du clic sur le menu
  onClick: any;
}

/**
 * Affiche un menu avec une icone et un libellé (surcharge de Material UI du composant ListItem)
 * @param style Surcharge du style css
 * @param icon Icone du menu
 * @param label Libellé du menu
 * @param onClick Fonction à appeler lors du clic sur le menu
 */
export const MenuItemClick: FC<MenuItemNavigationProps> = ({ style, icon, label, onClick }) => {
  return (
    <ListItem style={style} button onClick={onClick}>
      {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} />
    </ListItem>
  );
};

interface CheckboxCustomProps {
  // Identifiant de la case à cocher
  id: number;
  // Fonction à appeler lors du clic sur la case à cocher
  onClick: any;
  // Définit si la case à cocher est cochée (ou à moitié cochée)
  checkState: true | false | 'half';
}

/**
 * Affiche une case à cocher personnalisée
 * @param id Identifiant de la case à cocher
 * @param onClick Fonction à appeler lors du clic sur la case à cocher
 * @param checkState Définit si la case à cocher est cochée (ou à moitié cochée)
 */
export const CheckboxCustom: FC<CheckboxCustomProps> = ({ id, onClick, checkState }) => {
  const classNames = 'checkbox-custom' + (checkState === 'half' ? ' half-checkbox' : '');
  return (
    <IconButton size="small" className={classNames} onClick={() => onClick(id)}>
      {checkState === true ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}
    </IconButton>
  );
};

const componentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

// Composant d'attente de connexion
export const WaitingConnectionScreen: FC = () => {
  return (
    <div style={componentStyle}>
      <h1 style={{ color: 'white' }}>Bienvenue sur le CMS v2, vous allez être automatiquement redirigé</h1>
    </div>
  );
};

interface TextEllipseProps {
  // Texte à l'intérieur de la div
  text?: string | string[];
  //texte affiché dans l'infobulle, texte complet de la div par défaut
  title?: string;
  // Largeur maximale de la div
  maxWidth?: string;
  // Définit si la div est en ligne ou non (pour le text-overflow)
  inline?: boolean;
  // Surcharge du style css
  style?: CSSProperties;
  // Nombre maximal de lignes avant le clamping
  maxLines?: number;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

/**
 * Micro composant ayant pour but de remplacer une div commune en div ellipsis pour des textes
 * @param text Texte à l'intérieur de la div
 * @param title texte affiché dans l'infobulle, texte complet de la div par défaut
 * @param maxWidth Largeur maximale de la div
 * @param inline Définit si la div est en ligne ou non (pour le text-overflow)
 * @param placement Position de l'infobulle
 * @param style Surcharge du style css
 * @param maxLines Nombre maximal de lignes avant le clamping
 */
export const TextEllipse: FC<TextEllipseProps> = ({
  text,
  title = null,
  maxWidth = '100%',
  inline = false,
  placement,
  style = {},
  maxLines = 1, // Valeur par défaut de 1 ligne
}) => {
  if (!text) return <></>;
  let finalText = text;
  if (Array.isArray(text)) finalText = text.join(', ');
  let split: any = (title ?? finalText).toString().split('\r\n');
  split = split.map((x: any, i: number) => (i === split.length - 1 ? [x] : [x, <br key={i} />]));
  return (
    <Tooltip placement={placement} title={split}>
      <span
        style={{
          ...style,
          overflow: 'hidden',
          display: inline ? 'inline' : '-webkit-box',
          textOverflow: 'ellipsis',
          whiteSpace: inline ? 'nowrap' : 'normal',
          maxWidth: maxWidth,
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: maxLines,
        }}
      >
        {split}
      </span>
    </Tooltip>
  );
};

/**
 * Composant permettant d'afficher un lien vers une page du CMS
 * @param href Lien vers la page
 * @param label Libellé du lien
 */
export const CmsLink: FC<{ href: string; label: string }> = ({ href, label }) => {
  return (
    <Link href={href} style={{ textDecoration: 'none', color: 'inherit' }}>
      {label}
    </Link>
  );
};

/**
 * Formattage du composant parent de tout les Inputs avec le form field
 * @param children Composant enfant
 * @param style Style à appliquer sur le champ
 * @param className Classe à appliquer sur le champ
 */
export const FormField: FC<{ children: any; style?: React.CSSProperties; className?: string }> = ({
  children,
  style,
  className,
}) => (
  <div className={'cms-form-field ' + className} style={{ ...style }}>
    {children}
  </div>
);

interface ErrorShowProps {
  name: string;
  label?: string | ReactNode;
  required?: boolean;
  formState?: FormState<FieldValues>;
}

export const LabelWithError: FC<ErrorShowProps> = ({ name, label, required, formState }) => (
  <div className="flex-h space-between">
    <Label label={label} name={name} required={required} />
    {formState?.errors[name] && <div className="cms-form-error">{formState.errors[name]?.message as string}</div>}
  </div>
);

interface LabelProps {
  // Libellé du champ
  label?: string | ReactNode;
  // Nom du champ
  name?: string;
  // Défini si le champ est requis ou non
  required?: boolean;
  // Style à appliquer sur le label
  style?: React.CSSProperties;
}
/**
 * Label formatter pour les champs de formulaire (libellé + étoile si le champ est requis)
 * @param label Libellé du champ
 * @param name Nom du champ
 * @param required Défini si le champ est requis ou non
 * @param style Style à appliquer sur le label
 */
export const Label: FC<LabelProps> = ({ label, name, required = false, style }) => {
  if (!label) return <></>;
  return (
    <label htmlFor={name} style={{ display: 'block', marginBottom: '0.25em', fontWeight: 'bold', ...style }}>
      {label} {required ? '*' : ''}
    </label>
  );
};

export const PhoneLink: FC<{ phone?: string }> = ({ phone }) => {
  if (!phone) return <></>;
  let phoneFormatted = phone.replace(/ /g, '').replace(/-/g, '').replace(/\./g, '');
  if (phoneFormatted.length !== 10) return <>{phone}</>;
  phoneFormatted = phoneFormatted.replace(/(\d{2})(?=\d)/g, '$1 ');
  return <Link href={'tel:' + phoneFormatted}> {phoneFormatted}</Link>;
};

export const MailLink: FC<{ mail?: string }> = ({ mail }) => {
  return !mail ? <></> : <Link href={'mailto:' + mail}>{mail}</Link>;
};

export const Multiline: FC<{ text?: string }> = ({ text }) => <span className="line-break">{text}</span>;

interface ButtonHolderProps {
  children: ReactNode;
  style?: React.CSSProperties;
  divider?: boolean;
}

export const ButtonHolder: FC<ButtonHolderProps> = ({ children, style, divider }) => (
  <>
    {!!divider && <CmsDivider />}
    <div className="flex-h end">
      {React.Children.toArray(children).map((child: any, i: number) => (
        <div key={i} style={{ marginLeft: '0.5rem', ...style }}>
          {child}
        </div>
      ))}
    </div>
  </>
);
