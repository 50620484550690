import React, { FC, useContext, useEffect, useMemo } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import {
  AddNavigationButton,
  ArrowBackNavigationButton,
  EditNavigationButton,
  LinkWithAccess,
  NavigationButton,
  ViewNavigationButton,
} from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import CRUD from '../../../service/CRUD.service';
import { SkSupplier } from '../../../interface/SkType';
import { Grid } from '@mui/material';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm, CmsFormSubmit, FormSkeleton } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../component/LoadingScreen';
import { CmsButton, CmsPaper, HeaderPanel, WarningBubble } from '../../../component/shared/Ui';
// import MapCustom from '../../../component/map/GoogleMapCustom';
import { LabelValueIf, TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { LabelValue } from '../../../interface/CommonType';
import { ImportClientInseeData, SiretLinkIcon } from '../../../component/other/InseeImport';
import { UI } from '../../../component/shared';
import { GlobalContext } from '../../../context/Global.context';
import notificationService from '../../../service/NotificationService';
import CmsIcon from '../../../component/shared/CmsIcon';
import ROLE from '../../../constant/role.constant';
import { OsmMapShow } from '../../../component/map/OsmMap';
import Utils from '../../../helper/Utils';

export const SkSupplierListConfig: CmsColumnDef<SkSupplier>[] = [
  { header: 'Référence', id: 'ref', Filter: CmsTableFilter.Search },
  { header: 'Fournisseur', id: 'companyName', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Code Postal', id: 'postcode', Filter: CmsTableFilter.Text },
  { header: 'Ville', id: 'city', Filter: CmsTableFilter.Text },
  { header: 'Téléphone', id: 'tel', Filter: CmsTableFilter.Text },
  { header: 'SIRET', id: 'siret', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'SIRET mis à jour le', id: 'sirenUpdatedAt', Filter: CmsTableFilter.Date, hide: 'hiddenByDefault' },
  { header: 'SIRET mis à jour par', id: 'sirenUpdatedByName', Filter: CmsTableFilter.Select, hide: 'hiddenByDefault' },
  {
    header: 'Date synchronisation API',
    id: 'lastApiSync',
    Filter: CmsTableFilter.Date,
    size: 20,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Synchronisation API par',
    id: 'lastApiSyncByName',
    Filter: CmsTableFilter.Select,
    hide: 'hiddenByDefault',
  },
  { header: 'Synchronisé API', id: 'isApiSync', Filter: CmsTableFilter.Bool, size: 20, hide: 'hiddenByDefault' },
  {
    header: 'Annuaire',
    id: 'siret2',
    cell: (info) => <SiretLinkIcon siret={info.row.original.siret} />,
    size: 20,
    noClick: true,
  },
  { header: 'Bloqué', id: 'locked', Filter: CmsTableFilter.Bool, defaultFilterValue: false },
  {
    header: 'En activité',
    id: 'active',
    Filter: CmsTableFilter.Bool,
    hide: 'hiddenByDefault',
    defaultFilterValue: true,
  },
  {
    header: (
      <div className="flex-h align-center">
        Dupliqué
        <CmsIcon
          icon="info"
          tooltip="Magasin en doublon dont il en reste à bloquer pour qu'ils puissent être fusionnés plus tard (seules les entités ayant fait l'objet d'un import INSEE seront fusionnés)"
        />
      </div>
    ),
    id: 'haveDuplicatedSiret',
    hide: 'hiddenByDefault',
    size: 0,
    Filter: CmsTableFilter.Bool,
  },
];

/**
 * Liste des magasins
 */
export const SkSupplierList: FC = () => {
  return (
    <CmsFrontendTable
      route={APIRoute.SkSupplier}
      columns={SkSupplierListConfig}
      title="Liste des magasins"
      navigateTo={(id: number) => `/castres/stock/supplier/${id}/show`}
      actions={[
        <CmsIcon href="/help/Magasins/" icon="info" tooltip="Aide" target="_blank" />,
        <AddNavigationButton
          access={[ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_CREATE]}
          title="Ajouter un magasin"
          to="/castres/stock/supplier/create"
        />,
      ]}
      setFiltersInUrl
    />
  );
};

export const SkSupplierShow: FC = ({ id }: any) => {
  const [supplier, setSupplier] = React.useState<SkSupplier | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) navigate('/castres/stock/supplier/list');
    else CRUD.getById<SkSupplier>(APIRoute.SkSupplier, id).then(setSupplier);
  }, [navigate, id]);

  const config = useMemo(() => {
    if (!supplier?.companyId) return SupplierGeneralConfig;
    return [
      {
        label: 'Fournisseur',
        value: (x: SkSupplier) => (
          <LinkWithAccess
            to={`/castres/stock/company/${x.companyId}/show`}
            title={x.companyName}
            access={[ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_VIEW]}
          />
        ),
      },
      ...SupplierGeneralConfig,
    ];
  }, [supplier]);

  if (!supplier) return <LoadingScreen />;
  return (
    <>
      <HeaderPanel
        title={`Magasin: ${supplier?.name}`}
        subtitle={`(${supplier.ref})`}
        actions={[
          <ArrowBackNavigationButton title="Retourner à la liste" to="/castres/stock/supplier/list" />,
          AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_EDIT]) && (
            <EditNavigationButton title="Éditer le magasin" to={`/castres/stock/supplier/${supplier?.id}/edit`} />
          ),
        ]}
      />
      <Locker supplier={supplier} />
      {!supplier.locked && <WarnDuplicateSiret siret={supplier.siret} id={id} />}
      {!!supplier.latitude && !!supplier.longitude && (
        <CmsPaper>
          <OsmMapShow setCenter={{ lat: supplier.latitude, lng: supplier.longitude }} />
        </CmsPaper>
      )}
      <Grid container spacing={2}>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Contact">
            <TabStyleDataView conf={SupplierContactConfig} mapFrom={supplier} />
          </CmsPaper>
        </Grid>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Général">
            <TabStyleDataView conf={config} mapFrom={supplier} />
          </CmsPaper>
        </Grid>
      </Grid>
    </>
  );
};

const SkSupplierDuplicatesListConfig: CmsColumnDef<SkSupplier>[] = [
  {
    header: 'Action',
    id: 'none3',
    size: 0,
    noClick: true,
    cell: (info) => <LockerButton supplier={info.row.original} />,
  },
];

const WarnDuplicateSiret: FC<{ siret?: string; id: number }> = ({ siret, id }) => {
  const [duplicateList, setDuplicateList] = React.useState<SkSupplier[]>([]);
  const { theming } = useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const config = useMemo(() => {
    return [
      ...SkSupplierListConfig.filter((x) => x.id !== 'siret2' && x.id !== 'locked'),
      ...SkSupplierDuplicatesListConfig,
    ];
  }, []);

  useEffect(() => {
    if (!siret) return;
    CRUD.getList<SkSupplier>(APIRoute.SkSupplier + '/BySiret/' + siret).then((result) => {
      let notLocked: any[] = result.filter((x) => !x.locked);
      if (notLocked.find((x) => x.id === +id))
        notLocked.find((x) => x.id === +id).rowColor = theming.get().cms.color.blue;
      setDuplicateList(notLocked);
    });
  }, [id, siret, theming]);

  if (!siret || siret.length !== 14 || duplicateList.length < 2) return <></>;
  if (location.search.includes('toBlock=true')) return <></>;
  return (
    <WarningBubble style={{ marginBottom: '1rem' }} customColor="#FF0000">
      <span style={{ fontWeight: 'bold' }}>Attention ! </span>
      <span>
        Ce SIRET est utilisé sur plusieurs magasin. S'il s'agit de doublons, veuillez les bloquer pour n'en garder
        qu'un, sinon corrigez les SIRET.
      </span>
      <CmsButton className="hint-button" style={{ marginLeft: '0.5rem' }} color="inherit" onClick={() => setOpen(true)}>
        Voir la liste
      </CmsButton>
      <UI.Dialog fullWidth maxWidth={false} open={open} onClose={() => setOpen(false)}>
        <CmsFrontendTable
          columns={config}
          title="Doublons potentiels trouvés (vous êtes actuellement sur le magasin en bleu)"
          route="none"
          invertClick
          navigateTo={(id: number) => `/castres/stock/supplier/${id}/show?toBlock=true`}
          paperStyle={{ marginBottom: 0 }}
          controlledState={{ state: duplicateList, setState: setDuplicateList }}
        />
      </UI.Dialog>
    </WarningBubble>
  );
};

const LockerButton: FC<{ supplier: SkSupplier }> = ({ supplier }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleBlock = () => {
    const payload = { id: supplier.id };
    CRUD.put<SkSupplier>(APIRoute.SkSupplier + '/Lock', payload).then(() => {
      setOpen(false);
      notificationService.info('Magasin bloqué');
      navigate(0);
    });
  };

  return (
    <>
      <CmsButton onClick={() => setOpen(true)}>Bloquer</CmsButton>
      <UI.Dialog open={open} onClose={() => setOpen(false)}>
        <CmsPaper title="Bloquer un magasin en doublon" style={{ marginBottom: 0 }}>
          <p>Assurez-vous qu'il s'agit bien d'un magasin en doublon.</p>
          <p>Le champ "Commentaire de blocage" sera rempli avec : "Doublon bloqué le XX/XX/20XX par XXX".</p>
          <p>
            Cette action n'est pas définitive. Pour revoir un magasin bloqué, accédez à la liste des magasin et décochez
            "Bloqué" dans le filtre avancé.
            <br />
            Vous pourrez alors le débloquer.
          </p>
          <UI.ButtonHolder divider>
            <CmsButton onClick={() => setOpen(false)}>Annuler</CmsButton>
            <CmsButton onClick={handleBlock}>Bloquer</CmsButton>
          </UI.ButtonHolder>
        </CmsPaper>
      </UI.Dialog>
    </>
  );
};

const SupplierContactConfig: LabelValueIf[] = [
  { label: 'Adresse', value: (x: SkSupplier) => <UI.Multiline text={x.address} /> },
  { label: 'Code Postal', value: (x: SkSupplier) => x.postcode },
  { label: 'Ville', value: (x: SkSupplier) => x.city },
  { label: 'Pays', value: (x: SkSupplier) => x.country, ifIs: (x: SkSupplier) => !!x.country },
  { label: 'Email', value: (x: SkSupplier) => <UI.MailLink mail={x.email} /> },
  { label: 'Téléphone', value: (x: SkSupplier) => <UI.PhoneLink phone={x.tel} /> },
];

const SupplierGeneralConfig: LabelValue[] = [
  { label: 'Nom', value: (x: SkSupplier) => x.name },
  { label: 'Référence', value: (x: SkSupplier) => x.ref },
  { label: 'Bloqué', value: (x: SkSupplier) => (x.locked ? 'Oui' : 'Non') },
  { label: 'Commentaire de blocage', value: (x: SkSupplier) => x.lockComment },
  { label: 'SIRET', value: (x: SkSupplier) => [x.siret, <SiretLinkIcon siret={x.siret} />] },
  { label: 'SIRET mis à jour par', value: (x: SkSupplier) => x.sirenUpdatedByName },
  { label: 'SIRET mis à jour le', value: (x: SkSupplier) => Utils.displayDate(x.sirenUpdatedAt) },
  { label: 'En activité', value: (x: SkSupplier) => (x.active ? 'Oui' : 'Non') },
  {
    label: 'Synchronisation API',
    value: (c: SkSupplier) => (c.lastApiSync ? Utils.displayDate(c.lastApiSync) : 'Jamais effectuée'),
  },
  { label: 'Fait par', value: (c: SkSupplier) => c.lastApiSyncByName },
];

/**
 * Création ou édition d'un magasin
 * @param id id du magasin à éditer
 * @param setHttpCodePage fonction pour changer le code de la page
 */
export const SkSupplierCreateEdit: FC = ({ id }: any) => {
  const title = (!id ? 'Création' : 'Édition') + " d'un magasin";
  return (
    <CmsForm
      id={id}
      currentUrl="/castres/stock/supplier/"
      route={APIRoute.SkSupplier}
      defaultValues={{ active: true, locked: false }}
      goToOnValidForm={(id) => `/castres/stock/supplier/${id}/show`}
      renderForm={(form) => {
        if (!id && !form.watch().ref)
          CRUD.getGeneratedRef(APIRoute.SkSupplier).then((result) => form.reset({ ref: result.reference }));
        const data: SkSupplier = form.getValues() as any;
        const siret = data.siret;
        let siretTitle: any = 'SIRET';
        if (!!siret && siret.length === 14) siretTitle = ['SIRET', <SiretLinkIcon siret={siret} />];
        const see = [
          <ImportClientInseeData form={form} getSiege={false} />,
          <NavigationButton title="Retourner à la liste" to="/castres/stock/supplier/list" />,
        ];
        if (!!id) see.push(<ViewNavigationButton title="Voir" to={`/castres/stock/supplier/${id}/show`} />);
        return (
          <CmsPaper title={title} style={{ maxWidth: '1200px' }} actions={see}>
            {!data.locked && <WarnDuplicateSiret siret={siret} id={id} />}
            <FormSkeleton isLoading={!!id && !form.getValues().id} fieldNumber={8} columnNumber={2}>
              <Grid container spacing={2}>
                <Grid item sm={12} lg={6}>
                  <h2 style={{ textAlign: 'center' }}>Contact</h2>
                  <CmsFormInput.Text id="address" label="Adresse" multiline />
                  <CmsFormInput.Text id="postcode" label="Code Postal" />
                  <CmsFormInput.Text id="city" label="Ville" />
                  <CmsFormInput.Text id="country" label="Pays (si hors France)" />
                  <CmsFormInput.Text id="email" label="Email" />
                  <CmsFormInput.Text id="tel" label="Téléphone" />
                </Grid>
                <Grid item sm={12} lg={6}>
                  <h2 style={{ textAlign: 'center' }}>Général</h2>
                  <CmsFormInput.Select
                    id="companyId"
                    label="Fournisseur"
                    optionLabel={(x: any) => `F${x.id.toString().padStart(8, '0')} - ${x.label}`}
                    options={APIRoute.SkCompany + '/Simplified'}
                    required
                  />
                  <CmsFormInput.Text id="name" label="Nom" required max={255} />
                  <CmsFormInput.Text id="ref" label="Référence" required max={50} />
                  <CmsFormInput.Switch id="locked" label="Bloqué" />
                  <CmsFormInput.Text
                    id="lockComment"
                    label="Commentaire de blocage"
                    disabled={data.locked && data.lockComment?.includes('Doublon')}
                    max={255}
                    multiline
                  />
                  <CmsFormInput.Switch id="active" label="En activité" />
                  <CmsFormInput.Text id="siret" label={siretTitle} max={50} />
                  <CmsFormInput.Number id="latitude" label="Latitude" />
                  <CmsFormInput.Number id="longitude" label="Longitude" />
                </Grid>
              </Grid>
            </FormSkeleton>
            <CmsFormSubmit type={id ? 'editForm' : 'createForm'} />
          </CmsPaper>
        );
      }}
    ></CmsForm>
  );
};

const Locker: FC<{ supplier: SkSupplier }> = ({ supplier }) => {
  const location = useLocation();
  if (!location.search.includes('toBlock=true') || supplier.locked) return <></>;
  if (!AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_EDIT])) return <></>;
  return (
    <WarningBubble style={{ marginBottom: '1rem' }} customColor="#00FFFF">
      <span style={{ fontWeight: 'bold' }}>Attention ! </span>
      <span> Ce magasin est potentiellement un doublon. </span>
      <LockerButton supplier={supplier} />
    </WarningBubble>
  );
};
