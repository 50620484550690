import React, { FC } from 'react';
import { CmsColumnDef, CmsBackendTable } from '../../../component/table/CmsTable';
import { Link } from '@mui/material';
import APIRoute from '../../../constant/API.constant';
import { BlOrders } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import { BASE_URL } from 'constant/API_URL';
import { AddNavigationButton } from 'component/shared/Buttons';
import CmsIcon from '../../../component/shared/CmsIcon';
import AccessFilter from '../../../helper/AccessFilter';
import ROLE from '../../../constant/role.constant';
import Utils from 'helper/Utils';

const columns: CmsColumnDef<BlOrders>[] = [
  {
    header: 'Créé le / par',
    id: 'createdAtBy',
    accessorFn: (row) => row?.createdAt,
    cell: (info) => (
      <>
        <CmsTableCell.Date {...info} />
        <div style={{ fontSize: '.9em' }}>{info.row.original?.createdByName}</div>
      </>
    ),
  },
  {
    header: 'Créé le',
    id: 'createdAt',
    Filter: CmsTableFilter.Date,
    hide: true,
    defaultFilterValue: { value: Utils.Date.addDays(new Date(), -366), type: 9 },
  },
  {
    header: 'Créé par',
    id: 'createdBy',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.BlOrders}/OrderUsers`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    hide: true,
  },
  {
    header: 'Référence',
    id: 'ref',
    Filter: CmsTableFilter.Text,
    cell: (info) => (
      <Link
        key={info.row.original?.id}
        style={{ color: 'inherit' }}
        href={`${BASE_URL}castres/billing/order/${info.row.original?.id}/show`}
        target="_blank"
      >
        <div style={{ fontWeight: 'bold' }}>{info.getValue()}</div>
      </Link>
    ),
  },
  {
    header: 'Code chantier / Intervention',
    Filter: CmsTableFilter.Text,
    id: 'workCode',
    cell: (info) => (
      <Link
        key={info.row.original?.interventionId}
        style={{ color: 'inherit' }}
        href={`${BASE_URL}castres/work/intervention/${info.row.original?.interventionId}/show`}
        target="_blank"
      >
        {info.row.original?.interventionRef !== info.row.original?.workCode &&
          info.row.original?.workCode &&
          info.row.original?.workCode + ' - '}
        {info.row.original?.interventionRef}
      </Link>
    ),
  },
  {
    header: 'Intervention',
    id: 'interventionTitle',
    hide: 'hiddenByDefault',
  },
  {
    header: 'Devis',
    id: 'quotationId',
    cell: (info) =>
      info.row.original?.quotationId != null && (
        <Link
          key={info.row.original?.quotationId}
          style={{ color: 'inherit' }}
          href={`${BASE_URL}castres/billing/quotation/${info.row.original?.quotationId}/show`}
          target="_blank"
        >
          {info.row.original?.quotationRef}
        </Link>
      ),
    hide: 'hiddenByDefault',
  },
  {
    header: 'Conducteur de Travaux',
    id: 'supervisorName',
    hide: 'hiddenByDefault',
  },
  {
    header: 'Conducteur de Travaux',
    id: 'interventionSupervisedby',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.WkIntervention}/Supervisors`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    hide: true,
  },
  {
    header: 'Entité juridique',
    id: 'legalEntity',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: APIRoute.ClLegalEntity,
      optionFrom: 'BlQuotation',
    },
    hide: true,
  },
  {
    header: 'Site',
    id: 'station',
    cell: (info) => (
      <Link
        key={info.row.original?.stationId}
        style={{ color: 'inherit' }}
        href={`${BASE_URL}castres/client/station/${info.row.original?.stationId}/show`}
        target="_blank"
      >
        <div>{info.row.original?.stationRef}</div>
        <div>{info.row.original?.stationLabel}</div>
      </Link>
    ),
  },
  { header: 'Code implant site', id: 'stationRef', Filter: CmsTableFilter.Text, hide: true },
  { header: 'Nom du site', id: 'stationLabel', Filter: CmsTableFilter.Text, hide: true },
  {
    header: 'Adresse de livraison',
    id: 'deliveryAddress',
    cell: (info) => (
      <>
        <div>{info.row.original?.deliveryAddress}</div>
        <div>
          {info.row.original?.deliveryPostcode}' '{info.row.original?.deliveryCity}
        </div>
      </>
    ),
    hide: 'hiddenByDefault',
  },
  {
    header: 'Fournisseur / Magasin',
    id: 'supplier',
    cell: (info) => (
      <>
        <Link
          key={info.row.original?.companyId}
          style={{ color: 'inherit' }}
          href={`${BASE_URL}castres/stock/company/${info.row.original?.companyId}/show`}
          target="_blank"
        >
          <div>{info.row.original?.companyName}</div>
        </Link>
        <Link
          key={info.row.original?.supplierId}
          style={{ color: 'inherit' }}
          href={`${BASE_URL}castres/stock/supplier/${info.row.original?.supplierId}/show`}
          target="_blank"
        >
          <div>{info.row.original?.supplierName}</div>
        </Link>
      </>
    ),
  },
  { header: 'Fournisseur', id: 'companyName', Filter: CmsTableFilter.Text, hide: true },
  { header: 'Magasin', id: 'supplierName', Filter: CmsTableFilter.Text, hide: true },
  {
    header: 'SIREN',
    id: 'supplierSiret',
    Filter: CmsTableFilter.Text,
    hide: 'hiddenByDefault',
    cell: (info) => (
      <Link
        key={info.getValue()}
        style={{ color: 'inherit' }}
        href={`https://annuaire-entreprises.data.gouv.fr/entreprise/${info.getValue()}`}
        target="_blank"
      >
        <div>{info.getValue()}</div>
      </Link>
    ),
  },
  {
    header: 'Service / Stock',
    id: 'serviceStock',
    cell: (info) => (
      <>
        <div>
          {info.row.original?.agency} - {info.row.original?.legalEntityCode}
        </div>
        <div>
          {info.row.original?.service}
          {info.row.original?.stock ? ` Stock ${info.row.original?.stock}` : ''}
        </div>
      </>
    ),
  },
  {
    header: 'Service',
    id: 'service',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SfService, optionFrom: 'BlQuotation' },
    hide: true,
  },
  {
    header: 'Stock',
    id: 'stock',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SkStock, optionFrom: 'BlQuotation' },
    hide: true,
  },
  {
    header: 'Agence',
    id: 'agency',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClAgency, optionFrom: 'BlQuotation' },
    hide: true,
  },
  { header: 'Montant', id: 'price', cell: CmsTableCell.Price, size: 10 },
  {
    header: 'Date de livraison voulue',
    id: 'desiredDeliveryDate',
    Filter: CmsTableFilter.Date,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Type de livraison',
    id: 'deliveryType',
    hide: 'hiddenByDefault',
  },
  {
    header: 'Contact commande',
    id: 'deliveryContactName',
    hide: 'hiddenByDefault',
  },
  {
    header: 'Enlevé par',
    id: 'retrievedBy',
    accessorKey: 'retrievedByName',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.BlOrders}/Retrievers`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    hide: 'hiddenByDefault',
  },
  { header: "Corps d'état", id: 'job', hide: 'hiddenByDefault' },
  {
    header: 'Nature',
    id: 'nature',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.BlNature, multiple: true },
  },
  { header: 'Objet', id: 'comment', hide: 'hiddenByDefault' },
  {
    header: 'Statut',
    id: 'status',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.BlStatusOrder, multiple: true },
    cell: (info) => (
      <CmsTableCell.Colored
        color={info.row.original.status?.statusColor ?? ''}
        content={info.row.original.status?.statusLabel}
      />
    ),
    size: 40,
  },
  {
    header: 'Code postal',
    id: 'postCode',
    Filter: CmsTableFilter.Text,
    hide: true,
  },
  {
    header: 'Ville',
    id: 'city',
    Filter: CmsTableFilter.Text,
    hide: true,
  },
  {
    header: 'Référence Article',
    id: 'articleRef',
    Filter: CmsTableFilter.Text,
    hide: true,
  },
  {
    header: 'Libellé Article',
    id: 'articleLabel',
    Filter: CmsTableFilter.Text,
    hide: true,
  },
];

export const OrderList: FC = () => {
  return (
    <CmsBackendTable
      title="Liste des commandes"
      actions={[
        <CmsIcon href="/help/Facturation/commandes" icon="info" tooltip="Aide" target="_blank" />,
        <AddNavigationButton
          key="nav"
          title="Créer une commande"
          href={`${BASE_URL}castres/billing/order/create`}
          access={[ROLE.ADMIN_ORDER_CREATE]}
        />,
      ]}
      columns={columns}
      route={APIRoute.BlOrders}
      globalFilterIcon={
        <CmsIcon
          icon="warning"
          typeColor="primary"
          tooltip="Attention, ce filtre ne s'applique que sur le champ Référence"
        />
      }
      setFiltersInUrl
      canExport={AccessFilter([ROLE.ADMIN_ORDER_EXPORT])}
      exportList={[
        { title: 'Toutes Lignes CSV', type: 'csv', route: '/ExportLinesWithFilter' },
        { title: 'Toutes Lignes EXCEL', type: 'xlsx', route: '/ExportLinesWithFilter' },
      ]}
    />
  );
};
