import React, { CSSProperties, FC, ReactNode, useContext, useEffect } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import {
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popover,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import CRUD from '../../service/CRUD.service';
import './../shared/shared.scss';
import { DatePickerVanilla } from '../shared/InputUi';
import { IdLabel, IdLabelIcon } from '../../interface/CommonType';
import Radio from '@mui/material/Radio';
import { InputUI, UI } from '../shared';
import { GlobalContext } from '../../context/Global.context';
import { ColorResult, SketchPicker } from 'react-color';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { AutoCompletor } from '../shared/AutoCompletorWithTable';
import Utils from '../../helper/Utils';

const CmsFormValidation = {
  required: 'Ce champ est requis',
  max: (length: number) => ({ value: length, message: `La valeur maximal possible est ${length}` }),
  min: (length: number) => ({ value: length, message: `La valeur minimal possible est ${length}` }),
  maxLength: (length: number) => ({ value: length, message: `La longueur maximale est de ${length} caractères` }),
  minLength: (length: number) => ({ value: length, message: `La longueur minimale est de ${length} caractères` }),
};

/**
 * Champ de formulaire conditionnel
 * @param hideIf Condition d'affichage du champ
 * @param children Contenu du champ
 */
const CmsFormInputConditional = ({ hideIf, children }: any) => {
  const { watch } = useFormContext();
  return (hideIf && hideIf(watch()) && <></>) || children;
};

interface CmsFormInputTextProps {
  id: string;
  label: string | ReactNode;
  inputRef?: any;
  errors?: any;
  onChange?: any;
  width?: string;
  placeholder?: string;
  style?: any;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
  disableIf?: (data: any) => boolean;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  min?: number;
  max?: number;
  hideIf?: (data: any) => boolean;
  isPhone?: true;
}

/**
 * Champ de formulaire de type texte lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param min Longueur minimale du champ
 * @param max Longueur maximale du champ
 * @param width Largeur du champ
 * @param placeholder Placeholder du champ
 * @param required Champ requis ou non
 * @param multiline Champ multiligne ou non
 * @param disabled Champ désactivé ou non
 * @param disableIf Condition de désactivation du champ
 * @param readOnly Champ en lecture seule ou non
 * @param readOnlyIf Condition de lecture seule du champ
 * @param style Style du champ FormField
 * @param hideIf Condition d'affichage du champ
 * @param updateFieldIf Condition de mise à jour du champ
 */
const CmsFormInputText: FC<CmsFormInputTextProps> = ({
  id = '',
  label,
  min,
  max,
  width,
  placeholder,
  required = false,
  multiline = false,
  disabled = false,
  disableIf,
  readOnly,
  readOnlyIf,
  style,
  hideIf,
  updateFieldIf,
  isPhone,
}) => {
  const { register, formState, watch, setValue, setError } = useFormContext();
  let registerOptions: RegisterOptions = { required: required ? CmsFormValidation.required : undefined };
  if (max) registerOptions.maxLength = CmsFormValidation.maxLength(max);
  if (min) registerOptions.minLength = CmsFormValidation.minLength(isPhone ? 10 : min);
  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disableIf && disableIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) setValue(id, value);
  }
  if (isPhone && watch()[id]?.length > 9 && !formState.errors[id] && !watch()[id].match(/^[0-9]{10}$/g))
    setError(id, { type: 'manual', message: 'Seuls les numéros tels que 0123456789 sont autorisés' });
  return (
    <UI.FormField>
      <UI.LabelWithError name={id} label={label} required={required} formState={formState} />
      <TextField
        id={id}
        multiline={multiline}
        placeholder={placeholder}
        inputProps={{ readOnly: readOnly }}
        disabled={disabled}
        size="small"
        error={!!formState.errors[id]}
        style={{ width: width ?? '100%', ...style }}
        {...register(id, registerOptions)}
      />
    </UI.FormField>
  );
};

interface CmsFormInputNumberProps {
  id: string;
  label: string;
  inputRef?: any;
  errors?: any;
  onChange?: any;
  width?: string;
  placeholder?: string;
  style?: any;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
  disableIf?: (data: any) => boolean;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
  min?: number;
  max?: number;
  hideIf?: (data: any) => boolean;
}

/**
 * Champ de formulaire de type nombre lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param min Longueur minimale du champ
 * @param max Longueur maximale du champ
 * @param width Largeur du champ
 * @param placeholder Placeholder du champ
 * @param required Champ requis ou non
 * @param disabled Champ désactivé ou non
 * @param readOnly Champ en lecture seule ou non
 * @param readOnlyIf Condition de lecture seule du champ
 * @param disableIf Condition de désactivation du champ
 * @param hideIf Condition d'affichage du champ
 * @param updateFieldIf Condition de mise à jour du champ
 * @param style Style du champ FormField
 */
const CmsFormInputNumber: FC<CmsFormInputNumberProps> = ({
  id = '',
  label,
  min,
  max,
  width,
  placeholder,
  required = false,
  disabled = false,
  readOnly = false,
  readOnlyIf,
  disableIf,
  hideIf,
  updateFieldIf,
  style,
}) => {
  const { formState, watch, setValue, control } = useFormContext();
  const registerOptions: RegisterOptions = { required: required ? CmsFormValidation.required : undefined };
  if (max) registerOptions.max = CmsFormValidation.max(max);
  if (min) registerOptions.min = CmsFormValidation.min(min);
  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disableIf && disableIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) setValue(id, value);
  }
  const handleChange = (x: any, onChange: any) => {
    if (x === '0') return onChange(0);
    if (!x) return onChange(undefined);
    return onChange(+x);
  };

  return (
    <UI.FormField>
      <UI.LabelWithError label={label} name={id} required={required} formState={formState} />
      <Controller
        control={control}
        name={id}
        rules={registerOptions}
        render={({ field: { onChange, value } }) => (
          <TextField
            id={id}
            value={value ?? ''}
            onChange={(x) => handleChange(x.target.value, onChange)}
            type="number"
            placeholder={placeholder}
            inputProps={{ readOnly: readOnly }}
            disabled={disabled}
            size="small"
            error={!!formState.errors[id]}
            style={{ width: width ?? '100%', ...style }}
          />
        )}
      />
    </UI.FormField>
  );
};
// {/*{...register(id, registerOptions)}*/}

interface CmsFormInputSwitchProps {
  id: string;
  label: string;
  inline?: boolean;
  inlineReverse?: boolean;
  containerStyle?: CSSProperties;
  style?: CSSProperties;
  hideIf?: (data: any) => boolean;
  disabled?: boolean;
  disableIf?: (data: any) => boolean;
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
}

/**
 * Champ de type bouton radio on/off lié à un hookForm
 * @param control Gestionnaire de formulaire (géré par le hookForm)
 * @param inline Défini si le libellé est au-dessus ou à gauche du champ (défaut : false)
 * @param inlineReverse Défini si le libellé est à droite ou à gauche du champ (défaut : false)
 * @param label Libellé du champ
 * @param id id du champ
 * @param style Style du champ FormField
 */
const CmsFormInputSwitch: FC<CmsFormInputSwitchProps> = ({
  id,
  label,
  inline,
  inlineReverse,
  containerStyle,
  style,
  hideIf,
  disabled = false,
  disableIf,
  readOnly = false,
  readOnlyIf,
  updateFieldIf,
}) => {
  const { control, formState, watch, setValue } = useFormContext();
  let inlineStyle: CSSProperties = { display: 'flex', flexDirection: 'row', alignItems: 'center' };
  inlineStyle = inlineReverse ? { ...inlineStyle, justifyContent: 'start', flexDirection: 'row-reverse' } : inlineStyle;
  if (!inline && !inlineReverse) inlineStyle = {};
  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disableIf && disableIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) setValue(id, value);
  }
  const props = { style, color: 'primary' as any, readOnly, disabled };
  return (
    <UI.FormField className="switch-form" style={{ ...inlineStyle, ...containerStyle }}>
      <UI.LabelWithError name={id} required={false} label={label} formState={formState} />
      <Controller
        control={control}
        name={id}
        render={({ field: { onChange, value } }) => (
          <Switch checked={value ?? false} onChange={() => onChange(!value)} {...props} />
        )}
      />
    </UI.FormField>
  );
};

interface CmsFormSelectProps {
  id: string;
  label: string;
  options: any[] | string;
  disabled?: boolean;
  disableIf?: (data: any) => boolean;
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
  inputStyle?: CSSProperties;
  multiple?: boolean;
  optionLabel?: string | ((data: any) => string | undefined);
  optionValue?: string;
  required?: boolean;
  rules?: RegisterOptions;
  style?: CSSProperties;
  width?: string;
  hideIf?: (data: any) => boolean;
  updateFieldIf?: (data: any, optionList: any[]) => { update: boolean; value: any };
}

/**
 * Gestion des libellés personnalisés
 * @param optionList Liste des options
 * @param optionLabel Libellé de l'option
 */
function handleCustomLabel(optionList: any[], optionLabel: string | ((data: any) => string | undefined)): any[] {
  if (typeof optionLabel === 'string') return optionList;
  return optionList.filter((x) => !!optionLabel(x)).map((x) => ({ ...x, customLabel: optionLabel(x) }));
}

/**
 * Champ de formulaire de type select lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param disabled Champ désactivé ou non
 * @param disableIf Condition de désactivation du champ
 * @param readOnly Champ en lecture seule ou non
 * @param readOnlyIf Condition de lecture seule du champ
 * @param inputStyle Style du champ FormField
 * @param multiple Champ multiselect ou non
 * @param optionLabel Libellé de l'option
 * @param optionValue Valeur de l'option
 * @param options Liste des options
 * @param required Champ requis ou non
 * @param rules Règles de validation du champ
 * @param style Style du champ FormField
 * @param width Largeur du champ
 * @param hideIf Condition d'affichage du champ
 * @param updateFieldIf Condition de mise à jour du champ
 */
const CmsFormSelect: FC<CmsFormSelectProps> = ({
  id,
  label,
  disabled = false,
  disableIf,
  readOnly = false,
  readOnlyIf,
  inputStyle,
  multiple = false,
  optionLabel = 'label',
  optionValue = 'id',
  options,
  required = false,
  rules,
  style,
  width,
  hideIf,
  updateFieldIf,
}) => {
  const { control, formState, watch, setValue } = useFormContext();
  const [firstRender, setFirstRender] = React.useState(true);
  const [optionList, setOptionList] = React.useState<any[]>([]);
  useEffect(() => {
    if (Array.isArray(options)) setOptionList(handleCustomLabel(options, optionLabel));
  }, [optionLabel, options]);

  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disableIf && disableIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch(), optionList);
    if (update && value !== watch()[id]) setValue(id, value);
  }

  if (firstRender) {
    setFirstRender(false);
    if (!Array.isArray(options))
      CRUD.getList<any>(options).then((x) => setOptionList(handleCustomLabel(x, optionLabel)));
  }

  return (
    <Controller
      name={id}
      rules={{ required: required ? CmsFormValidation.required : undefined, ...rules }}
      control={control}
      render={({ field, ...props }) => {
        if (!field.value) field.value = [];
        return (
          <AutoCompletor
            error={!!formState.errors[id]}
            options={optionList}
            readOnly={readOnly}
            optionLabel={typeof optionLabel === 'string' ? optionLabel : 'customLabel'}
            optionValue={optionValue}
            required={required}
            name={id}
            label={label}
            width={width}
            disabled={disabled}
            multiple={multiple}
            value={field.value}
            style={style}
            inputStyle={inputStyle}
            onChange={field.onChange}
            control={control}
            extendsProps={props}
            formState={formState}
          />
        );
      }}
    />
  );
};

interface CmsFormInputDateProps {
  id: string;
  label: string;
  required?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  disabledIf?: (data: any) => boolean;
  readOnly?: boolean;
  readOnlyIf?: (data: any) => boolean;
  format?: string;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
  hideIf?: (data: any) => boolean;
  dateOnlyFormat?: boolean;
}

/**
 * Champ de formulaire de type date lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param style Style du champ FormField
 * @param required Champ requis ou non
 * @param disabled Champ désactivé ou non
 * @param readOnly Champ en lecture seule ou non
 * @param disabledIf Condition de désactivation du champ
 * @param readOnlyIf Condition de lecture seule du champ
 * @param format Format de la date (défaut : dd/MM/yyyy)
 * @param updateFieldIf Condition de mise à jour du champ
 * @param hideIf Condition d'affichage du champ
 * @param dateOnlyFormat Défini si le champ doit être de type date uniquement
 */
const CmsFormInputDate: FC<CmsFormInputDateProps> = ({
  id,
  label,
  style,
  required = false,
  disabled = false,
  readOnly = false,
  disabledIf,
  readOnlyIf,
  format = 'dd/MM/yyyy',
  updateFieldIf,
  hideIf,
  dateOnlyFormat = false,
}) => {
  const { formState, setValue, control, watch } = useFormContext();
  if (hideIf && hideIf(watch())) return <></>;
  if (readOnlyIf && readOnlyIf(watch())) readOnly = true;
  if (disabledIf && disabledIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) setValue(id, value);
  }
  return (
    <Controller
      name={id}
      rules={{ required: required ? CmsFormValidation.required : undefined }}
      control={control}
      render={({ field: { onChange, value } }) => {
        if (value === undefined) value = null;
        if (typeof value === 'string') value = new Date(value);
        const handleChange = (date: Date | null) =>
          onChange(!date ? null : dateOnlyFormat ? Utils.Date.dateOnlyFormat(date) : date);
        return (
          <UI.FormField style={style}>
            <UI.LabelWithError name={id} required={required} label={label} formState={formState} />
            <DatePickerVanilla
              error={!!formState.errors[id]}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              readOnly={readOnly}
              format={format}
            />
          </UI.FormField>
        );
      }}
    />
  );
};

interface CmsFormInputRadioProps {
  id: string;
  label: string;
  disabled?: boolean;
  disabledIf?: (data: any) => boolean;
  options: IdLabel[];
  style?: CSSProperties;
  vertical?: boolean;
  hideIf?: (data: any) => boolean;
  updateFieldIf?: (data: any) => { update: boolean; value: any };
}

/**
 * Champ de formulaire de type radio lié à un hookForm
 * @param label Libellé du champ
 * @param id id du champ
 * @param options Liste des options
 * @param style Style du champ FormField
 * @param vertical Défini si les boutons radio sont affichés verticalement ou non
 * @param hideIf Condition d'affichage du champ
 * @param disabled Champ désactivé ou non
 * @param disabledIf Condition de désactivation du champ
 * @param updateFieldIf Condition de mise à jour du champ
 */
const CmsFormInputRadio: FC<CmsFormInputRadioProps> = ({
  label,
  id,
  options,
  style,
  vertical = false,
  hideIf,
  disabled,
  disabledIf,
  updateFieldIf,
}) => {
  const { formState, control, watch, setValue } = useFormContext();
  if (hideIf && hideIf(watch())) return <></>;
  if (disabledIf && disabledIf(watch())) disabled = true;
  if (updateFieldIf) {
    const { update, value } = updateFieldIf(watch());
    if (update && value !== watch()[id]) setValue(id, value);
  }
  let groupRadioStyle: CSSProperties = { display: 'flex', flexDirection: 'row' };
  if (vertical) groupRadioStyle = {};
  const retype = (x: any) => (isNaN(x.target.value) ? x.target.value : +x.target.value);
  return (
    <UI.FormField style={style}>
      <UI.LabelWithError label={label} name={id} required={false} formState={formState} />
      <Controller
        control={control}
        name={id}
        render={({ field: { onChange, value } }) => {
          return (
            <RadioGroup onChange={(x) => onChange(retype(x))} style={groupRadioStyle} value={value ?? null}>
              {options &&
                options.map((option: IdLabel, i: number) => (
                  <FormControlLabel
                    key={i}
                    control={<Radio />}
                    label={option.label}
                    value={option.id}
                    disabled={disabled}
                  />
                ))}
            </RadioGroup>
          );
        }}
      />
    </UI.FormField>
  );
};

interface ControlledColorProps {
  id: string;
  label: string;
  withoutSharp?: boolean;
  isNullable?: boolean;
  placeholder?: string;
  required?: boolean;
}

const anchorOrigin: any = { vertical: 'bottom', horizontal: 'center' };
const transformOrigin: any = { vertical: 'top', horizontal: 'center' };

/**
 * Champ de formulaire de type color picker lié à un hookForm
 * @param id id du champ
 * @param label Libellé du champ
 * @param withoutSharp Défini si le champ doit être renvoyer sans le #
 * @param isNullable Défini si le champ peut avoir la valeur null ou non
 * @param placeholder Placeholder du champ
 * @param required Champ requis ou non
 */
const ColorPickerControlled: FC<ControlledColorProps> = ({
  id,
  label,
  withoutSharp = false,
  isNullable = true,
  placeholder = 'Veuillez choisir une couleur',
  required,
}) => {
  const { formState, control } = useFormContext();
  const { theming } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const idHtml = open ? 'simple-popover' : undefined;
  return (
    <UI.FormField>
      <UI.LabelWithError name={id} required={required} label={label} formState={formState} />
      <Controller
        defaultValue={null}
        name={id}
        rules={{ required: required ? CmsFormValidation.required : undefined }}
        control={control}
        render={({ field }) => {
          const handleChange = (val: ColorResult) => {
            field.onChange(!val?.hex ? null : withoutSharp ? val.hex.split('#')[1] : val.hex);
          };
          const handleValue = (value: string): string => {
            if (!value) return theming.get().palette.text.primary;
            return withoutSharp ? '#' + value : value;
          };
          const buttonProps: any = { variant: 'contained', style: { backgroundColor: handleValue(field.value) } };
          const popProps: any = { open, anchorEl, onClose: handleClose, transformOrigin, anchorOrigin };
          return (
            <>
              <Button aria-describedby={idHtml} onClick={handleClick} {...buttonProps}>
                {field.value ? label : placeholder}
              </Button>
              {isNullable && field.value && (
                <IconButton onClick={() => field.onChange(null)} size="small" style={{ marginLeft: '12px' }}>
                  <DeleteOutlinedIcon />
                </IconButton>
              )}
              <Popover id={idHtml} {...popProps}>
                <SketchPicker color={handleValue(field.value)} onChange={handleChange} />
              </Popover>
            </>
          );
        }}
      />
    </UI.FormField>
  );
};

interface FormSelectProps {
  // Nom du champ
  id: string;
  // Libellé du champ
  label: string;
  // Liste des options proposées format par défaut : [{id: 1, label: 'test', icon: <Icon />}], Icône facultative
  options: Array<IdLabelIcon | IdLabel> | string;
  // Style du champ FormField
  style?: React.CSSProperties;
  // Style du champ Select
  itemStyle?: React.CSSProperties;
  // Défini si le champ est désactivé ou non (grisé)
  disabled?: boolean;
  // Défini si le champ peut avoir la valeur null ou non
  nullable?: boolean;
  // Champ requis ou non
  required?: boolean;
  optionLabel?: string | ((data: any) => string | undefined);
  optionValue?: string;
}

/**
 * Champ de formulaire de type select lié à un hookForm
 * @param disabled Champ désactivé ou non
 * @param itemStyle Style des options du select
 * @param label Libellé du champ
 * @param id id du champ
 * @param nullable Défini si le champ peut avoir la valeur null ou non
 * @param options Liste des options
 * @param required Champ requis ou non
 * @param style Style du champ FormField
 * @param optionLabel Libellé de l'option
 * @param optionValue Valeur de l'option
 */
const CmsFormSimpleSelect: FC<FormSelectProps> = ({
  disabled = false,
  itemStyle,
  label,
  id,
  nullable = false,
  options,
  required,
  style,
  optionLabel = 'label',
  optionValue = 'id',
}) => {
  const { formState, control } = useFormContext();
  const [firstRender, setFirstRender] = React.useState(true);
  const [optionList, setOptionList] = React.useState<any[]>([]);

  if (firstRender) {
    setFirstRender(false);
    if (Array.isArray(options)) setOptionList(handleCustomLabel(options, optionLabel));
    else CRUD.getList<any>(options).then((x) => setOptionList(handleCustomLabel(x, optionLabel)));
  }

  return (
    <UI.FormField style={style}>
      <UI.LabelWithError name={id} label={label} required={required} formState={formState} />
      <Controller
        name={id}
        control={control}
        rules={{ required: required ? CmsFormValidation.required : undefined }}
        render={({ field }) => {
          return (
            <Select
              name={id}
              disabled={disabled || !options}
              inputProps={{ name: id }}
              labelId="simple-select"
              onChange={field.onChange}
              style={{ width: '100%' }}
              value={field.value ?? ''}
              size="small"
            >
              {nullable && (
                <MenuItem value={undefined} style={itemStyle}>
                  Aucun
                </MenuItem>
              )}
              {optionList &&
                optionList.map((opt, i) => (
                  <MenuItem key={i} value={opt[optionValue]} style={itemStyle}>
                    {'icon' in opt && opt.icon}
                    {opt.label}
                  </MenuItem>
                ))}
            </Select>
          );
        }}
      />
    </UI.FormField>
  );
};

interface CmsFormInputFileProps {
  id: string;
  label: string;
  // updateFieldIf?: (data: any) => { update: boolean; value: any };
  readOnlyIf?: (data: any) => boolean;
  disableIf?: (data: any) => boolean;
  required?: boolean;
  disabled?: boolean;
  hideIf?: (data: any) => boolean;
  // Le champ est-il un champ d'upload d'image ?
  image?: boolean;
  // Le champ est-il un champ d'upload de pdf ?
  pdfOnly?: boolean;
  // Le champ est-il un champ d'upload d'image jpg ?
  jpgOnly?: boolean;
}

/**
 * Champ de type fichier lié à un hookForm
 * @param id id du champ
 * @param required champ requis ou non
 * @param label libellé du champ
 * @param hideIf champ caché ou non
 * @param disabled champ désactivé ou non
 * @param disableIf champ désactivé ou non en fonction de la valeur d'un autre champ
 * @param readOnlyIf champ en lecture seule ou non en fonction de la valeur d'un autre champ
 * @param image le champ est-il un champ d'upload d'image ?
 * @param pdfOnly le champ est-il un champ d'upload de pdf ?
 * @param jpgOnly le champ est-il un champ d'upload d'image jpg ?
 */
const CmsFormInputFile: FC<CmsFormInputFileProps> = ({
  id,
  required,
  label,
  hideIf,
  disabled,
  disableIf,
  readOnlyIf,
  image,
  pdfOnly,
  jpgOnly,
}) => {
  const { formState, control, watch } = useFormContext();
  if (hideIf && hideIf(watch())) return <></>;
  if (disableIf && disableIf(watch())) disabled = true;
  if (readOnlyIf && readOnlyIf(watch())) disabled = true;
  return (
    <UI.FormField>
      <UI.LabelWithError name={id} required={required} label={label} formState={formState} />
      <Controller
        name={id}
        rules={{ required: required ? CmsFormValidation.required : undefined }}
        control={control}
        render={({ field }) => {
          const fileProps: any = { disabled, image, pdfOnly, jpgOnly };
          return <InputUI.InputFile {...fileProps} onFileSelected={field.onChange} />;
        }}
      />
    </UI.FormField>
  );
};

interface CmsFormInputSuggestProps {
  id: string;
  label: string;
  route: string;
  readOnlyIf?: (data: any) => boolean;
  disableIf?: (data: any) => boolean;
  required?: boolean;
  disabled?: boolean;
  hideIf?: (data: any) => boolean;
  optionValue?: string;
  optionLabel?: string;
  defaultValueHolder?: string;
}

/**
 * Champ de type suggest lié à un hookForm
 * @param id id du champ
 * @param label libellé du champ
 * @param route route de l'api à appeler
 * @param required champ requis ou non
 * @param readOnlyIf champ en lecture seule ou non en fonction de la valeur d'un autre champ
 * @param disableIf champ désactivé ou non en fonction de la valeur d'un autre champ
 * @param disabled champ désactivé ou non
 * @param hideIf champ caché ou non en fonction de la valeur d'un autre champ
 * @param optionValue définit la valeur de l'option
 * @param optionLabel définit le libellé de l'option
 * @param defaultValueHolder définit la valeur par défaut du champ
 */
const CmsFormInputSuggest: FC<CmsFormInputSuggestProps> = ({
  id,
  label,
  route,
  required,
  readOnlyIf,
  disableIf,
  disabled,
  hideIf,
  optionValue = 'id',
  optionLabel = 'label',
  defaultValueHolder,
}) => {
  const { formState, control, watch } = useFormContext();
  if (hideIf && hideIf(watch())) return <></>;
  if (disableIf && disableIf(watch())) disabled = true;
  if (readOnlyIf && readOnlyIf(watch())) disabled = true;
  return (
    <UI.FormField>
      <UI.LabelWithError name={id} required={required} label={label} formState={formState} />
      <Controller
        name={id}
        rules={{ required: required ? CmsFormValidation.required : undefined }}
        control={control}
        render={({ field }) => {
          return (
            <InputUI.CustomSuggest
              disabled={disabled}
              name={id}
              initialValue={defaultValueHolder}
              onValueSelected={field.onChange}
              getSuggestionService={(search: string) => CRUD.getSuggestedList(route, { search })}
              showAttribute={optionLabel}
              getAttribute={optionValue}
            />
          );
        }}
      />
    </UI.FormField>
  );
};

export const CmsFormInput = {
  Conditional: CmsFormInputConditional,
  Number: CmsFormInputNumber,
  Select: CmsFormSelect,
  SimpleSelect: CmsFormSimpleSelect,
  Radio: CmsFormInputRadio,
  Date: CmsFormInputDate,
  Text: CmsFormInputText,
  Switch: CmsFormInputSwitch,
  Color: ColorPickerControlled,
  File: CmsFormInputFile,
  Suggest: CmsFormInputSuggest,
};
