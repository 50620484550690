import React, { createContext, CSSProperties, FC, useContext, useEffect, useMemo, useState } from 'react';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import LoadingScreen from '../LoadingScreen';
import NotificationService from '../../service/NotificationService';
import { Collapse } from '@mui/material';
import './form.scss';
import { UI } from '../shared';
import CmsIcon from '../shared/CmsIcon';
import { MultiLevelInputProps } from './MultiLevelInput';

/**
 * Contexte du formulaire multi-niveau
 * @param state Etat du formulaire
 * @param setState Fonction pour modifier l'état du formulaire
 * @param get Fonction pour récupérer les données du formulaire
 * @param reload Fonction pour recharger les données du formulaire
 * @param setOptionListFromSubItem Fonction pour récupérer les options des champs de menu déroulant
 * @param optionList Liste des options des champs de menu déroulant
 */
export const MultiLevelContext = createContext({
  state: {} as any,
  setState: (state: any) => {},
  modifiedState: {} as any,
  setModifiedState: (state: any) => {},
  get: (id: number, route: string) => {},
  reload: () => {},
  setOptionListFromSubItem: (subitem: LevelFormProps) => {},
  optionList: {} as any,
});

/**
 * Récupère toutes les routes des options des champs de menu déroulant récursivement
 * @param subitem Sous-élément
 * @param result Liste des routes
 */
function getAllOptionListRoute(subitem: LevelFormProps, result: string[] = []): string[] {
  for (let column of subitem.columnList)
    if (!!column.optionRoute && !result.find((x) => x === column.optionRoute)) result.push(column.optionRoute);
  return !!subitem.subItem ? getAllOptionListRoute(subitem.subItem, result) : result;
}

interface MultiLevelFormContextProviderProps {
  id: number;
  getRoute: string | APIRoute | ((id: number) => string);
  onGetData?: (data: any) => any;
  children: any;
  onChange?: any;
  data?: any;
}

/**
 * Contexte du formulaire multi-niveau
 * @param props on ne récupère que les enfants du composant
 * @constructor Création du contexte du formulaire multi-niveau
 */
export const MultiLevelFormContextProvider: FC<MultiLevelFormContextProviderProps> = (props) => {
  const { children, onChange, data, id, getRoute, onGetData } = props;
  const [state, setState] = useState<any>({});
  const [modifiedState, setModifiedState] = useState<any>({});
  const [optionList, setOptionList] = useState<any>({});

  useEffect(() => {
    onChange && onChange(modifiedState.item);
  }, [onChange, modifiedState.item]);

  useEffect(() => {
    if (!data || state.item === data) return;
    setState({ ...state, item: data, id, route: getRoute });
    setModifiedState({ ...modifiedState, item: data });
  }, [data, id, modifiedState, getRoute, state]);

  const get = (id: number, route: string) => {
    if (state.id) return;
    setState({ ...state, id, route, item: data });
    if (!data) getById(id, route);
  };

  const setOptionListFromSubItem = (subitem?: LevelFormProps) => {
    const routeList = subitem ? getAllOptionListRoute(subitem) : optionList.routeList ?? [];
    CRUD.getMultiple(routeList).then((x) => setOptionList({ ...x, routeList }));
  };

  const reload = () => {
    NotificationService.success('Enregistrement effectué avec succès !');
    getById(state.id, state.route);
    setOptionListFromSubItem();
  };

  const getById = (id: number, route: string) => {
    CRUD.getCustomObject<any>(route).then((item) => {
      if (onGetData) item = onGetData(item);
      setState({ ...state, id, route, item });
      setModifiedState({ ...modifiedState, id, route, item });
      onChange && onChange(item);
    });
  };

  return (
    <MultiLevelContext.Provider
      value={{
        state,
        setState,
        modifiedState,
        setModifiedState,
        get,
        reload,
        setOptionListFromSubItem,
        optionList,
      }}
    >
      {children}
    </MultiLevelContext.Provider>
  );
};

/**
 * Configuration du formulaire multi-niveau
 * @param id Id de l'élément contenant les sous-éléments, exemple: id du type de rapport d'intervention
 * qui contient les sous-éléments ( Catégorie puis les lignes de rapport d'intervention)
 * @param getRoute Route pour récupérer l'élément contenant les sous-éléments
 * @param config Configuration du formulaire (voir interface FirstLevelProps)
 */
export interface MultiLevelFormProps {
  id: number;
  getRoute: string | APIRoute | ((id: number) => string);
  onGetData?: (data: any) => any;
  config: FirstLevelProps;
  onChange?: (data: any) => void;
  unitarySave?: boolean;
  // Uniquement si vous donnez vous même les données
  data?: any;
}

/**
 * Configuration du premier niveau du formulaire
 * @param subListTitle Titre afficher au-dessus de la liste suivante
 * @param attrToList Attribut de l'élément contenant les sous-éléments, exemple: 'wkReportCategoryList' pour le type de rapport d'intervention
 * @param subItem Configuration de la liste suivante
 */
export interface FirstLevelProps {
  subListTitle?: string;
  attrToList: string;
  subItem?: LevelFormProps;
}

/**
 * Configuration d'un sous-élément
 * @param postRoute Route pour enregistrer l'élément, exemple: 'api/wkreporttype' pour le type de rapport d'intervention
 * @param parentIdName Nom de l'attribut de l'élément parent, exemple: 'wkReportTypeId' pour la catégorie de rapport d'intervention
 * @param columnList Liste des colonnes du formulaire, contient les informations pour créer les champs / colonnes
 * @param level Niveau du formulaire, 1 pour le premier niveau, 2 pour le deuxième niveau, etc...
 * @param data Données de l'élément du formulaire
 */
interface LevelFormProps extends FirstLevelProps {
  // key: number;
  index?: number;
  postRoute?: string | APIRoute;
  deleteRoute?: string | APIRoute;
  parentIdName: string;
  columnList: ColumnProps[];
  level?: number;
  data?: any;
  rowStyle?: (data: any) => CSSProperties;
  onChildChange?: (data: any, index: number) => void;
  unitarySave?: boolean;
  openByDefault?: boolean;
}

/**
 * Configuration d'une colonne du formulaire
 * @param id Id du champ, exemple: 'label' pour le libellé
 * @param label Label du champ, exemple: 'Libellé'
 * @param FormComponent Composant react spécifique au multilevelform, vous ne devez utiliser que des composants commancant par 'MultiLevel'
 * @param optionRoute Route pour récupérer les options du champ, exemple: 'api/wkreporttype' pour le type de rapport d'intervention
 * @param inputOption Options du champ, exemple: {options:[{id: 1, label: 'Libellé 1'}, {id: 2, label: 'Libellé 2'}]} si votre liste est statique
 * @param width Largeur de la colonne, exemple: 50 pour 50px, sert à figé la largeur de la colonne
 */
interface ColumnProps {
  id: string;
  label: string;
  FormComponent: (payload: MultiLevelInputProps) => any;
  optionRoute?: string | APIRoute;
  inputOption?: {};
  inputOptionWhen?: (data: any) => {};
  disableIf?: (data: any) => boolean;
  width?: number;
}

/**
 * Formulaire multi-niveau, encapsule le formulaire de chaque niveau avec le contexte
 * @param props Configuration du formulaire multi-niveau
 */
export const MultiLevelForm: FC<MultiLevelFormProps> = (props) => {
  return (
    <MultiLevelFormContextProvider {...props}>
      <FirstLevel {...props} />
    </MultiLevelFormContextProvider>
  );
};

/**
 * Premier niveau du formulaire multi-niveau
 * @param id l'id d'appel du formulaire (conjoint avec getRoute)
 * @param getRoute la route d'appel du formulaire (conjoint avec id)
 * @param config la configuration du formulaire
 * @param unitarySave si le formulaire doit peut être sauvegardé unitairement
 * @param openByDefault si toutes les lignes doivent être ouvertes par défaut
 */
const FirstLevel: FC<MultiLevelFormProps> = ({ id, getRoute, config, unitarySave }) => {
  const { state, modifiedState, setModifiedState, get, setOptionListFromSubItem } = useContext(MultiLevelContext);
  const [isFirstRender, setFirstRender] = useState<boolean>(true);

  if (isFirstRender) {
    get(id, typeof getRoute === 'function' ? getRoute(id) : `${getRoute}/${id}`);
    if (!!config.subItem) setOptionListFromSubItem(config.subItem);
    setFirstRender(false);
  }

  const onChildChangeHandler = (child: any, index?: number) => {
    if (index === null || index === undefined) return;
    modifiedState.item[index] = child;
    modifiedState.item = { ...modifiedState.item };
    setModifiedState({ ...modifiedState });
  };

  if (!state.item) return <LoadingScreen />;
  return (
    <LevelForm
      key={0}
      index={0}
      {...config}
      level={1}
      data={state.item}
      parentIdName="no-use"
      postRoute="no-use"
      columnList={[]}
      unitarySave={unitarySave}
      onChildChange={onChildChangeHandler}
      openByDefault={false}
    />
  );
};

/**
 * Formulaire multi-niveau (niveau 2 et plus) composant récursif infini configurer par les "subItem"
 * @param key Clé de l'élément
 * @param index Index de l'élément
 * @param level Niveau du formulaire, 1 pour le premier niveau, 2 pour le deuxième niveau, etc...
 * @param subListTitle Titre afficher au-dessus de la liste suivante
 * @param postRoute Route pour enregistrer l'élément, exemple: 'api/wkreporttype' pour le type de rapport d'intervention
 * @param columnList Liste des colonnes du formulaire, contient les informations pour créer les champs / colonnes
 * @param subItem Configuration de la liste suivante
 * @param data Données de l'élément du formulaire
 * @param rowStyle Style de la ligne
 * @param deleteRoute Route pour supprimer l'élément, exemple: 'api/wkreporttype' pour le type de rapport d'intervention
 * @param onChildChange Fonction pour modifier les données de l'élément parent
 * @param unitarySave si le formulaire doit peut être sauvegardé unitairement
 * @param openByDefault si toutes les lignes doivent être ouvertes par défaut
 */
const LevelForm: FC<LevelFormProps> = ({
  key,
  index,
  level,
  subListTitle,
  postRoute,
  columnList,
  subItem,
  data,
  rowStyle = () => ({}),
  deleteRoute,
  onChildChange,
  unitarySave = true,
  openByDefault = false,
}: any) => {
  const [dataState, setDataState] = useState<any>(data);
  const [isCollapseOpen, setCollapseOpen] = useState(openByDefault);
  const { reload } = useContext(MultiLevelContext);
  const haveSubItem = !!subItem && !!dataState[subItem.attrToList];
  const modified = dataState.multiFormModified;

  const fullReadOnly = useMemo(() => {
    return columnList.every((x: any) => x.disableIf && x.disableIf(dataState));
  }, [columnList, dataState]);

  const handleSubmit = () => CRUD.post(postRoute, dataState, !!dataState.id).then(reload);
  const handleDelete = () => deleteRoute && CRUD.deleteById(deleteRoute, dataState.id).then(reload);

  useEffect(() => {
    setDataState(data);
  }, [data]);

  const handleUpdate = (value: any, column: any, index: number) => {
    const payload = { ...dataState, [column.id]: value, multiFormModified: true };
    setDataState(payload);
    onChildChange && onChildChange(payload, index);
  };

  const onChildChangeHandler = (child: any, index: number) => {
    dataState[subItem!.attrToList][index] = child;
    setDataState({ ...dataState });
    onChildChange && onChildChange({ ...dataState }, index);
  };

  const handleCreate = () => {
    if (!subItem) return NotificationService.error("La sous-liste n'existe pas");
    const list = dataState[subItem.attrToList];
    if (!list) return NotificationService.error("La liste n'existe pas");
    list.push({ [subItem.parentIdName]: data.id });
    setDataState({ ...dataState, [subItem.attrToList]: list });
  };
  const backgroundColor = index % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';
  const gridStyle = getGrid({ subItem, columnList, deleteRoute } as any, !!postRoute, fullReadOnly);
  const formComp: JSX.Element = (
    <div className="form-list" style={{ ...gridStyle, backgroundColor, ...rowStyle(dataState) }}>
      {columnList.map((column: any, index: number) => {
        let inputOption = { ...column.inputOption, disabled: column.disableIf && column.disableIf(dataState) };
        if (column.inputOptionWhen) inputOption = { ...inputOption, ...column.inputOptionWhen(dataState) };
        return (
          <div className={'form-cell grid cell-' + index} key={index}>
            {column.FormComponent({
              value: dataState[column.id],
              setValue: (value: any) => handleUpdate(value, column, index),
              optionRoute: column.optionRoute,
              row: dataState,
              inputOption,
            })}
          </div>
        );
      })}
      <div className="form-cell action-cell">
        <div className="flex-h end actions">
          <div className="action-holder">
            {haveSubItem && <UI.DeployIcon size="small" value={isCollapseOpen} onchange={setCollapseOpen} />}
            {unitarySave && <CmsIcon disabled={!modified} icon="save" tooltip="Sauvegarder" onClick={handleSubmit} />}
            {!fullReadOnly && (
              <CmsIcon disabled={!modified} icon="undo" tooltip="Annuler" onClick={() => setDataState(data)} />
            )}
            {deleteRoute && dataState.id && <CmsIcon icon="delete" tooltip="Supprimer" onClick={handleDelete} />}
          </div>
        </div>
      </div>
    </div>
  );

  const abstractComp = (): JSX.Element => {
    if (!haveSubItem || !subItem) return <></>;
    const subList: any[] = dataState[subItem.attrToList];
    const isAddDisabled = !subList;
    return (
      <div style={{ minWidth: subItem.columnList.length * 200 + 120 + 'px' }}>
        <div className="add-title">
          <h3>{subListTitle ?? 'Liste des sous-éléments'}</h3>
          {subItem?.postRoute && (
            <div className="circle">
              <CmsIcon icon="add" tooltip="Créer" onClick={handleCreate} disabled={isAddDisabled} />
            </div>
          )}
        </div>
        <HeaderList subItem={subItem} />
        {dataState[subItem.attrToList]
          .filter((x: any) => !x.hideRow)
          .map((row: any, index: number) => (
            <LevelForm
              key={index}
              {...subItem}
              data={row}
              level={(level ?? 0) + 1}
              onChildChange={(x) => onChildChangeHandler(x, index)}
              openByDefault={!!subItem.openByDefault}
              {...{ unitarySave, index }}
            />
          ))}
      </div>
    );
  };

  return (
    <div className={'level-form styled-h-scroll level' + level + (isCollapseOpen ? ' open' : '')}>
      {level !== 1 && formComp}
      {(haveSubItem && level !== 1 && (
        <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
          {abstractComp()}
        </Collapse>
      )) ||
        abstractComp()}
    </div>
  );
};

function getGrid(item?: LevelFormProps, unitarySave?: boolean, readOnly?: boolean): CSSProperties {
  let col = item?.columnList.reduce((acc, x) => (acc += x.width ? `${x.width}px ` : 'minmax(150px, 1fr) '), '') ?? '';
  col += (+!!item?.subItem + +!!item?.deleteRoute + +!readOnly + +!!unitarySave) * 30 + 10 + 'px';
  return { gridTemplateColumns: col };
}

/**
 * En-tête de la liste des sous-éléments
 * @param columnList Liste des colonnes du formulaire, contient les informations pour créer les champs / colonnes
 * @param colGridStyle Style de la grille des colonnes
 */
const HeaderList: FC<{ subItem: LevelFormProps }> = ({ subItem }) => {
  const colGrid = getGrid(subItem, !!subItem.postRoute, false);
  return (
    <div className="form-list header" style={colGrid}>
      {subItem.columnList.map((column, index) => (
        <div key={index}>
          <UI.TextEllipse text={column.label} style={{ fontWeight: 'bold' }} />
        </div>
      ))}
      <div></div>
    </div>
  );
};
