// fichier de configuration pour les environnements de PRODUCTION
// les valeurs commençant par GITLAB_ sont définies dans Gitlab CI

export const CMS_FRONTEND_ENVIRONMENT: string = 'rousseau'; // 'castres' | 'c4energies' | 'rousseau'
export const FRONTEND_VERSION = '2.10.0 - Build:7421';
export const BASE_URL: string = 'https://cms.rousseau-batiment.com/';

export const API_URL: string = BASE_URL + 'api/';
export const GOOGLE_API_KEY: string = 'AIzaSyBYZ5uyWO-CCvDn5fBSgrCJTHyzdgs39E0';
export const ENV_ISDEV: boolean = false;
// Lorsqu'il reste X temps de durée de vie de session, déclenche le rafraichissement de la session lors prochain changement de page.
export const REFRESH_TOKEN_TIMER = 60; // exprimé en minutes;

//configuration Sentry
export const SENTRY_KEY = '02a900f456d54d759fd7edc61720c9d1';
export const SENTRY_ID = 10;
export const SENTRY_SAMPLE_RATE = 0.25;
export const SENTRY_DSN = 'https://' + SENTRY_KEY + '@' + BASE_URL.replace(/https?:\/\//i, '') + 'sentry/' + SENTRY_ID;
