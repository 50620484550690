import React, { CSSProperties, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import {
  Cell,
  CellContext,
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  Table as TansTable,
  useReactTable,
} from '@tanstack/react-table';
import CmsFilterCalculator from './helper/CmsTableFilterCalculator';
import CmsTableFilter, {
  CmsFilterOptions,
  CmsFormFilter,
  CmsGlobalFilter,
  CmsTableFilterHandler,
  CmsTableFilterType,
  CmsTableHandler,
  ColumnDefToRtColumn,
  TradFilter,
} from './helper/CmsTableFilter';
import { CmsButton, CmsPaper } from '../shared/Ui';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material/';
import { ENV_ISDEV } from '../../constant/API_URL';
import './table.style.scss';
import { GlobalContext, GlobalContextListProps } from '../../context/Global.context';
import { InputUI, UI } from '../shared';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import { TableSortLabel, Tooltip } from '@mui/material';
import { GetFilterValueFromURL, SetCmsTableFiltersInURL } from '../../helper/UrlDataTableHandler';
import SkeletonTable from './SkeletonTable';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Utils from '../../helper/Utils';
import CmsIcon from '../shared/CmsIcon';
import ReloadDataOption from './helper/ReloadDataOption';
import { Indicator } from './helper/Indicator';
import { LabelCalc } from '../../interface/CommonType';
import CmsTableCell from './helper/CmsTableCell';
import csvExport from '../../helper/csvExport';
import { useLocation, useNavigate } from 'react-router-dom';
import { CmsSummaryTable } from './helper/CmsSummaryTable';
import { CmsMenuButton } from '../shared/Menu';
import notificationService from '../../service/NotificationService';
import { authenticationService } from '../../service/Authentication.service';
import { CurrentUser } from '../../interface/User';
import { CmsPagination } from './helper/CmsTablePagination';

export interface CmsColumnDef<T> {
  id: string;
  header?: string | ReactNode | FC;
  filterHeader?: string | (() => ReactNode);
  footer?: string | ((props: { column: ColumnDef<T, any> }) => ReactNode);
  cell?: (info: CellContext<T, any>) => any;
  accessorKey?: string;
  columns?: CmsColumnDef<T>[];
  accessorFn?: (row: T) => any;
  filterFn?: FilterFn<any>;
  Filter?: any;
  filterOptions?: CmsFilterOptions;
  // Faite attention à la forme de votre objet, il doit être compatible avec le type de filtre
  // boolfilter == boolean
  // selectfilter == string | number
  // numberfilter == { type: CmsTableFilterType; value: any };
  // stringfilter == { type: CmsTableFilterType; value: any };
  // datefilter == { type: CmsTableFilterType; value: any };
  defaultFilterValue?: defaulFilterType | ((user: CurrentUser) => defaulFilterType | undefined | null);
  // sortingFn?: SortingFn<any>; // Ne plus utiliser
  cmsSortingFn?: (a: any, b: any, desc: boolean) => number;
  enableColumnFilter?: boolean;
  hide?: true | 'hiddenByDefault'; // hiddenByDefault permet de cacher la colonne par défaut mais de laisser la possibilité de l'afficher
  updatableColInputType?: 'string' | 'number' | 'date'; // type de l'input pour la colonne updatable, ne fonctionne que pour le cmsfrontendtable
  updatableColRight?: string; // Droit nécessaire pour modifier les données par cette colonne, ne fonctionne que pour le cmsfrontendtable
  backgroundColor?: { dark: string; light: string };
  size?: number;
  minSize?: number;
  maxSize?: number;
  noClick?: boolean;
}

type defaulFilterType = boolean | number | string | Date | any[] | { type: CmsTableFilterType; value: any };

//# region Backend Table

interface CmsBackendTableProps extends TableAction {
  columns: CmsColumnDef<any>[];
  title: string;
  route: APIRoute | string;
  actions?: ReactNode[];
  exportList?: ExportListProps[];
  setFiltersInUrl?: boolean;
  canExport?: boolean;
  showFooter?: boolean;
  SummaryComponent?: ({ summaryList }: any) => ReactNode;
  invertClick?: boolean;
  globalFilterIcon?: ReactNode;
  rowStyle?: (row: Row<any>) => CSSProperties | undefined;
}

interface ExportListProps {
  title: string;
  type: 'csv' | 'xlsx';
  route: string;
}

/**
 * Tableau Backend, c'est a dire qu'il attend que l'api lui donne les données filtrées et paginée. Tout les calcules
 * sont fait en backend. Vous ne pouvez ni faire de tableau draggable, ni de sauvegarde de données dans le global context.
 * @param columns liste des colonnes CmsColumnDef
 * @param title titre du tableau
 * @param route route de l'api pour récupérer les données (méthode post avec le payload de filtre, try, pagination).
 * @param actions liste des actions du tableau (clique, navigation, etc...)
 * @param optionnalExport liste d'objets décrivant cahcun un bouton d'export supplémentaire optionnel, contient : un type d'export, une route backend, un id et son texte d'affichage
 * @param setFiltersInUrl défini si on doit sauvegarder les filtres dans l'url
 * @param onRowClick évènement de clic sur une ligne
 * @param onCellClick évènement de clic sur une cellule
 * @param navigateTo défini comment un céllule doit naviguer (url), si l'url commence par http, cela ouvre un nouvel onglet
 * @param initialSorting défini le tri initial du tableau
 * @param draggableRow défini si le tableau doit être draggable
 * @param canExport défini si on peut exporter le tableau
 * @param showFooter défini si le tableau doit afficher le footer
 * @param invertClick Force l'ouverture d'un nouvel onglet pour les liens
 * @param SummaryComponent Composant de synthèse des données
 */
export const CmsBackendTable: FC<CmsBackendTableProps> = ({
  columns,
  title,
  route,
  actions = [],
  exportList,
  setFiltersInUrl,
  onRowClick,
  onCellClick,
  navigateTo,
  initialSorting,
  draggableRow = false,
  canExport = false,
  showFooter = false,
  invertClick,
  globalFilterIcon,
  rowStyle,
  SummaryComponent = CmsSummaryTable,
}) => {
  const { globalHistory } = useContext(GlobalContext);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isReloading, setIsReloading] = useState<string>();
  const [globalFilter, setGlobalFilter] = React.useState<any>({ value: undefined, type: 0 });
  const [state, setState] = React.useState<any[]>([]);
  const currentUser = useMemo(() => authenticationService.getCurrentUser(), []);
  const memoColumns = useMemo(() => fillDefaultColumnDef(columns, currentUser), [columns, currentUser]);
  const [filter, setFilter] = React.useState<any[]>(getDefaultFilterList(memoColumns));
  const [pagination, setPagination] = React.useState<any>({ pageIndex: 0, pageSize: 20 });
  const [sorting, setSorting] = React.useState<any>((initialSorting && [initialSorting]) || null);
  const [count, setCount] = useState<number>(0);
  const [oldPayload, setOldPayload] = useState<string>();
  const [summary, setSummary] = useState<any[]>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [colShown, setColShown] = useState<any>(columns.reduce((x, y: any) => ({ ...x, [y.id]: !y.hide }), {}));
  const totalColShown = useMemo(() => memoColumns.filter((x) => !x.hide).length, [memoColumns]);
  const fullExportList: any[] = useMemo(() => {
    return [{ title: 'Export CSV', type: 'csv' }, { title: 'Export EXCEL', type: 'xlsx' }, ...(exportList ?? [])];
  }, [exportList]);
  const navigate = useNavigate();
  const location = useLocation();

  if (firstRender) {
    setFirstRender(false);
    const filterFromUrl = TradFilter(
      GetFilterValueFromURL(ColumnDefToRtColumn(memoColumns), globalHistory, navigate, location),
      memoColumns,
    );
    const filterOnly = filterFromUrl.filter((x: any) => !x.isSort && !x.isCol && !x.isPage);
    const sortBy = filterFromUrl.find((x: any) => x.isSort);
    const pagi = {
      pageIndex: filterFromUrl.find((x) => x.id === 'pageIndex')?.value ?? 0,
      pageSize: filterFromUrl.find((x) => x.id === 'pageSize')?.value ?? 20,
    };
    if (pagi.pageIndex !== 0 || pagi.pageSize !== 20) setPagination(pagi);
    if (sortBy) setSorting([{ id: sortBy.id, desc: sortBy.desc }]);
    const col = filterFromUrl.find((x: any) => x.isCol);
    if (col) {
      let tempCol = { ...colShown };
      for (let c of col.value) tempCol[c] = !tempCol[c];
      setColShown(tempCol);
    }
    if (filterOnly.length > 0) {
      setIsFilterOpen(true);
      setFilter(filterOnly);
    }
  } else if (setFiltersInUrl) {
    const cols = getColShowEdited(columns, colShown);
    const pagi = { ...pagination, initialPageSize: 20 };
    SetCmsTableFiltersInURL(filter, globalHistory, navigate, location, pagi, globalFilter, sorting, cols);
  }

  useEffect(() => {
    if (isReloading === 'waiting') return;
    if (isReloading === 'loading') {
      setIsReloading('waiting');
      return;
    }
    if (isReloading === 'loading') return;
    const payload = CmsTableFilter.tradFilterFrontToBack(filter, pagination, sorting, globalFilter);
    const stringifiedPayload = JSON.stringify(payload);
    if (oldPayload === stringifiedPayload && !refresh) return;
    setOldPayload(stringifiedPayload);
    setIsReloading('loading');
    CRUD.postWithFilter<any>(route, payload)
      .then((result) => {
        setState(result.data);
        setCount(result.count);
        setSummary(result.summary);
        setIsLoaded(true);
      })
      .finally(() => setIsReloading(isReloading === 'waiting' ? 'reload' : undefined));
    setRefresh(false);
  }, [filter, route, globalFilter, oldPayload, pagination, refresh, sorting, isReloading]);

  const filterHandler: CmsTableFilterHandler = {
    filter,
    globalFilter,
    setPagination,
    setSorting,
    sorting,
    pagination,
    count,
    setGlobalFilter: (filter) => {
      if (pagination.pageIndex !== 0) setPagination({ ...pagination, pageIndex: 0 });
      setGlobalFilter(filter);
    },
    setFilter: (filters) => {
      if (pagination.pageIndex !== 0) setPagination({ ...pagination, pageIndex: 0 });
      setFilter(filters);
    },
  };

  const handleExport = (type: 'csv' | 'xlsx', exportRoute = '/ExportWithFilter', isExcel: boolean) => {
    if (isExporting) return notificationService.error('Un export est déjà en cours');
    if (count > 50000 && isExcel) {
      const message = 'Vous ne pouvez pas exporter plus de 50000 lignes à la fois, ';
      return notificationService.error(message + count + ' actuellement');
    }
    const payload = CmsTableFilter.tradFilterFrontToBack(filter, pagination, sorting, globalFilter);
    setIsExporting(true);
    CRUD.getBlob(route + exportRoute + '?format=' + type, payload)
      .then((result) => Utils.downloadFile(result, 'export.' + type))
      .finally(() => setIsExporting(false));
  };

  const exportComponent = () => {
    if (!canExport) return <></>;
    const icon = <CmsIcon icon={!isExporting ? 'download' : 'waiting'} typeColor="primary" />;
    return (
      <CmsMenuButton title={<>{icon} Exports</>} disabled={isExporting}>
        {fullExportList.map((exportButton, i) => (
          <Tooltip
            placement="left"
            title={count > 50000 && i === 1 ? 'Vous ne pouvez pas exporter plus de 50000 lignes à la fois' : ''}
            style={{ opacity: count > 50000 && i === 1 ? 0.5 : 1 }}
            key={i}
            onClick={() => handleExport(exportButton.type, exportButton.route, i === 1)}
          >
            {exportButton.title}
          </Tooltip>
        ))}
      </CmsMenuButton>
    );
  };

  const actionList = [...actions, <ReloadDataOption updateListAction={() => setRefresh(true)} />];
  return (
    <CmsPaper className="cms-table cms-backend-table" title={title} actions={actionList}>
      {(isLoaded && (
        <>
          {summary && SummaryComponent({ summaryList: summary })}
          <TableContainer
            route={route}
            advancedSearchButton={
              <AdvancedFilterButton onClick={() => setIsFilterOpen(!isFilterOpen)} filter={filter} />
            }
            exportComponent={exportComponent()}
            stateHandler={{ state, setState }}
            filterHandler={filterHandler}
            columns={memoColumns}
            isFilterOpen={isFilterOpen}
            tableAction={{ onRowClick, onCellClick, navigateTo }}
            draggableRow={draggableRow}
            showHeader="always"
            showFooter={showFooter}
            invertClick={invertClick}
            setFiltersInUrl={setFiltersInUrl}
            disableResetPageIndex={true}
            globalFilterIcon={globalFilterIcon}
            colShownHandler={{ colShown, setColShown }}
            rowStyle={rowStyle}
          />
        </>
      )) || <SkeletonTable columnNumber={totalColShown} />}
      <UI.Waiting isLoading={!!isReloading} />
    </CmsPaper>
  );
};

//#endregion

//#region Frontend Table

interface CmsFrontendTableProps extends TableAction {
  columns: CmsColumnDef<any>[];
  title: string;
  route: APIRoute | string;
  actions?: ReactNode[];
  setFiltersInUrl?: boolean;
  draggableRow?: boolean;
  indicatorList?: LabelCalc[];
  globalTable?: GlobalContextListProps;
  dataBuilder?: (data: any[]) => any[];
  autoRefresh?: boolean;
  controlledPagination?: { pagination: any; setPagination: any };
  controlledFilter?: { filterList: any[]; setFilterList: any };
  controlledState?: { state: any[]; setState: any };
  showHeader?: 'always' | 'never' | 'auto';
  showFooter?: boolean;
  downloadable?: 'csv' | 'excel' | 'csv&excel' | false;
  initialPageSize?: 10 | 20 | 50 | 100;
  paperStyle?: CSSProperties;
  invertClick?: boolean;
  disableResetPageIndex?: boolean;
  headerComponent?: ReactNode;
  rowStyle?: (row: Row<any>) => CSSProperties;
}

/**
 * Tableau Frontend, c'est a dire qu'il attend qu'on lui donne toute les données et qu'il les filtre lui même.
 * Ce tableau doit également gérer le globalContext pour la sauvegarde des données des tableau, ce que le CmsBackendTable
 * ne fait pas, de même, seul le tableau FrontEnd pourra être draggable.
 * @param columns liste des colonnes CmsColumnDef
 * @param title titre du tableau
 * @param route route de l'api pour récupérer les données
 * @param actions liste des actions du tableau (clique, navigation, etc...)
 * @param setFiltersInUrl défini si on doit sauvegarder les filtres dans l'url
 * @param onRowClick évènement de clic sur une ligne
 * @param onCellClick évènement de clic sur une cellule
 * @param navigateTo défini comment un céllule doit naviguer (url), si l'url commence par http, cela ouvre un nouvel onglet
 * @param draggableRow défini si le tableau doit être draggable
 * @param initialSorting défini le tri initial du tableau
 * @param indicatorList permet d'afficher des indicateurs, calculés sur les données du tableau
 * @param globalTable permet de sauvegarder les données du tableau dans le global context
 * @param dataBuilder permet de modifier les données avant de les afficher dans le tableau
 * @param autoRefresh défini si le tableau doit se rafraichir automatiquement
 * @param controlledPagination permet de contrôler la pagination du tableau depuis l'extérieur
 * @param controlledFilter permet de contrôler les filtres du tableau depuis l'extérieur
 * @param controlledState permet de contrôler les données du tableau depuis l'extérieur
 * @param showHeader défini si le tableau doit afficher l'en-tête (avec filtre + pagination + etc...)
 * @param showFooter défini si le tableau doit afficher le footer
 * @param initialPageSize 50 par défaut, défini le nombre de ligne par page
 * @param canExportById Export Par liste d'Id, a ne pas confondre avec l'export par filtre
 * @param paperStyle style du composant Paper
 * @param invertClick Force l'ouverture d'un nouvel onglet pour les liens
 * @param disableResetPageIndex Empêche le reset de la page à chaque changement de filtre
 * @param headerComponent Composant à afficher en haut du tableau
 */
export const CmsFrontendTable: FC<CmsFrontendTableProps> = ({
  columns,
  title,
  route,
  actions = [],
  setFiltersInUrl,
  onRowClick,
  onCellClick,
  navigateTo,
  draggableRow = false,
  initialSorting,
  indicatorList,
  globalTable,
  dataBuilder,
  autoRefresh,
  controlledPagination,
  controlledFilter,
  controlledState,
  showHeader = 'auto',
  showFooter = false,
  initialPageSize = 50,
  paperStyle,
  downloadable = 'csv',
  invertClick,
  disableResetPageIndex = true,
  headerComponent,
  rowStyle,
}) => {
  const { globalContext, setGlobalContextList, globalHistory } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = React.useState<string>('');
  const [localState, setlocalState] = React.useState<any[]>([]);
  const [localPagination, setLocalPagination] = React.useState<any>({ pageIndex: 0, pageSize: initialPageSize });
  const currentUser = useMemo(() => authenticationService.getCurrentUser(), []);
  const memoColumns = useMemo(() => fillDefaultColumnDef(columns, currentUser), [columns, currentUser]);
  const [localFilter, setLocalFilter] = React.useState<any[]>(getDefaultFilterList(memoColumns));
  const [sorting, setSorting] = React.useState<any>((initialSorting && [initialSorting]) || null);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const totalColShown = useMemo(() => memoColumns.filter((x) => !x.hide).length, [memoColumns]);
  const [colShown, setColShown] = useState<any>(columns.reduce((x, y: any) => ({ ...x, [y.id]: !y.hide }), {}));
  const filter = controlledFilter?.filterList ?? localFilter;
  const setFilter = controlledFilter?.setFilterList ?? setLocalFilter;
  const state = controlledState?.state ?? localState;
  const setState = controlledState?.setState ?? setlocalState;
  const pagination = controlledPagination?.pagination ?? localPagination;
  const setPagination = controlledPagination?.setPagination ?? setLocalPagination;

  const sortedState = useMemo(() => {
    return handleSorting(globalTable ? globalContext[globalTable.target]?.list ?? [] : state, sorting, memoColumns);
  }, [globalContext, globalTable, memoColumns, sorting, state]);

  useEffect(() => {
    if (!isLoaded && !!globalTable && globalContext[globalTable.target]?.list) setIsLoaded(true);
  }, [globalContext, globalTable, isLoaded]);

  const advancedFilter = useMemo(() => {
    if (!memoColumns.find((x) => x.Filter)) return <></>;
    return <AdvancedFilterButton onClick={() => setIsFilterOpen(!isFilterOpen)} filter={filter} />;
  }, [filter, isFilterOpen, memoColumns]);

  const callFunction = () => {
    if (!!controlledState) return setIsLoaded(true);
    if (globalTable) return setGlobalContextList({ ...globalTable, configList: columns, dataBuilder });
    CRUD.getList<any>(route).then((result) => {
      if (dataBuilder) setState(dataBuilder(parseDateByColumnFilterType(columns, result)));
      else setState(parseDateByColumnFilterType(columns, result));
      setIsLoaded(true);
    });
  };

  if (firstRender) {
    setFirstRender(false);
    const filterFromUrl = TradFilter(
      GetFilterValueFromURL(ColumnDefToRtColumn(memoColumns), globalHistory, navigate, location),
      memoColumns,
    );
    let filterOnly = filterFromUrl.filter((x: any) => !x.isSort && !x.isCol && !x.isPage);
    const sortBy = filterFromUrl.find((x: any) => x.isSort);
    const pagi = {
      pageIndex: filterFromUrl.find((x) => x.id === 'pageIndex')?.value ?? 0,
      pageSize: filterFromUrl.find((x) => x.id === 'pageSize')?.value ?? initialPageSize,
    };
    if (pagi.pageIndex !== 0 || pagi.pageSize !== initialPageSize) setPagination(pagi);
    if (sortBy) setSorting([{ id: sortBy.id, desc: sortBy.desc }]);
    const col = filterFromUrl.find((x: any) => x.isCol);
    if (col) {
      let tempCol = { ...colShown };
      for (let c of col.value) tempCol[c] = !tempCol[c];
      setColShown(tempCol);
    }
    if (controlledFilter && filterOnly.length === 0) filterOnly = getDefaultFilterList(columns);
    if (filterOnly.length > 0) {
      setIsFilterOpen(true);
      setFilter(filterOnly);
    }
    callFunction();
  } else if (setFiltersInUrl) {
    const cols = getColShowEdited(columns, colShown);
    const pagi = { ...pagination, initialPageSize };
    SetCmsTableFiltersInURL(filter, globalHistory, navigate, location, pagi, globalFilter, sorting, cols);
  }

  const filterHandler: CmsTableFilterHandler = {
    filter,
    setGlobalFilter,
    globalFilter,
    setSorting,
    sorting,
    setPagination,
    pagination,
    setFilter: (x) => {
      setFilter(x);
      setPagination({ ...pagination, pageIndex: 0 });
    },
  };

  if (autoRefresh) actions = [...actions, <ReloadDataOption updateListAction={() => callFunction()} />];
  return (
    <CmsPaper style={paperStyle} className="cms-table cms-frontend-table" title={title} actions={actions}>
      {headerComponent}
      {(isLoaded && (
        <TableContainer
          route={route}
          isFrontEndTable
          advancedSearchButton={advancedFilter}
          exportComponent={<></>}
          stateHandler={{ state: sortedState, setState }}
          filterHandler={filterHandler}
          columns={memoColumns}
          isFilterOpen={isFilterOpen}
          tableAction={{ onRowClick, onCellClick, navigateTo }}
          draggableRow={draggableRow}
          indicatorList={indicatorList}
          showHeader={showHeader}
          showFooter={showFooter}
          downloadable={downloadable}
          invertClick={invertClick}
          disableResetPageIndex={disableResetPageIndex}
          setFiltersInUrl={setFiltersInUrl}
          colShownHandler={{ colShown, setColShown }}
          rowStyle={rowStyle}
        />
      )) || <SkeletonTable columnNumber={totalColShown} />}
    </CmsPaper>
  );
};

function getColShowEdited(defaultColumns: any[], currentColumns: any[]): string[] {
  let result = [];
  for (let col of defaultColumns) {
    if (!col.hide && currentColumns[col.id] === false) result.push(col.id);
    else if (col.hide === 'hiddenByDefault' && currentColumns[col.id] === true) result.push(col.id);
  }
  return result;
}

function handleSorting(data: any[], sorting: any, columns: CmsColumnDef<any>[]) {
  if (!sorting || sorting.length < 1) return data;
  const { id, desc } = sorting[0];
  const columnToSort = columns.find((x) => x.id === id);
  if (!columnToSort?.cmsSortingFn) return data;
  const sortingFn: Function = columnToSort.cmsSortingFn as Function;
  let access: Function = columnToSort.accessorFn as Function;
  if (!access && columnToSort.accessorKey) access = (row: any) => row[columnToSort.accessorKey as string];
  else if (!access) access = (row: any) => row[columnToSort.id];
  return Object.assign([], data).sort((a, b) => sortingFn(access(a), access(b), desc));
}

const FrontEndExport = (
  filteredData: any[],
  columnList: CmsColumnDef<any>[],
  title: string,
  downloadable?: 'csv' | 'excel' | 'csv&excel' | false,
  backDownloadUrl?: string,
) => {
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const handleExport = () => {
    const data = filteredData.map((x) => x.original);
    const filteredColumnList = columnList.filter((x) => x.hide !== true);
    const titleWithAttr = filteredColumnList.map((x) => ({ title: x.header, attr: x.accessorFn ?? x.accessorKey }));
    csvExport(data, titleWithAttr as any, title);
  };

  const handleBackendExport = () => {
    const idList = filteredData.map((x) => x.original.id);
    setIsExporting(true);
    CRUD.getBlob((backDownloadUrl ?? '') + '/ExportByIdList', { idList })
      .then((result: Blob) => {
        Utils.downloadFile(result, `export-${Utils.setToKebabDate(new Date())}.xlsx`);
      })
      .finally(() => setIsExporting(false));
  };

  const icon = <CmsIcon icon={!isExporting ? 'download' : 'waiting'} typeColor="primary" />;
  return (
    <>
      {(downloadable === 'excel' || downloadable === 'csv&excel') && (
        <CmsButton startIcon={icon} onClick={handleBackendExport} variant="text" disabled={isExporting}>
          Export Excel
        </CmsButton>
      )}
      {(downloadable === 'csv' || downloadable === 'csv&excel') && (
        <CmsButton startIcon={<CmsIcon icon="download" typeColor="primary" />} onClick={handleExport} variant="text">
          Export CSV
        </CmsButton>
      )}
    </>
  );
};

//#endregion

interface TableContainerProps {
  route: APIRoute | string;
  stateHandler: CmsTableHandler;
  filterHandler?: CmsTableFilterHandler;
  columns: CmsColumnDef<any>[];
  isFilterOpen: boolean;
  tableAction: TableAction;
  draggableRow: boolean;
  exportComponent: ReactNode;
  advancedSearchButton?: ReactNode;
  indicatorList?: LabelCalc[];
  isFrontEndTable?: boolean;
  showHeader?: 'always' | 'never' | 'auto';
  showFooter?: boolean;
  canExportCSV?: boolean;
  downloadable?: 'csv' | 'excel' | 'csv&excel' | false;
  invertClick?: boolean;
  disableResetPageIndex?: boolean;
  setFiltersInUrl?: boolean;
  globalFilterIcon?: ReactNode;
  rowStyle?: (row: Row<any>) => CSSProperties | undefined;
  colShownHandler: { colShown: any; setColShown: any };
}

interface TableAction {
  onRowClick?: (row: Row<any>) => void;
  onCellClick?: (cell: Cell<any, unknown>) => void;
  navigateTo?: (id: any, cell: Cell<any, unknown>) => string;
  draggableRow?: boolean;
  initialSorting?: defaultSorting;
}

interface defaultSorting {
  id: string;
  desc: boolean;
}
// interface CmsColumnFilter extends ColumnFilter {
//   type: string;
// }

/**
 * Coeur du ReactTable V8, il ne doit jamais être utilisé directement.
 * Vous devez utiliser CmsBackendTable ou CmsFrontendTable.
 * @param route route de l'api pour récupérer les données (ici utilisé pour les exports car les données sont gérer par les composant parent du table container)
 * @param stateHandler permet de gérer le state du tableau (les données)
 * @param filterHandler permet de gérer les filtres du tableau
 * @param columns liste des colonnes CmsColumnDef
 * @param isFilterOpen permet d'afficher ou non le composant de filtre
 * @param tableAction permet de gérer les actions sur le tableau (clic cellule, navigation, etc...)
 * @param draggableRow rend le tableau draggable
 * @param exportComponent permet d'afficher le lien d'export
 * @param advancedSearchButton
 * @param indicatorList permet d'afficher des indicateurs, calculés sur les données du tableau
 * @param isFrontEndTable défini si le tableau est un tableau frontend ou backend
 * @param showFooter défini si le tableau doit afficher le footer
 * @param canExportById Export Par liste d'Id, a ne pas confondre avec l'export par filtre
 */
const TableContainer: FC<TableContainerProps> = ({
  route,
  stateHandler,
  filterHandler,
  columns,
  isFilterOpen,
  tableAction,
  draggableRow = false,
  exportComponent,
  advancedSearchButton,
  indicatorList,
  isFrontEndTable = false,
  showHeader = 'auto',
  showFooter = false,
  downloadable,
  invertClick = false,
  disableResetPageIndex = false,
  setFiltersInUrl = false,
  globalFilterIcon,
  colShownHandler,
  rowStyle,
}) => {
  const { theming } = useContext(GlobalContext);

  const reorderRow = (draggedRowIndex: number, targetRowIndex: number) => {
    if (!stateHandler?.state) return;
    const data = stateHandler?.state;
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0] as any);
    stateHandler.setState([...data]);
  };

  const table = useReactTable({
    data: stateHandler?.state as any,
    columns: columns as any,
    filterFns: { fuzzy: CmsFilterCalculator.includes },
    globalFilterFn: CmsFilterCalculator.includes,
    state: {
      columnVisibility: colShownHandler.colShown,
      columnFilters: filterHandler?.filter,
      globalFilter: filterHandler?.globalFilter,
      sorting: filterHandler?.sorting,
      pagination: filterHandler?.pagination,
    },
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
    manualSorting: true,
    enableGlobalFilter: isFrontEndTable,
    enableFilters: isFrontEndTable,
    enableColumnFilters: isFrontEndTable,
    manualFiltering: !isFrontEndTable,
    manualPagination: !isFrontEndTable,
    onColumnVisibilityChange: colShownHandler.setColShown,
    onColumnFiltersChange: filterHandler?.setFilter,
    onGlobalFilterChange: filterHandler?.setGlobalFilter,
    onSortingChange: filterHandler?.setSorting,
    onPaginationChange: filterHandler?.setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    autoResetPageIndex: !disableResetPageIndex,
    debugTable: ENV_ISDEV,
    debugHeaders: ENV_ISDEV,
    // debugColumns: false,
    // onStateChange: log,
  });

  const exportComp = () => {
    if (!isFrontEndTable) return exportComponent;
    const title = 'export-' + Utils.setToKebabDate(new Date());
    return FrontEndExport(table.getFilteredRowModel().rows, columns, title, downloadable, route);
  };

  return (
    <div className={'cms-table-container react-table-header-left ' + theming.get().palette.mode}>
      {indicatorList && <Indicator indicatorList={indicatorList} rows={table.getFilteredRowModel().rows} />}
      <Table>
        <Header
          show={showHeader}
          exportComponent={exportComp()}
          formFilter={<CmsFormFilter {...{ table, columns, isFilterOpen, filterHandler, route, setFiltersInUrl }} />}
          table={table}
          advancedSearchButton={advancedSearchButton}
          globalFilterIcon={globalFilterIcon}
          globalFilterHandler={{
            globalFilter: filterHandler?.globalFilter,
            setGlobalFilter: filterHandler?.setGlobalFilter,
            withType: !isFrontEndTable,
          }}
          count={filterHandler?.count}
          draggableRow={draggableRow}
          colShownHandler={colShownHandler}
        />
        {!draggableRow ? (
          <Body
            table={table}
            rowStyle={rowStyle}
            reorderRow={reorderRow}
            onCellClick={tableAction.onCellClick}
            navigateTo={tableAction.navigateTo}
            invertClick={invertClick}
          />
        ) : (
          <DraggableBody
            table={table}
            reorderRow={reorderRow}
            onCellClick={tableAction.onCellClick}
            navigateTo={tableAction.navigateTo}
          />
        )}
        {showFooter && <Footer table={table} count={filterHandler?.count} />}
      </Table>
      <Debug table={table} />
    </div>
  );
};

/**
 * Bouton de recherche avancée
 * @param onClick fonction à appeler au clic
 * @param filter liste des filtres actifs
 */
const AdvancedFilterButton: FC<{ onClick: any; filter: any[] }> = ({ onClick, filter }) => {
  return (
    <CmsButton
      startIcon={<CmsIcon icon="search" typeColor="primary" />}
      className="advanced-filter-button"
      size="small"
      onClick={onClick}
    >
      {(filter.length > 0 ? `(${filter.length}) ` : '') + 'Recherche avancée'}
    </CmsButton>
  );
};

interface GlobalFilterProps {
  globalFilterHandler: {
    globalFilter?: string;
    setGlobalFilter: any;
    withType?: boolean;
  };
  isMobile?: boolean;
  globalFilterIcon?: ReactNode;
}

interface HeaderProps extends GlobalFilterProps {
  show?: 'always' | 'never' | 'auto';
  table: TansTable<any>;
  count?: number;
  draggableRow: boolean;
  exportComponent: ReactNode;
  advancedSearchButton?: ReactNode;
  colShownHandler: any;
  globalFilterIcon?: ReactNode;
  formFilter?: ReactNode;
}

/**
 * Composant d'en-tête du tableau, gère les titres des colonnes, le filtre global et la pagination
 * @param table table ReactTableV8
 * @param globalFilterHandler permet de gérer le filtre global
 * @param count nombre de ligne total du tableau (pour gestion de la pagination)
 * @param draggableRow défini si on doit ajouter un collone vide en en-tête pour le drag and drop
 * @param exportComponent permet d'afficher le lien d'export CSV et Excel
 * @param advancedSearchButton permet d'afficher le bouton de recherche avancée
 * @param colShownHandler permet de gérer l'affichage des colonnes
 * @param formFilter permet d'afficher le formulaire de filtre
 * @param globalFilterIcon icone à afficher à gauche du filtre global
 * @param show défini si l'en-tête doit être affiché en permanence / jamais / auto
 */
const Header: FC<HeaderProps> = ({
  table,
  globalFilterHandler,
  count,
  draggableRow,
  exportComponent,
  advancedSearchButton,
  colShownHandler,
  formFilter,
  globalFilterIcon,
  show = 'auto',
}) => {
  const { theming } = useContext(GlobalContext);
  const isMobile = useMemo(() => Utils.isMobileBrowser(), []);
  const isLessThan10 = table.getCoreRowModel().rows.length < 10;
  const hideTools = show === 'never' || (show === 'auto' && isLessThan10);
  return (
    <TableHead>
      {(!hideTools && [
        <TableRow>
          <TableCell colSpan={table.getAllLeafColumns().length}>{formFilter}</TableCell>
        </TableRow>,
        <TableRow>
          <TableCell colSpan={table.getAllLeafColumns().length}>
            <div className={isMobile ? 'flex-v item-center' : 'flex-h item-center end'}>
              {advancedSearchButton}
              <CmsGlobalFilter {...{ globalFilterHandler, isMobile, globalFilterIcon, count: count ?? 0 }} />
              {exportComponent}
              <ColumnVisibilityHandler table={table} colShownHandler={colShownHandler} />
              <CmsPagination table={table} count={count} />
            </div>
          </TableCell>
        </TableRow>,
      ]) ||
        (!isLessThan10 && (
          <TableRow>
            <TableCell colSpan={table.getAllLeafColumns().length}>
              <CmsPagination table={table} count={count} />
            </TableCell>
          </TableRow>
        ))}
      {!isMobile &&
        table.getHeaderGroups().map((headerGroup) => (
          <TableRow
            className="sticky-head"
            key={headerGroup.id}
            style={{ backgroundColor: theming.get().cms.main.header }}
          >
            {draggableRow && <TableCell />}
            {headerGroup.headers.map(CmsHeaderCell)}
          </TableRow>
        ))}
    </TableHead>
  );
};

/**
 * Pied de tableau, gère la pagination
 * @param table table ReactTableV8
 * @param count nombre de ligne total du tableau (pour gestion de la pagination)
 */
const Footer: FC<{ table: TansTable<any>; count?: number }> = ({ table, count }) => {
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={table.getAllLeafColumns().length}>
          <div className="flex-h item-center end">
            <CmsPagination table={table} count={count} />
          </div>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

const ColumnVisibilityHandler: FC<{ table: TansTable<any>; colShownHandler: any }> = ({ table, colShownHandler }) => {
  const options = useMemo(() => {
    return table.getAllLeafColumns().reduce((x: any, y: any) => {
      const col = { id: y.columnDef.id, label: y.columnDef.filterHeader ?? y.columnDef.header };
      return y.columnDef.hide === true ? x : [...x, col];
    }, []);
  }, [table]);
  const handleOnClick = (id: string) => {
    colShownHandler.setColShown({ ...colShownHandler.colShown, [id]: !colShownHandler.colShown[id] });
  };
  let values = [];
  for (const [key, value] of Object.entries(colShownHandler.colShown)) if (value) values.push(key);
  return (
    <InputUI.MenuWithCheckBox
      options={options}
      value={values}
      onClick={handleOnClick}
      containerStyle={{ marginLeft: '.5rem' }}
    >
      <CmsButton variant="text" color="inherit" startIcon={<CmsIcon icon="column" />}>
        Modifier les colonnes
      </CmsButton>
    </InputUI.MenuWithCheckBox>
  );
};

interface BodyProps extends TableAction {
  table: TansTable<any>;
  reorderRow: any;
  invertClick?: boolean;
  rowStyle?: (row: Row<any>) => CSSProperties | undefined;
}

/**
 * Composant de corps du tableau, gère les lignes et les cellules
 * @param table table ReactTableV8
 * @param onCellClick permet de gérer le clic sur une cellule
 * @param navigateTo permet de gérer la navigation sur une cellule / ligne
 * @param invertClick Force l'ouverture d'un nouvel onglet pour les liens
 * @param rowStyle permet de définir un style pour les lignes
 */
const Body: FC<BodyProps> = ({ table, onCellClick, navigateTo, invertClick, rowStyle }) => {
  const navigate = useNavigate();
  const { theming } = useContext(GlobalContext);
  const isMobile = useMemo(() => Utils.isMobileBrowser(), []);
  const mainTheme = theming.get().cms.main;
  const canClick = !!onCellClick || !!navigateTo;
  const handleCellClick = (event: any, cell: Cell<any, any>) => {
    if (event.button === 2) return;
    if ((cell.column.columnDef as CmsColumnDef<any>).noClick) return;
    if (onCellClick) return onCellClick(cell);
    if (!navigateTo) return;
    const url = navigateTo(cell.row.original.id, cell);
    if (url.startsWith('http')) return window.open(url, '_blank');
    if (event.button === 0 && event.shiftKey) return navigate(url);
    if (event.button === 1 || (event.ctrlKey && event.button === 0)) return window.open(url, '_blank');
    invertClick ? window.open(url, '_blank') : navigate(url);
  };
  if (isMobile) return <MobileBody table={table} handleCellClick={canClick ? handleCellClick : undefined} />;
  return (
    <TableBody className={onCellClick || navigateTo ? 'click-row' : ''}>
      {table.getRowModel().rows.map((row, i) => {
        let alternStyle = { backgroundColor: i % 2 === 1 ? mainTheme.rowOdd : mainTheme.rowEven };
        if (!!row.original?.rowColor) alternStyle = { backgroundColor: row.original?.rowColor };
        if (rowStyle) alternStyle = { ...alternStyle, ...rowStyle(row) };
        return (
          <TableRow key={row.id} style={alternStyle} className="body-row">
            {row.getVisibleCells().map((cell) => {
              return (
                <TableCell
                  key={cell.id}
                  onMouseDown={(e) => handleCellClick(e, cell)}
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

interface MobileBodyProps {
  table: TansTable<any>;
  handleCellClick: any;
}

/**
 * Composant de corps du tableau pour les mobiles, gère les lignes et les cellules
 * @param table table ReactTableV8
 * @param handleCellClick permet de gérer le clic sur une cellule
 */
const MobileBody: FC<MobileBodyProps> = ({ table, handleCellClick }) => {
  const colSpan = useMemo(() => table.getAllLeafColumns().length, [table]);
  const { theming } = useContext(GlobalContext);
  return (
    <TableBody>
      {table.getRowModel().rows.map((row) => (
        <TableRow key={'row' + row.id} className="body-row">
          <TableCell key={'cell' + row.id} colSpan={colSpan}>
            <div className="modible-cell">
              {row.getVisibleCells().map((cell) => (
                <div>
                  <div className="mobile-title" style={{ backgroundColor: theming.get().cms.main.rowEven }}>
                    {cell.column.columnDef.header as any}
                  </div>
                  <div className="mobile-value" style={{ backgroundColor: theming.get().cms.main.rowOdd }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </div>
              ))}
              {handleCellClick && (
                <CmsButton className="mobile-see" onClick={(e) => handleCellClick(e, row.getVisibleCells()?.[0])}>
                  Voir
                </CmsButton>
              )}
            </div>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

/**
 * Composant de corps du tableau, gère les lignes et les cellules pour le "drag and drop"
 * @param table table ReactTableV8
 * @param reorderRow fonction de réorganisation des lignes
 */
const DraggableBody: FC<BodyProps> = ({ table, reorderRow }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <DraggableRow key={row.id} row={row} reorderRow={reorderRow} />
        ))}
      </TableBody>
    </DndProvider>
  );
};

/**
 * Composant de cellule du tableau (en-tête)
 * @param header l'objet header de la colonne du CmsTable
 */
const CmsHeaderCell: FC<any> = (header) => {
  const { theming } = useContext(GlobalContext);
  const { id, colSpan, column, isPlaceholder, getContext, getSize } = header;
  if (!!isPlaceholder) return <TableCell key={id} colSpan={colSpan} />;
  const render = flexRender(column.columnDef.header, getContext());
  const sort: any = column.getIsSorted() ? column.getIsSorted() : undefined;
  const backgroundColor = column.columnDef.backgroundColor?.[theming.get().palette.mode === 'dark' ? 'dark' : 'light'];
  const isChapter = column.columnDef.columns;
  return (
    <TableCell key={id} colSpan={colSpan} style={{ width: getSize(), backgroundColor }}>
      <div className="cms-header-cell">
        {render}
        {!isChapter && <TableSortLabel active={!!sort} direction={sort} onClick={column.getToggleSortingHandler()} />}
        <div
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
        />
      </div>
    </TableCell>
  );
};

/**
 * Rempli les valeurs par défaut des colonnes du tableau a partir des données de chaque colonne.
 * Cela permet de ne pas avoir à remplir toutes les valeurs pour chaque colonne
 * Par exemple : un composant CmsTableFilter.Text aura par défaut un filtre CmsFilterCalculator.text si aucun autre
 * filtre n'est spécifié
 * @param columns liste des colonnes
 * @param depth profondeur de la récursion. car les colonnes peuvent être imbriquées
 */
function fillDefaultColumnDef<T>(columns: CmsColumnDef<T>[], currentUser: any, depth = 0): CmsColumnDef<T>[] {
  for (let column of columns) {
    if (column.id && !column.accessorKey) column.accessorKey = column.id;
    if (!column.footer) column.footer = (props) => props.column.id;
    if (column.Filter === CmsTableFilter.Date && !column.size) column.size = 100;
    if (column.Filter === CmsTableFilter.Bool && !column.size) column.size = 80;
    if (typeof column.defaultFilterValue === 'function')
      column.defaultFilterValue = column.defaultFilterValue(currentUser) as any;
    setBackgroundChildColor(column);
    setDefaultFilterCalculator(column);
    setDefaultSorting(column);
    setDefaultCell(column);
    if (!!column.columns && depth < 10) fillDefaultColumnDef(column.columns, currentUser, depth + 1);
  }
  return columns;
}

function getDefaultFilterList<T>(columns: CmsColumnDef<T>[], depth = 0): any[] {
  let result: any[] = [];
  for (let column of columns) {
    if (column.defaultFilterValue != null) result.push({ id: column.id, value: column.defaultFilterValue });
    if (depth < 10 && column.columns) result = [...result, ...getDefaultFilterList(column.columns, depth + 1)];
  }
  return result;
}

function setBackgroundChildColor(column: CmsColumnDef<any>): void {
  if (!column.backgroundColor || !column.columns) return;
  for (let col of column.columns.filter((x) => !x.backgroundColor)) col.backgroundColor = column.backgroundColor;
}

function setDefaultFilterCalculator(column: CmsColumnDef<any>): void {
  if (column.filterFn) return;
  if (column.Filter === CmsTableFilter.Text) column.filterFn = CmsFilterCalculator.text;
  if (column.Filter === CmsTableFilter.Search) column.filterFn = CmsFilterCalculator.search;
  if (column.Filter === CmsTableFilter.Number) column.filterFn = CmsFilterCalculator.number;
  if (column.Filter === CmsTableFilter.Select) column.filterFn = CmsFilterCalculator.select;
  if (column.Filter === CmsTableFilter.Bool) column.filterFn = CmsFilterCalculator.exact;
  if (column.Filter === CmsTableFilter.Date) column.filterFn = CmsFilterCalculator.date;
}

function setDefaultSorting(column: CmsColumnDef<any>): void {
  if (column.cmsSortingFn) return;
  if (column.Filter === CmsTableFilter.Text) column.cmsSortingFn = CmsFilterCalculator.sort.string;
  if (column.Filter === CmsTableFilter.Search) column.cmsSortingFn = CmsFilterCalculator.sort.string;
  if (column.Filter === CmsTableFilter.Number) column.cmsSortingFn = CmsFilterCalculator.sort.number;
  if (column.Filter === CmsTableFilter.Select) column.cmsSortingFn = CmsFilterCalculator.sort.string;
  if (column.Filter === CmsTableFilter.Bool) column.cmsSortingFn = CmsFilterCalculator.sort.boolean;
  if (column.Filter === CmsTableFilter.Date) column.cmsSortingFn = CmsFilterCalculator.sort.date;
}

function setDefaultCell(column: CmsColumnDef<any>): void {
  if (column.cell) return;
  if (column.Filter === CmsTableFilter.Date) column.cell = CmsTableCell.Date;
  if (column.Filter === CmsTableFilter.Bool) column.cell = CmsTableCell.Bool;
}

function parseDateByColumnFilterType(column: CmsColumnDef<any>[], data: any[]): any[] {
  const attrList = column.filter((x) => x.Filter === CmsTableFilter.Date).map((x) => x.id);
  return Utils.parseToDateInList(data, attrList);
}

/**
 * Composant de debug du CmsTable, ne s'affiche que en local et sur l'environnement de dev
 * @param table table cms-table
 */

const Debug: FC<{ table: TansTable<any> }> = ({ table }) => {
  return <></>;
  // return ENV_ISDEV ? <pre>{JSON.stringify(table.getState(), null, 2)}</pre> : <></>;
};

interface DraggableRowProps {
  row: Row<any>;
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
}

/**
 * Composant de ligne de tableau faite pour le "drag and drop"
 * @param row ligne du tableau
 * @param reorderRow fonction de réorganisation des lignes
 */
const DraggableRow: FC<DraggableRowProps> = ({ row, reorderRow }) => {
  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow: Row<any>) => reorderRow(draggedRow.index, row.index),
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    item: () => row,
    type: 'row',
  });

  return (
    <TableRow ref={previewRef} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <TableCell ref={dropRef}>
        <button ref={dragRef}>🟰</button>
      </TableCell>
      {row.getVisibleCells().map((cell) => (
        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
      ))}
    </TableRow>
  );
};
