enum ROLE {
  //#region Rôles génériques - de 0 à 9
  NONE = 'NONE',
  /// <summary> Donne l"ensemble des droits sur l"application </summary>
  //[Display(Description = "//[Display(Description",
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  ADMIN_CONFIGURATION = 'ROLE_ADMIN_CONFIGURATION',
  /// <summary> Permet de se faire passer pour un autre utilisateur (supporté uniquement par le CMSv1) </summary>
  //[Display(Description = "//[Display(Description",
  ALLOWED_TO_SWITCH = 'ROLE_ALLOWED_TO_SWITCH',
  SONATA_ADMIN = 'ROLE_SONATA_ADMIN',
  //#endregion

  //#region Rôles non liés à une entité - de 10 à 19
  /// <summary> Rôle calculé dynamiquement indiquant si l"utilisateur est manager </summary>
  USER_IS_MANAGER = 'ROLE_USER_IS_MANAGER',
  /// <summary> Permet de calculer le mot de passe joker d"un technicien </summary>
  //[Display(Description = "//[Display(Description",
  USER_JOKER_PASSWORD = 'ROLE_USER_JOKER_PASSWORD',
  HABILITATION_CELL_POSITION = 'ROLE_HABILITATION_CELL_POSITION',
  //#endregion

  //#region Extension des rôles du CMSv1 - de 20 à 39
  ADMIN_TIMEREPORTINGWEEK_VALIDATION = 'ROLE_ADMIN_TIMEREPORTINGWEEK_VALIDATION',
  ADMIN_STAFF_ABSENCE_VALIDATION = 'ROLE_ADMIN_STAFF_ABSENCE_VALIDATION',
  ADMIN_STAFF_ABSENCE_MANAGER_VALIDATION = 'ROLE_ADMIN_STAFF_ABSENCE_MANAGER_VALIDATION',
  //#endregion

  //#region Rôles liés à Sonata User Admin (préfixe Us) - de 40 à 69
  ///<summary> Classe Admin CMSv1 : Application\Sonata\UserBundle\Admin\Model\UserAdmin </summary>
  SONATA_USER_ADMIN_USER_VIEW = 'ROLE_SONATA_USER_ADMIN_USER_VIEW',
  ///<summary> Classe Admin CMSv1 : Application\Sonata\UserBundle\Admin\Model\UserAdmin </summary>
  SONATA_USER_ADMIN_USER_LIST = 'ROLE_SONATA_USER_ADMIN_USER_LIST',
  ///<summary> Classe Admin CMSv1 : Application\Sonata\UserBundle\Admin\Model\UserAdmin </summary>
  SONATA_USER_ADMIN_USER_EDIT = 'ROLE_SONATA_USER_ADMIN_USER_EDIT',
  ///<summary> Classe Admin CMSv1 : Application\Sonata\UserBundle\Admin\Model\UserAdmin </summary>
  SONATA_USER_ADMIN_USER_CREATE = 'ROLE_SONATA_USER_ADMIN_USER_CREATE',
  ///<summary> Classe Admin CMSv1 : Application\Sonata\UserBundle\Admin\Model\UserAdmin </summary>
  SONATA_USER_ADMIN_USER_DELETE = 'ROLE_SONATA_USER_ADMIN_USER_DELETE',
  ///<summary> Classe Admin CMSv1 : Application\Sonata\UserBundle\Admin\Model\UserAdmin </summary>
  SONATA_USER_ADMIN_USER_OPERATOR = 'ROLE_SONATA_USER_ADMIN_USER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Application\Sonata\UserBundle\Admin\Model\UserAdmin </summary>
  SONATA_USER_ADMIN_USER_MASTER = 'ROLE_SONATA_USER_ADMIN_USER_MASTER',
  ///<summary> Classe Admin CMSv1 : Application\Sonata\UserBundle\Admin\Model\UserAdmin </summary>
  SONATA_USER_ADMIN_USER_EXPORT = 'ROLE_SONATA_USER_ADMIN_USER_EXPORT',

  ///<summary> Classe Admin CMSv1 : Sonata\UserBundle\Admin\Entity\GroupAdmin </summary>
  SONATA_USER_ADMIN_GROUP_VIEW = 'ROLE_SONATA_USER_ADMIN_GROUP_VIEW',
  ///<summary> Classe Admin CMSv1 : Sonata\UserBundle\Admin\Entity\GroupAdmin </summary>
  SONATA_USER_ADMIN_GROUP_LIST = 'ROLE_SONATA_USER_ADMIN_GROUP_LIST',
  ///<summary> Classe Admin CMSv1 : Sonata\UserBundle\Admin\Entity\GroupAdmin </summary>
  SONATA_USER_ADMIN_GROUP_EDIT = 'ROLE_SONATA_USER_ADMIN_GROUP_EDIT',
  ///<summary> Classe Admin CMSv1 : Sonata\UserBundle\Admin\Entity\GroupAdmin </summary>
  SONATA_USER_ADMIN_GROUP_CREATE = 'ROLE_SONATA_USER_ADMIN_GROUP_CREATE',
  ///<summary> Classe Admin CMSv1 : Sonata\UserBundle\Admin\Entity\GroupAdmin </summary>
  SONATA_USER_ADMIN_GROUP_DELETE = 'ROLE_SONATA_USER_ADMIN_GROUP_DELETE',
  ///<summary> Classe Admin CMSv1 : Sonata\UserBundle\Admin\Entity\GroupAdmin </summary>
  SONATA_USER_ADMIN_GROUP_OPERATOR = 'ROLE_SONATA_USER_ADMIN_GROUP_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Sonata\UserBundle\Admin\Entity\GroupAdmin </summary>
  SONATA_USER_ADMIN_GROUP_MASTER = 'ROLE_SONATA_USER_ADMIN_GROUP_MASTER',
  ///<summary> Classe Admin CMSv1 : Sonata\UserBundle\Admin\Entity\GroupAdmin </summary>
  SONATA_USER_ADMIN_GROUP_EXPORT = 'ROLE_SONATA_USER_ADMIN_GROUP_EXPORT',

  //[Display(Description = "//[Display(Description",
  NOTIFICATION_MASTER = 'ROLE_NOTIFICATION_MASTER',
  //[Display(Description = "//[Display(Description",
  NOTIFICATION_OPERATOR = 'ROLE_NOTIFICATION_OPERATOR',
  //[Display(Description = "//[Display(Description",
  NOTIFICATION_LIST = 'ROLE_NOTIFICATION_LIST',
  //#endregion

  //#region Rôles liés à la partie Géolocalisation (préfixe Go) - de 70 à 79
  ///<summary> Classe Admin CMSv1 : Castres\GeoBundle\Admin\VehicleAdmin </summary>
  ADMIN_GEO_VEHICLE_VIEW = 'ROLE_ADMIN_GEO_VEHICLE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\GeoBundle\Admin\VehicleAdmin </summary>
  ADMIN_GEO_VEHICLE_LIST = 'ROLE_ADMIN_GEO_VEHICLE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\GeoBundle\Admin\VehicleAdmin </summary>
  ADMIN_GEO_VEHICLE_EDIT = 'ROLE_ADMIN_GEO_VEHICLE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\GeoBundle\Admin\VehicleAdmin </summary>
  ADMIN_GEO_VEHICLE_CREATE = 'ROLE_ADMIN_GEO_VEHICLE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\GeoBundle\Admin\VehicleAdmin </summary>
  ADMIN_GEO_VEHICLE_DELETE = 'ROLE_ADMIN_GEO_VEHICLE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\GeoBundle\Admin\VehicleAdmin </summary>
  ADMIN_GEO_VEHICLE_OPERATOR = 'ROLE_ADMIN_GEO_VEHICLE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\GeoBundle\Admin\VehicleAdmin </summary>
  ADMIN_GEO_VEHICLE_MASTER = 'ROLE_ADMIN_GEO_VEHICLE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\GeoBundle\Admin\VehicleAdmin </summary>
  ADMIN_GEO_VEHICLE_EXPORT = 'ROLE_ADMIN_GEO_VEHICLE_EXPORT',
  //#endregion

  //#region Rôles liés à la partie Média (préfixe Md) - de 80 à 89
  ///<summary> Classe Admin CMSv1 : Castres\MediaBundle\Admin\MediaAdmin </summary>
  ADMIN_MEDIA_VIEW = 'ROLE_ADMIN_MEDIA_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\MediaBundle\Admin\MediaAdmin </summary>
  ADMIN_MEDIA_LIST = 'ROLE_ADMIN_MEDIA_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\MediaBundle\Admin\MediaAdmin </summary>
  ADMIN_MEDIA_EDIT = 'ROLE_ADMIN_MEDIA_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\MediaBundle\Admin\MediaAdmin </summary>
  ADMIN_MEDIA_CREATE = 'ROLE_ADMIN_MEDIA_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\MediaBundle\Admin\MediaAdmin </summary>
  ADMIN_MEDIA_DELETE = 'ROLE_ADMIN_MEDIA_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\MediaBundle\Admin\MediaAdmin </summary>
  ADMIN_MEDIA_OPERATOR = 'ROLE_ADMIN_MEDIA_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\MediaBundle\Admin\MediaAdmin </summary>
  ADMIN_MEDIA_MASTER = 'ROLE_ADMIN_MEDIA_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\MediaBundle\Admin\MediaAdmin </summary>
  ADMIN_MEDIA_EXPORT = 'ROLE_ADMIN_MEDIA_EXPORT',
  //#endregion

  //#region Rôles liés à la partie Comptablilité (préfixe Bl) - de 100 à 499
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNT_VIEW = 'ROLE_ADMIN_CONFIGURATION_ACCOUNT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNT_LIST = 'ROLE_ADMIN_CONFIGURATION_ACCOUNT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNT_EDIT = 'ROLE_ADMIN_CONFIGURATION_ACCOUNT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNT_CREATE = 'ROLE_ADMIN_CONFIGURATION_ACCOUNT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNT_DELETE = 'ROLE_ADMIN_CONFIGURATION_ACCOUNT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNT_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_ACCOUNT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNT_MASTER = 'ROLE_ADMIN_CONFIGURATION_ACCOUNT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNT_EXPORT = 'ROLE_ADMIN_CONFIGURATION_ACCOUNT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountOrderAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNTORDER_VIEW = 'ROLE_ADMIN_CONFIGURATION_ACCOUNTORDER_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountOrderAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNTORDER_LIST = 'ROLE_ADMIN_CONFIGURATION_ACCOUNTORDER_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountOrderAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNTORDER_EDIT = 'ROLE_ADMIN_CONFIGURATION_ACCOUNTORDER_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountOrderAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNTORDER_CREATE = 'ROLE_ADMIN_CONFIGURATION_ACCOUNTORDER_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountOrderAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNTORDER_DELETE = 'ROLE_ADMIN_CONFIGURATION_ACCOUNTORDER_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountOrderAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNTORDER_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_ACCOUNTORDER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountOrderAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNTORDER_MASTER = 'ROLE_ADMIN_CONFIGURATION_ACCOUNTORDER_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\AccountOrderAdmin </summary>
  ADMIN_CONFIGURATION_ACCOUNTORDER_EXPORT = 'ROLE_ADMIN_CONFIGURATION_ACCOUNTORDER_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractAdmin </summary>
  ADMIN_CONTRACT_VIEW = 'ROLE_ADMIN_CONTRACT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractAdmin </summary>
  ADMIN_CONTRACT_LIST = 'ROLE_ADMIN_CONTRACT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractAdmin </summary>
  ADMIN_CONTRACT_EDIT = 'ROLE_ADMIN_CONTRACT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractAdmin </summary>
  ADMIN_CONTRACT_CREATE = 'ROLE_ADMIN_CONTRACT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractAdmin </summary>
  ADMIN_CONTRACT_DELETE = 'ROLE_ADMIN_CONTRACT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractAdmin </summary>
  ADMIN_CONTRACT_OPERATOR = 'ROLE_ADMIN_CONTRACT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractAdmin </summary>
  ADMIN_CONTRACT_MASTER = 'ROLE_ADMIN_CONTRACT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractAdmin </summary>
  ADMIN_CONTRACT_EXPORT = 'ROLE_ADMIN_CONTRACT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractFrequencyInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractFrequencyInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractFrequencyInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractFrequencyInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractFrequencyInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractFrequencyInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractFrequencyInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractFrequencyInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractLineInterventionStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractLineInterventionStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractLineInterventionStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractLineInterventionStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractLineInterventionStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractLineInterventionStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractLineInterventionStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractLineInterventionStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractStatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractTypeInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractTypeInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractTypeInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractTypeInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractTypeInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractTypeInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractTypeInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ContractTypeInvoiceAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryAdmin </summary>
  ADMIN_ORDER_DELIVERY_VIEW = 'ROLE_ADMIN_ORDER_DELIVERY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryAdmin </summary>
  ADMIN_ORDER_DELIVERY_LIST = 'ROLE_ADMIN_ORDER_DELIVERY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryAdmin </summary>
  ADMIN_ORDER_DELIVERY_EDIT = 'ROLE_ADMIN_ORDER_DELIVERY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryAdmin </summary>
  ADMIN_ORDER_DELIVERY_CREATE = 'ROLE_ADMIN_ORDER_DELIVERY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryAdmin </summary>
  ADMIN_ORDER_DELIVERY_DELETE = 'ROLE_ADMIN_ORDER_DELIVERY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryAdmin </summary>
  ADMIN_ORDER_DELIVERY_OPERATOR = 'ROLE_ADMIN_ORDER_DELIVERY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryAdmin </summary>
  ADMIN_ORDER_DELIVERY_MASTER = 'ROLE_ADMIN_ORDER_DELIVERY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryAdmin </summary>
  ADMIN_ORDER_DELIVERY_EXPORT = 'ROLE_ADMIN_ORDER_DELIVERY_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\DeliveryTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceAdmin </summary>
  ADMIN_BILLING_INVOICE_VIEW = 'ROLE_ADMIN_BILLING_INVOICE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceAdmin </summary>
  ADMIN_BILLING_INVOICE_LIST = 'ROLE_ADMIN_BILLING_INVOICE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceAdmin </summary>
  ADMIN_BILLING_INVOICE_EDIT = 'ROLE_ADMIN_BILLING_INVOICE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceAdmin </summary>
  ADMIN_BILLING_INVOICE_CREATE = 'ROLE_ADMIN_BILLING_INVOICE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceAdmin </summary>
  ADMIN_BILLING_INVOICE_DELETE = 'ROLE_ADMIN_BILLING_INVOICE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceAdmin </summary>
  ADMIN_BILLING_INVOICE_OPERATOR = 'ROLE_ADMIN_BILLING_INVOICE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceAdmin </summary>
  ADMIN_BILLING_INVOICE_MASTER = 'ROLE_ADMIN_BILLING_INVOICE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceAdmin </summary>
  ADMIN_BILLING_INVOICE_EXPORT = 'ROLE_ADMIN_BILLING_INVOICE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceSupplierAdmin </summary>
  ADMIN_ORDER_INVOICE_VIEW = 'ROLE_ADMIN_ORDER_INVOICE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceSupplierAdmin </summary>
  ADMIN_ORDER_INVOICE_LIST = 'ROLE_ADMIN_ORDER_INVOICE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceSupplierAdmin </summary>
  ADMIN_ORDER_INVOICE_EDIT = 'ROLE_ADMIN_ORDER_INVOICE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceSupplierAdmin </summary>
  ADMIN_ORDER_INVOICE_CREATE = 'ROLE_ADMIN_ORDER_INVOICE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceSupplierAdmin </summary>
  ADMIN_ORDER_INVOICE_DELETE = 'ROLE_ADMIN_ORDER_INVOICE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceSupplierAdmin </summary>
  ADMIN_ORDER_INVOICE_OPERATOR = 'ROLE_ADMIN_ORDER_INVOICE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceSupplierAdmin </summary>
  ADMIN_ORDER_INVOICE_MASTER = 'ROLE_ADMIN_ORDER_INVOICE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\InvoiceSupplierAdmin </summary>
  ADMIN_ORDER_INVOICE_EXPORT = 'ROLE_ADMIN_ORDER_INVOICE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_NATURE_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_NATURE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_NATURE_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_NATURE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_NATURE_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_NATURE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_NATURE_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_NATURE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_NATURE_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_NATURE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_NATURE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_NATURE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_NATURE_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_NATURE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_NATURE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_NATURE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\OrderAdmin </summary>
  ADMIN_ORDER_VIEW = 'ROLE_ADMIN_ORDER_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\OrderAdmin </summary>
  ADMIN_ORDER_LIST = 'ROLE_ADMIN_ORDER_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\OrderAdmin </summary>
  ADMIN_ORDER_EDIT = 'ROLE_ADMIN_ORDER_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\OrderAdmin </summary>
  ADMIN_ORDER_CREATE = 'ROLE_ADMIN_ORDER_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\OrderAdmin </summary>
  ADMIN_ORDER_DELETE = 'ROLE_ADMIN_ORDER_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\OrderAdmin </summary>
  ADMIN_ORDER_OPERATOR = 'ROLE_ADMIN_ORDER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\OrderAdmin </summary>
  ADMIN_ORDER_MASTER = 'ROLE_ADMIN_ORDER_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\OrderAdmin </summary>
  ADMIN_ORDER_EXPORT = 'ROLE_ADMIN_ORDER_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PaymentModeAdmin </summary>
  ADMIN_CONFIGURATION_PAYMENTMODE_VIEW = 'ROLE_ADMIN_CONFIGURATION_PAYMENTMODE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PaymentModeAdmin </summary>
  ADMIN_CONFIGURATION_PAYMENTMODE_LIST = 'ROLE_ADMIN_CONFIGURATION_PAYMENTMODE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PaymentModeAdmin </summary>
  ADMIN_CONFIGURATION_PAYMENTMODE_EDIT = 'ROLE_ADMIN_CONFIGURATION_PAYMENTMODE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PaymentModeAdmin </summary>
  ADMIN_CONFIGURATION_PAYMENTMODE_CREATE = 'ROLE_ADMIN_CONFIGURATION_PAYMENTMODE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PaymentModeAdmin </summary>
  ADMIN_CONFIGURATION_PAYMENTMODE_DELETE = 'ROLE_ADMIN_CONFIGURATION_PAYMENTMODE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PaymentModeAdmin </summary>
  ADMIN_CONFIGURATION_PAYMENTMODE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_PAYMENTMODE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PaymentModeAdmin </summary>
  ADMIN_CONFIGURATION_PAYMENTMODE_MASTER = 'ROLE_ADMIN_CONFIGURATION_PAYMENTMODE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PaymentModeAdmin </summary>
  ADMIN_CONFIGURATION_PAYMENTMODE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_PAYMENTMODE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleGroupTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleGroupTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleGroupTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleGroupTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleGroupTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleGroupTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleGroupTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleGroupTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\PriceScheduleTypeAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationAdmin </summary>
  ADMIN_QUOTATION_VIEW = 'ROLE_ADMIN_QUOTATION_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationAdmin </summary>
  ADMIN_QUOTATION_LIST = 'ROLE_ADMIN_QUOTATION_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationAdmin </summary>
  ADMIN_QUOTATION_EDIT = 'ROLE_ADMIN_QUOTATION_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationAdmin </summary>
  ADMIN_QUOTATION_CREATE = 'ROLE_ADMIN_QUOTATION_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationAdmin </summary>
  ADMIN_QUOTATION_DELETE = 'ROLE_ADMIN_QUOTATION_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationAdmin </summary>
  ADMIN_QUOTATION_OPERATOR = 'ROLE_ADMIN_QUOTATION_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationAdmin </summary>
  ADMIN_QUOTATION_MASTER = 'ROLE_ADMIN_QUOTATION_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationAdmin </summary>
  ADMIN_QUOTATION_EXPORT = 'ROLE_ADMIN_QUOTATION_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationOrderAdmin </summary>
  ADMIN_QUOTATION_ORDER_VIEW = 'ROLE_ADMIN_QUOTATION_ORDER_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationOrderAdmin </summary>
  ADMIN_QUOTATION_ORDER_LIST = 'ROLE_ADMIN_QUOTATION_ORDER_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationOrderAdmin </summary>
  ADMIN_QUOTATION_ORDER_EDIT = 'ROLE_ADMIN_QUOTATION_ORDER_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationOrderAdmin </summary>
  ADMIN_QUOTATION_ORDER_CREATE = 'ROLE_ADMIN_QUOTATION_ORDER_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationOrderAdmin </summary>
  ADMIN_QUOTATION_ORDER_DELETE = 'ROLE_ADMIN_QUOTATION_ORDER_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationOrderAdmin </summary>
  ADMIN_QUOTATION_ORDER_OPERATOR = 'ROLE_ADMIN_QUOTATION_ORDER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationOrderAdmin </summary>
  ADMIN_QUOTATION_ORDER_MASTER = 'ROLE_ADMIN_QUOTATION_ORDER_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationOrderAdmin </summary>
  ADMIN_QUOTATION_ORDER_EXPORT = 'ROLE_ADMIN_QUOTATION_ORDER_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationRequestAdmin </summary>
  ADMIN_QUOTATION_REQUEST_VIEW = 'ROLE_ADMIN_QUOTATION_REQUEST_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationRequestAdmin </summary>
  ADMIN_QUOTATION_REQUEST_LIST = 'ROLE_ADMIN_QUOTATION_REQUEST_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationRequestAdmin </summary>
  ADMIN_QUOTATION_REQUEST_EDIT = 'ROLE_ADMIN_QUOTATION_REQUEST_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationRequestAdmin </summary>
  ADMIN_QUOTATION_REQUEST_CREATE = 'ROLE_ADMIN_QUOTATION_REQUEST_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationRequestAdmin </summary>
  ADMIN_QUOTATION_REQUEST_DELETE = 'ROLE_ADMIN_QUOTATION_REQUEST_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationRequestAdmin </summary>
  ADMIN_QUOTATION_REQUEST_OPERATOR = 'ROLE_ADMIN_QUOTATION_REQUEST_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationRequestAdmin </summary>
  ADMIN_QUOTATION_REQUEST_MASTER = 'ROLE_ADMIN_QUOTATION_REQUEST_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\QuotationRequestAdmin </summary>
  ADMIN_QUOTATION_REQUEST_EXPORT = 'ROLE_ADMIN_QUOTATION_REQUEST_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\SituationAdmin </summary>
  ADMIN_BILLING_SITUATION_VIEW = 'ROLE_ADMIN_BILLING_SITUATION_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\SituationAdmin </summary>
  ADMIN_BILLING_SITUATION_LIST = 'ROLE_ADMIN_BILLING_SITUATION_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\SituationAdmin </summary>
  ADMIN_BILLING_SITUATION_EDIT = 'ROLE_ADMIN_BILLING_SITUATION_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\SituationAdmin </summary>
  ADMIN_BILLING_SITUATION_CREATE = 'ROLE_ADMIN_BILLING_SITUATION_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\SituationAdmin </summary>
  ADMIN_BILLING_SITUATION_DELETE = 'ROLE_ADMIN_BILLING_SITUATION_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\SituationAdmin </summary>
  ADMIN_BILLING_SITUATION_OPERATOR = 'ROLE_ADMIN_BILLING_SITUATION_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\SituationAdmin </summary>
  ADMIN_BILLING_SITUATION_MASTER = 'ROLE_ADMIN_BILLING_SITUATION_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\SituationAdmin </summary>
  ADMIN_BILLING_SITUATION_EXPORT = 'ROLE_ADMIN_BILLING_SITUATION_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUS_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUS_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUS_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUS_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUS_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUS_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUS_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUS_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUS_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUS_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUS_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUS_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUS_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUS_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUS_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUS_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusOrderAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUSORDER_VIEW = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUSORDER_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusOrderAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUSORDER_LIST = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUSORDER_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusOrderAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUSORDER_EDIT = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUSORDER_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusOrderAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUSORDER_CREATE = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUSORDER_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusOrderAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUSORDER_DELETE = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUSORDER_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusOrderAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUSORDER_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUSORDER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusOrderAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUSORDER_MASTER = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUSORDER_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\StatusOrderAdmin </summary>
  ADMIN_CONFIGURATION_BILLING_STATUSORDER_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BILLING_STATUSORDER_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ToleranceAdmin </summary>
  ADMIN_CONFIGURATION_TOLERANCE_VIEW = 'ROLE_ADMIN_CONFIGURATION_TOLERANCE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ToleranceAdmin </summary>
  ADMIN_CONFIGURATION_TOLERANCE_LIST = 'ROLE_ADMIN_CONFIGURATION_TOLERANCE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ToleranceAdmin </summary>
  ADMIN_CONFIGURATION_TOLERANCE_EDIT = 'ROLE_ADMIN_CONFIGURATION_TOLERANCE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ToleranceAdmin </summary>
  ADMIN_CONFIGURATION_TOLERANCE_CREATE = 'ROLE_ADMIN_CONFIGURATION_TOLERANCE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ToleranceAdmin </summary>
  ADMIN_CONFIGURATION_TOLERANCE_DELETE = 'ROLE_ADMIN_CONFIGURATION_TOLERANCE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ToleranceAdmin </summary>
  ADMIN_CONFIGURATION_TOLERANCE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_TOLERANCE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ToleranceAdmin </summary>
  ADMIN_CONFIGURATION_TOLERANCE_MASTER = 'ROLE_ADMIN_CONFIGURATION_TOLERANCE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\ToleranceAdmin </summary>
  ADMIN_CONFIGURATION_TOLERANCE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_TOLERANCE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\VATAdmin </summary>
  ADMIN_CONFIGURATION_VAT_VIEW = 'ROLE_ADMIN_CONFIGURATION_VAT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\VATAdmin </summary>
  ADMIN_CONFIGURATION_VAT_LIST = 'ROLE_ADMIN_CONFIGURATION_VAT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\VATAdmin </summary>
  ADMIN_CONFIGURATION_VAT_EDIT = 'ROLE_ADMIN_CONFIGURATION_VAT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\VATAdmin </summary>
  ADMIN_CONFIGURATION_VAT_CREATE = 'ROLE_ADMIN_CONFIGURATION_VAT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\VATAdmin </summary>
  ADMIN_CONFIGURATION_VAT_DELETE = 'ROLE_ADMIN_CONFIGURATION_VAT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\VATAdmin </summary>
  ADMIN_CONFIGURATION_VAT_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_VAT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\VATAdmin </summary>
  ADMIN_CONFIGURATION_VAT_MASTER = 'ROLE_ADMIN_CONFIGURATION_VAT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\BillingBundle\Admin\VATAdmin </summary>
  ADMIN_CONFIGURATION_VAT_EXPORT = 'ROLE_ADMIN_CONFIGURATION_VAT_EXPORT',
  //#endregion

  //#region Rôles liés à la partie Client (préfixe Cl) - de 500 à 599
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\AgencyAdmin </summary>
  ADMIN_CONFIGURATION_AGENCY_VIEW = 'ROLE_ADMIN_CONFIGURATION_AGENCY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\AgencyAdmin </summary>
  ADMIN_CONFIGURATION_AGENCY_LIST = 'ROLE_ADMIN_CONFIGURATION_AGENCY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\AgencyAdmin </summary>
  ADMIN_CONFIGURATION_AGENCY_EDIT = 'ROLE_ADMIN_CONFIGURATION_AGENCY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\AgencyAdmin </summary>
  ADMIN_CONFIGURATION_AGENCY_CREATE = 'ROLE_ADMIN_CONFIGURATION_AGENCY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\AgencyAdmin </summary>
  ADMIN_CONFIGURATION_AGENCY_DELETE = 'ROLE_ADMIN_CONFIGURATION_AGENCY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\AgencyAdmin </summary>
  ADMIN_CONFIGURATION_AGENCY_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_AGENCY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\AgencyAdmin </summary>
  ADMIN_CONFIGURATION_AGENCY_MASTER = 'ROLE_ADMIN_CONFIGURATION_AGENCY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\AgencyAdmin </summary>
  ADMIN_CONFIGURATION_AGENCY_EXPORT = 'ROLE_ADMIN_CONFIGURATION_AGENCY_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientAdmin </summary>
  ADMIN_CLIENT_VIEW = 'ROLE_ADMIN_CLIENT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientAdmin </summary>
  ADMIN_CLIENT_LIST = 'ROLE_ADMIN_CLIENT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientAdmin </summary>
  ADMIN_CLIENT_EDIT = 'ROLE_ADMIN_CLIENT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientAdmin </summary>
  ADMIN_CLIENT_CREATE = 'ROLE_ADMIN_CLIENT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientAdmin </summary>
  ADMIN_CLIENT_DELETE = 'ROLE_ADMIN_CLIENT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientAdmin </summary>
  ADMIN_CLIENT_OPERATOR = 'ROLE_ADMIN_CLIENT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientAdmin </summary>
  ADMIN_CLIENT_MASTER = 'ROLE_ADMIN_CLIENT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientAdmin </summary>
  ADMIN_CLIENT_EXPORT = 'ROLE_ADMIN_CLIENT_EXPORT',
  //[Display(Description = "//[Display(Description",
  ADMIN_CLIENT_EDIT_FULL = 'ROLE_ADMIN_CLIENT_EDIT_FULL',

  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientCodeAdmin </summary>
  ADMIN_CONFIGURATION_CLIENTCODE_VIEW = 'ROLE_ADMIN_CONFIGURATION_CLIENTCODE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientCodeAdmin </summary>
  ADMIN_CONFIGURATION_CLIENTCODE_LIST = 'ROLE_ADMIN_CONFIGURATION_CLIENTCODE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientCodeAdmin </summary>
  ADMIN_CONFIGURATION_CLIENTCODE_EDIT = 'ROLE_ADMIN_CONFIGURATION_CLIENTCODE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientCodeAdmin </summary>
  ADMIN_CONFIGURATION_CLIENTCODE_CREATE = 'ROLE_ADMIN_CONFIGURATION_CLIENTCODE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientCodeAdmin </summary>
  ADMIN_CONFIGURATION_CLIENTCODE_DELETE = 'ROLE_ADMIN_CONFIGURATION_CLIENTCODE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientCodeAdmin </summary>
  ADMIN_CONFIGURATION_CLIENTCODE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_CLIENTCODE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientCodeAdmin </summary>
  ADMIN_CONFIGURATION_CLIENTCODE_MASTER = 'ROLE_ADMIN_CONFIGURATION_CLIENTCODE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ClientCodeAdmin </summary>
  ADMIN_CONFIGURATION_CLIENTCODE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_CLIENTCODE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_COMPANY_VIEW = 'ROLE_ADMIN_CONFIGURATION_COMPANY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_COMPANY_LIST = 'ROLE_ADMIN_CONFIGURATION_COMPANY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_COMPANY_EDIT = 'ROLE_ADMIN_CONFIGURATION_COMPANY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_COMPANY_CREATE = 'ROLE_ADMIN_CONFIGURATION_COMPANY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_COMPANY_DELETE = 'ROLE_ADMIN_CONFIGURATION_COMPANY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_COMPANY_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_COMPANY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_COMPANY_MASTER = 'ROLE_ADMIN_CONFIGURATION_COMPANY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_COMPANY_EXPORT = 'ROLE_ADMIN_CONFIGURATION_COMPANY_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ContactAdmin </summary>
  ADMIN_CONTACT_VIEW = 'ROLE_ADMIN_CONTACT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ContactAdmin </summary>
  ADMIN_CONTACT_LIST = 'ROLE_ADMIN_CONTACT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ContactAdmin </summary>
  ADMIN_CONTACT_EDIT = 'ROLE_ADMIN_CONTACT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ContactAdmin </summary>
  ADMIN_CONTACT_CREATE = 'ROLE_ADMIN_CONTACT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ContactAdmin </summary>
  ADMIN_CONTACT_DELETE = 'ROLE_ADMIN_CONTACT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ContactAdmin </summary>
  ADMIN_CONTACT_OPERATOR = 'ROLE_ADMIN_CONTACT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ContactAdmin </summary>
  ADMIN_CONTACT_MASTER = 'ROLE_ADMIN_CONTACT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\ContactAdmin </summary>
  ADMIN_CONTACT_EXPORT = 'ROLE_ADMIN_CONTACT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\LegalEntityAdmin </summary>
  ADMIN_CONFIGURATION_LEGALENTITY_VIEW = 'ROLE_ADMIN_CONFIGURATION_LEGALENTITY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\LegalEntityAdmin </summary>
  ADMIN_CONFIGURATION_LEGALENTITY_LIST = 'ROLE_ADMIN_CONFIGURATION_LEGALENTITY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\LegalEntityAdmin </summary>
  ADMIN_CONFIGURATION_LEGALENTITY_EDIT = 'ROLE_ADMIN_CONFIGURATION_LEGALENTITY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\LegalEntityAdmin </summary>
  ADMIN_CONFIGURATION_LEGALENTITY_CREATE = 'ROLE_ADMIN_CONFIGURATION_LEGALENTITY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\LegalEntityAdmin </summary>
  ADMIN_CONFIGURATION_LEGALENTITY_DELETE = 'ROLE_ADMIN_CONFIGURATION_LEGALENTITY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\LegalEntityAdmin </summary>
  ADMIN_CONFIGURATION_LEGALENTITY_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_LEGALENTITY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\LegalEntityAdmin </summary>
  ADMIN_CONFIGURATION_LEGALENTITY_MASTER = 'ROLE_ADMIN_CONFIGURATION_LEGALENTITY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\LegalEntityAdmin </summary>
  ADMIN_CONFIGURATION_LEGALENTITY_EXPORT = 'ROLE_ADMIN_CONFIGURATION_LEGALENTITY_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\NetworkAdmin </summary>
  ADMIN_CONFIGURATION_NETWORK_VIEW = 'ROLE_ADMIN_CONFIGURATION_NETWORK_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\NetworkAdmin </summary>
  ADMIN_CONFIGURATION_NETWORK_LIST = 'ROLE_ADMIN_CONFIGURATION_NETWORK_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\NetworkAdmin </summary>
  ADMIN_CONFIGURATION_NETWORK_EDIT = 'ROLE_ADMIN_CONFIGURATION_NETWORK_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\NetworkAdmin </summary>
  ADMIN_CONFIGURATION_NETWORK_CREATE = 'ROLE_ADMIN_CONFIGURATION_NETWORK_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\NetworkAdmin </summary>
  ADMIN_CONFIGURATION_NETWORK_DELETE = 'ROLE_ADMIN_CONFIGURATION_NETWORK_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\NetworkAdmin </summary>
  ADMIN_CONFIGURATION_NETWORK_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_NETWORK_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\NetworkAdmin </summary>
  ADMIN_CONFIGURATION_NETWORK_MASTER = 'ROLE_ADMIN_CONFIGURATION_NETWORK_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\NetworkAdmin </summary>
  ADMIN_CONFIGURATION_NETWORK_EXPORT = 'ROLE_ADMIN_CONFIGURATION_NETWORK_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\StationAdmin </summary>
  ADMIN_PARK_STATION_VIEW = 'ROLE_ADMIN_PARK_STATION_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\StationAdmin </summary>
  ADMIN_PARK_STATION_LIST = 'ROLE_ADMIN_PARK_STATION_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\StationAdmin </summary>
  ADMIN_PARK_STATION_EDIT = 'ROLE_ADMIN_PARK_STATION_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\StationAdmin </summary>
  ADMIN_PARK_STATION_CREATE = 'ROLE_ADMIN_PARK_STATION_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\StationAdmin </summary>
  ADMIN_PARK_STATION_DELETE = 'ROLE_ADMIN_PARK_STATION_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\StationAdmin </summary>
  ADMIN_PARK_STATION_OPERATOR = 'ROLE_ADMIN_PARK_STATION_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\StationAdmin </summary>
  ADMIN_PARK_STATION_MASTER = 'ROLE_ADMIN_PARK_STATION_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\ClientBundle\Admin\StationAdmin </summary>
  ADMIN_PARK_STATION_EXPORT = 'ROLE_ADMIN_PARK_STATION_EXPORT',
  //#endregion

  //#region Rôles liés à la partie Équipement (préfixe Eq) - de 600 à 899
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ActionAdmin </summary>
  ADMIN_CONFIGURATION_EQ_ACTION_VIEW = 'ROLE_ADMIN_CONFIGURATION_EQ_ACTION_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ActionAdmin </summary>
  ADMIN_CONFIGURATION_EQ_ACTION_LIST = 'ROLE_ADMIN_CONFIGURATION_EQ_ACTION_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ActionAdmin </summary>
  ADMIN_CONFIGURATION_EQ_ACTION_EDIT = 'ROLE_ADMIN_CONFIGURATION_EQ_ACTION_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ActionAdmin </summary>
  ADMIN_CONFIGURATION_EQ_ACTION_CREATE = 'ROLE_ADMIN_CONFIGURATION_EQ_ACTION_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ActionAdmin </summary>
  ADMIN_CONFIGURATION_EQ_ACTION_DELETE = 'ROLE_ADMIN_CONFIGURATION_EQ_ACTION_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ActionAdmin </summary>
  ADMIN_CONFIGURATION_EQ_ACTION_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EQ_ACTION_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ActionAdmin </summary>
  ADMIN_CONFIGURATION_EQ_ACTION_MASTER = 'ROLE_ADMIN_CONFIGURATION_EQ_ACTION_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ActionAdmin </summary>
  ADMIN_CONFIGURATION_EQ_ACTION_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EQ_ACTION_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ApprovalDecisionAdmin </summary>
  ADMIN_PARK_APPROVALDECISION_VIEW = 'ROLE_ADMIN_PARK_APPROVALDECISION_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ApprovalDecisionAdmin </summary>
  ADMIN_PARK_APPROVALDECISION_LIST = 'ROLE_ADMIN_PARK_APPROVALDECISION_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ApprovalDecisionAdmin </summary>
  ADMIN_PARK_APPROVALDECISION_EDIT = 'ROLE_ADMIN_PARK_APPROVALDECISION_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ApprovalDecisionAdmin </summary>
  ADMIN_PARK_APPROVALDECISION_CREATE = 'ROLE_ADMIN_PARK_APPROVALDECISION_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ApprovalDecisionAdmin </summary>
  ADMIN_PARK_APPROVALDECISION_DELETE = 'ROLE_ADMIN_PARK_APPROVALDECISION_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ApprovalDecisionAdmin </summary>
  ADMIN_PARK_APPROVALDECISION_OPERATOR = 'ROLE_ADMIN_PARK_APPROVALDECISION_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ApprovalDecisionAdmin </summary>
  ADMIN_PARK_APPROVALDECISION_MASTER = 'ROLE_ADMIN_PARK_APPROVALDECISION_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ApprovalDecisionAdmin </summary>
  ADMIN_PARK_APPROVALDECISION_EXPORT = 'ROLE_ADMIN_PARK_APPROVALDECISION_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\BeltAdmin </summary>
  ADMIN_CONFIGURATION_BELT_VIEW = 'ROLE_ADMIN_CONFIGURATION_BELT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\BeltAdmin </summary>
  ADMIN_CONFIGURATION_BELT_LIST = 'ROLE_ADMIN_CONFIGURATION_BELT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\BeltAdmin </summary>
  ADMIN_CONFIGURATION_BELT_EDIT = 'ROLE_ADMIN_CONFIGURATION_BELT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\BeltAdmin </summary>
  ADMIN_CONFIGURATION_BELT_CREATE = 'ROLE_ADMIN_CONFIGURATION_BELT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\BeltAdmin </summary>
  ADMIN_CONFIGURATION_BELT_DELETE = 'ROLE_ADMIN_CONFIGURATION_BELT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\BeltAdmin </summary>
  ADMIN_CONFIGURATION_BELT_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BELT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\BeltAdmin </summary>
  ADMIN_CONFIGURATION_BELT_MASTER = 'ROLE_ADMIN_CONFIGURATION_BELT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\BeltAdmin </summary>
  ADMIN_CONFIGURATION_BELT_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BELT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ConnectionAdmin </summary>
  ADMIN_CONFIGURATION_CONNECTION_VIEW = 'ROLE_ADMIN_CONFIGURATION_CONNECTION_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ConnectionAdmin </summary>
  ADMIN_CONFIGURATION_CONNECTION_LIST = 'ROLE_ADMIN_CONFIGURATION_CONNECTION_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ConnectionAdmin </summary>
  ADMIN_CONFIGURATION_CONNECTION_EDIT = 'ROLE_ADMIN_CONFIGURATION_CONNECTION_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ConnectionAdmin </summary>
  ADMIN_CONFIGURATION_CONNECTION_CREATE = 'ROLE_ADMIN_CONFIGURATION_CONNECTION_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ConnectionAdmin </summary>
  ADMIN_CONFIGURATION_CONNECTION_DELETE = 'ROLE_ADMIN_CONFIGURATION_CONNECTION_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ConnectionAdmin </summary>
  ADMIN_CONFIGURATION_CONNECTION_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_CONNECTION_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ConnectionAdmin </summary>
  ADMIN_CONFIGURATION_CONNECTION_MASTER = 'ROLE_ADMIN_CONFIGURATION_CONNECTION_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ConnectionAdmin </summary>
  ADMIN_CONFIGURATION_CONNECTION_EXPORT = 'ROLE_ADMIN_CONFIGURATION_CONNECTION_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DiameterAdmin </summary>
  ADMIN_CONFIGURATION_DIAMETER_VIEW = 'ROLE_ADMIN_CONFIGURATION_DIAMETER_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DiameterAdmin </summary>
  ADMIN_CONFIGURATION_DIAMETER_LIST = 'ROLE_ADMIN_CONFIGURATION_DIAMETER_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DiameterAdmin </summary>
  ADMIN_CONFIGURATION_DIAMETER_EDIT = 'ROLE_ADMIN_CONFIGURATION_DIAMETER_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DiameterAdmin </summary>
  ADMIN_CONFIGURATION_DIAMETER_CREATE = 'ROLE_ADMIN_CONFIGURATION_DIAMETER_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DiameterAdmin </summary>
  ADMIN_CONFIGURATION_DIAMETER_DELETE = 'ROLE_ADMIN_CONFIGURATION_DIAMETER_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DiameterAdmin </summary>
  ADMIN_CONFIGURATION_DIAMETER_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_DIAMETER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DiameterAdmin </summary>
  ADMIN_CONFIGURATION_DIAMETER_MASTER = 'ROLE_ADMIN_CONFIGURATION_DIAMETER_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DiameterAdmin </summary>
  ADMIN_CONFIGURATION_DIAMETER_EXPORT = 'ROLE_ADMIN_CONFIGURATION_DIAMETER_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DispenserMotorTypeAdmin </summary>
  ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DispenserMotorTypeAdmin </summary>
  ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DispenserMotorTypeAdmin </summary>
  ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DispenserMotorTypeAdmin </summary>
  ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DispenserMotorTypeAdmin </summary>
  ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DispenserMotorTypeAdmin </summary>
  ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DispenserMotorTypeAdmin </summary>
  ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DispenserMotorTypeAdmin </summary>
  ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_EQ_DOMAIN_VIEW = 'ROLE_ADMIN_CONFIGURATION_EQ_DOMAIN_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_EQ_DOMAIN_LIST = 'ROLE_ADMIN_CONFIGURATION_EQ_DOMAIN_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_EQ_DOMAIN_EDIT = 'ROLE_ADMIN_CONFIGURATION_EQ_DOMAIN_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_EQ_DOMAIN_CREATE = 'ROLE_ADMIN_CONFIGURATION_EQ_DOMAIN_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_EQ_DOMAIN_DELETE = 'ROLE_ADMIN_CONFIGURATION_EQ_DOMAIN_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_EQ_DOMAIN_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EQ_DOMAIN_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_EQ_DOMAIN_MASTER = 'ROLE_ADMIN_CONFIGURATION_EQ_DOMAIN_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_EQ_DOMAIN_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EQ_DOMAIN_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EffectAdmin </summary>
  ADMIN_CONFIGURATION_EQ_EFFECT_VIEW = 'ROLE_ADMIN_CONFIGURATION_EQ_EFFECT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EffectAdmin </summary>
  ADMIN_CONFIGURATION_EQ_EFFECT_LIST = 'ROLE_ADMIN_CONFIGURATION_EQ_EFFECT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EffectAdmin </summary>
  ADMIN_CONFIGURATION_EQ_EFFECT_EDIT = 'ROLE_ADMIN_CONFIGURATION_EQ_EFFECT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EffectAdmin </summary>
  ADMIN_CONFIGURATION_EQ_EFFECT_CREATE = 'ROLE_ADMIN_CONFIGURATION_EQ_EFFECT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EffectAdmin </summary>
  ADMIN_CONFIGURATION_EQ_EFFECT_DELETE = 'ROLE_ADMIN_CONFIGURATION_EQ_EFFECT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EffectAdmin </summary>
  ADMIN_CONFIGURATION_EQ_EFFECT_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EQ_EFFECT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EffectAdmin </summary>
  ADMIN_CONFIGURATION_EQ_EFFECT_MASTER = 'ROLE_ADMIN_CONFIGURATION_EQ_EFFECT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EffectAdmin </summary>
  ADMIN_CONFIGURATION_EQ_EFFECT_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EQ_EFFECT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAdmin </summary>
  ADMIN_PARK_EQUIPMENT_VIEW = 'ROLE_ADMIN_PARK_EQUIPMENT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAdmin </summary>
  ADMIN_PARK_EQUIPMENT_LIST = 'ROLE_ADMIN_PARK_EQUIPMENT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAdmin </summary>
  ADMIN_PARK_EQUIPMENT_EDIT = 'ROLE_ADMIN_PARK_EQUIPMENT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAdmin </summary>
  ADMIN_PARK_EQUIPMENT_CREATE = 'ROLE_ADMIN_PARK_EQUIPMENT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAdmin </summary>
  ADMIN_PARK_EQUIPMENT_DELETE = 'ROLE_ADMIN_PARK_EQUIPMENT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAdmin </summary>
  ADMIN_PARK_EQUIPMENT_OPERATOR = 'ROLE_ADMIN_PARK_EQUIPMENT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAdmin </summary>
  ADMIN_PARK_EQUIPMENT_MASTER = 'ROLE_ADMIN_PARK_EQUIPMENT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAdmin </summary>
  ADMIN_PARK_EQUIPMENT_EXPORT = 'ROLE_ADMIN_PARK_EQUIPMENT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_VIEW = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_LIST = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_EDIT = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_CREATE = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_DELETE = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_MASTER = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeListAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_VIEW = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeListAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_LIST = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeListAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_EDIT = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeListAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_CREATE = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeListAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_DELETE = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeListAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeListAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_MASTER = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentAttributeListAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_TYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_TYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_TYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_TYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_TYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_TYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_TYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_TYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_TYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_TYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_TYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_TYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_TYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_TYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\EquipmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_EQUIPMENT_TYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EQUIPMENT_TYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\FlexLengthAdmin </summary>
  ADMIN_CONFIGURATION_FLEXLENGTH_VIEW = 'ROLE_ADMIN_CONFIGURATION_FLEXLENGTH_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\FlexLengthAdmin </summary>
  ADMIN_CONFIGURATION_FLEXLENGTH_LIST = 'ROLE_ADMIN_CONFIGURATION_FLEXLENGTH_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\FlexLengthAdmin </summary>
  ADMIN_CONFIGURATION_FLEXLENGTH_EDIT = 'ROLE_ADMIN_CONFIGURATION_FLEXLENGTH_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\FlexLengthAdmin </summary>
  ADMIN_CONFIGURATION_FLEXLENGTH_CREATE = 'ROLE_ADMIN_CONFIGURATION_FLEXLENGTH_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\FlexLengthAdmin </summary>
  ADMIN_CONFIGURATION_FLEXLENGTH_DELETE = 'ROLE_ADMIN_CONFIGURATION_FLEXLENGTH_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\FlexLengthAdmin </summary>
  ADMIN_CONFIGURATION_FLEXLENGTH_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_FLEXLENGTH_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\FlexLengthAdmin </summary>
  ADMIN_CONFIGURATION_FLEXLENGTH_MASTER = 'ROLE_ADMIN_CONFIGURATION_FLEXLENGTH_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\FlexLengthAdmin </summary>
  ADMIN_CONFIGURATION_FLEXLENGTH_EXPORT = 'ROLE_ADMIN_CONFIGURATION_FLEXLENGTH_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ManufacturerAdmin </summary>
  ADMIN_CONFIGURATION_MANUFACTURER_VIEW = 'ROLE_ADMIN_CONFIGURATION_MANUFACTURER_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ManufacturerAdmin </summary>
  ADMIN_CONFIGURATION_MANUFACTURER_LIST = 'ROLE_ADMIN_CONFIGURATION_MANUFACTURER_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ManufacturerAdmin </summary>
  ADMIN_CONFIGURATION_MANUFACTURER_EDIT = 'ROLE_ADMIN_CONFIGURATION_MANUFACTURER_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ManufacturerAdmin </summary>
  ADMIN_CONFIGURATION_MANUFACTURER_CREATE = 'ROLE_ADMIN_CONFIGURATION_MANUFACTURER_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ManufacturerAdmin </summary>
  ADMIN_CONFIGURATION_MANUFACTURER_DELETE = 'ROLE_ADMIN_CONFIGURATION_MANUFACTURER_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ManufacturerAdmin </summary>
  ADMIN_CONFIGURATION_MANUFACTURER_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_MANUFACTURER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ManufacturerAdmin </summary>
  ADMIN_CONFIGURATION_MANUFACTURER_MASTER = 'ROLE_ADMIN_CONFIGURATION_MANUFACTURER_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ManufacturerAdmin </summary>
  ADMIN_CONFIGURATION_MANUFACTURER_EXPORT = 'ROLE_ADMIN_CONFIGURATION_MANUFACTURER_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\MetrologicModelAdmin </summary>
  ADMIN_CONFIGURATION_METROLOGICMODEL_VIEW = 'ROLE_ADMIN_CONFIGURATION_METROLOGICMODEL_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\MetrologicModelAdmin </summary>
  ADMIN_CONFIGURATION_METROLOGICMODEL_LIST = 'ROLE_ADMIN_CONFIGURATION_METROLOGICMODEL_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\MetrologicModelAdmin </summary>
  ADMIN_CONFIGURATION_METROLOGICMODEL_EDIT = 'ROLE_ADMIN_CONFIGURATION_METROLOGICMODEL_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\MetrologicModelAdmin </summary>
  ADMIN_CONFIGURATION_METROLOGICMODEL_CREATE = 'ROLE_ADMIN_CONFIGURATION_METROLOGICMODEL_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\MetrologicModelAdmin </summary>
  ADMIN_CONFIGURATION_METROLOGICMODEL_DELETE = 'ROLE_ADMIN_CONFIGURATION_METROLOGICMODEL_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\MetrologicModelAdmin </summary>
  ADMIN_CONFIGURATION_METROLOGICMODEL_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_METROLOGICMODEL_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\MetrologicModelAdmin </summary>
  ADMIN_CONFIGURATION_METROLOGICMODEL_MASTER = 'ROLE_ADMIN_CONFIGURATION_METROLOGICMODEL_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\MetrologicModelAdmin </summary>
  ADMIN_CONFIGURATION_METROLOGICMODEL_EXPORT = 'ROLE_ADMIN_CONFIGURATION_METROLOGICMODEL_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ModelAdmin </summary>
  ADMIN_CONFIGURATION_MODEL_VIEW = 'ROLE_ADMIN_CONFIGURATION_MODEL_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ModelAdmin </summary>
  ADMIN_CONFIGURATION_MODEL_LIST = 'ROLE_ADMIN_CONFIGURATION_MODEL_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ModelAdmin </summary>
  ADMIN_CONFIGURATION_MODEL_EDIT = 'ROLE_ADMIN_CONFIGURATION_MODEL_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ModelAdmin </summary>
  ADMIN_CONFIGURATION_MODEL_CREATE = 'ROLE_ADMIN_CONFIGURATION_MODEL_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ModelAdmin </summary>
  ADMIN_CONFIGURATION_MODEL_DELETE = 'ROLE_ADMIN_CONFIGURATION_MODEL_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ModelAdmin </summary>
  ADMIN_CONFIGURATION_MODEL_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_MODEL_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ModelAdmin </summary>
  ADMIN_CONFIGURATION_MODEL_MASTER = 'ROLE_ADMIN_CONFIGURATION_MODEL_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ModelAdmin </summary>
  ADMIN_CONFIGURATION_MODEL_EXPORT = 'ROLE_ADMIN_CONFIGURATION_MODEL_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_EQ_NATURE_VIEW = 'ROLE_ADMIN_CONFIGURATION_EQ_NATURE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_EQ_NATURE_LIST = 'ROLE_ADMIN_CONFIGURATION_EQ_NATURE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_EQ_NATURE_EDIT = 'ROLE_ADMIN_CONFIGURATION_EQ_NATURE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_EQ_NATURE_CREATE = 'ROLE_ADMIN_CONFIGURATION_EQ_NATURE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_EQ_NATURE_DELETE = 'ROLE_ADMIN_CONFIGURATION_EQ_NATURE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_EQ_NATURE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EQ_NATURE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_EQ_NATURE_MASTER = 'ROLE_ADMIN_CONFIGURATION_EQ_NATURE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_EQ_NATURE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EQ_NATURE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\PistolAdmin </summary>
  ADMIN_CONFIGURATION_EQ_PISTOL_VIEW = 'ROLE_ADMIN_CONFIGURATION_EQ_PISTOL_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\PistolAdmin </summary>
  ADMIN_CONFIGURATION_EQ_PISTOL_LIST = 'ROLE_ADMIN_CONFIGURATION_EQ_PISTOL_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\PistolAdmin </summary>
  ADMIN_CONFIGURATION_EQ_PISTOL_EDIT = 'ROLE_ADMIN_CONFIGURATION_EQ_PISTOL_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\PistolAdmin </summary>
  ADMIN_CONFIGURATION_EQ_PISTOL_CREATE = 'ROLE_ADMIN_CONFIGURATION_EQ_PISTOL_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\PistolAdmin </summary>
  ADMIN_CONFIGURATION_EQ_PISTOL_DELETE = 'ROLE_ADMIN_CONFIGURATION_EQ_PISTOL_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\PistolAdmin </summary>
  ADMIN_CONFIGURATION_EQ_PISTOL_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EQ_PISTOL_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\PistolAdmin </summary>
  ADMIN_CONFIGURATION_EQ_PISTOL_MASTER = 'ROLE_ADMIN_CONFIGURATION_EQ_PISTOL_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\PistolAdmin </summary>
  ADMIN_CONFIGURATION_EQ_PISTOL_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EQ_PISTOL_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ProductAdmin </summary>
  ADMIN_CONFIGURATION_PRODUCT_VIEW = 'ROLE_ADMIN_CONFIGURATION_PRODUCT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ProductAdmin </summary>
  ADMIN_CONFIGURATION_PRODUCT_LIST = 'ROLE_ADMIN_CONFIGURATION_PRODUCT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ProductAdmin </summary>
  ADMIN_CONFIGURATION_PRODUCT_EDIT = 'ROLE_ADMIN_CONFIGURATION_PRODUCT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ProductAdmin </summary>
  ADMIN_CONFIGURATION_PRODUCT_CREATE = 'ROLE_ADMIN_CONFIGURATION_PRODUCT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ProductAdmin </summary>
  ADMIN_CONFIGURATION_PRODUCT_DELETE = 'ROLE_ADMIN_CONFIGURATION_PRODUCT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ProductAdmin </summary>
  ADMIN_CONFIGURATION_PRODUCT_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_PRODUCT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ProductAdmin </summary>
  ADMIN_CONFIGURATION_PRODUCT_MASTER = 'ROLE_ADMIN_CONFIGURATION_PRODUCT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\ProductAdmin </summary>
  ADMIN_CONFIGURATION_PRODUCT_EXPORT = 'ROLE_ADMIN_CONFIGURATION_PRODUCT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SolenoidTypeAdmin </summary>
  ADMIN_CONFIGURATION_SOLENOID_TYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_SOLENOID_TYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SolenoidTypeAdmin </summary>
  ADMIN_CONFIGURATION_SOLENOID_TYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_SOLENOID_TYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SolenoidTypeAdmin </summary>
  ADMIN_CONFIGURATION_SOLENOID_TYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_SOLENOID_TYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SolenoidTypeAdmin </summary>
  ADMIN_CONFIGURATION_SOLENOID_TYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_SOLENOID_TYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SolenoidTypeAdmin </summary>
  ADMIN_CONFIGURATION_SOLENOID_TYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_SOLENOID_TYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SolenoidTypeAdmin </summary>
  ADMIN_CONFIGURATION_SOLENOID_TYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_SOLENOID_TYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SolenoidTypeAdmin </summary>
  ADMIN_CONFIGURATION_SOLENOID_TYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_SOLENOID_TYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SolenoidTypeAdmin </summary>
  ADMIN_CONFIGURATION_SOLENOID_TYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_SOLENOID_TYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerAdmin </summary>
  ADMIN_PARK_STICKER_VIEW = 'ROLE_ADMIN_PARK_STICKER_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerAdmin </summary>
  ADMIN_PARK_STICKER_LIST = 'ROLE_ADMIN_PARK_STICKER_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerAdmin </summary>
  ADMIN_PARK_STICKER_EDIT = 'ROLE_ADMIN_PARK_STICKER_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerAdmin </summary>
  ADMIN_PARK_STICKER_CREATE = 'ROLE_ADMIN_PARK_STICKER_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerAdmin </summary>
  ADMIN_PARK_STICKER_DELETE = 'ROLE_ADMIN_PARK_STICKER_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerAdmin </summary>
  ADMIN_PARK_STICKER_OPERATOR = 'ROLE_ADMIN_PARK_STICKER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerAdmin </summary>
  ADMIN_PARK_STICKER_MASTER = 'ROLE_ADMIN_PARK_STICKER_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerAdmin </summary>
  ADMIN_PARK_STICKER_EXPORT = 'ROLE_ADMIN_PARK_STICKER_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerPackAdmin </summary>
  ADMIN_PARK_STICKERPACK_VIEW = 'ROLE_ADMIN_PARK_STICKERPACK_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerPackAdmin </summary>
  ADMIN_PARK_STICKERPACK_LIST = 'ROLE_ADMIN_PARK_STICKERPACK_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerPackAdmin </summary>
  ADMIN_PARK_STICKERPACK_EDIT = 'ROLE_ADMIN_PARK_STICKERPACK_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerPackAdmin </summary>
  ADMIN_PARK_STICKERPACK_CREATE = 'ROLE_ADMIN_PARK_STICKERPACK_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerPackAdmin </summary>
  ADMIN_PARK_STICKERPACK_DELETE = 'ROLE_ADMIN_PARK_STICKERPACK_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerPackAdmin </summary>
  ADMIN_PARK_STICKERPACK_OPERATOR = 'ROLE_ADMIN_PARK_STICKERPACK_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerPackAdmin </summary>
  ADMIN_PARK_STICKERPACK_MASTER = 'ROLE_ADMIN_PARK_STICKERPACK_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerPackAdmin </summary>
  ADMIN_PARK_STICKERPACK_EXPORT = 'ROLE_ADMIN_PARK_STICKERPACK_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerTypeAdmin </summary>
  ADMIN_CONFIGURATION_STICKERTYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_STICKERTYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerTypeAdmin </summary>
  ADMIN_CONFIGURATION_STICKERTYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_STICKERTYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerTypeAdmin </summary>
  ADMIN_CONFIGURATION_STICKERTYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_STICKERTYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerTypeAdmin </summary>
  ADMIN_CONFIGURATION_STICKERTYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_STICKERTYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerTypeAdmin </summary>
  ADMIN_CONFIGURATION_STICKERTYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_STICKERTYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerTypeAdmin </summary>
  ADMIN_CONFIGURATION_STICKERTYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STICKERTYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerTypeAdmin </summary>
  ADMIN_CONFIGURATION_STICKERTYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_STICKERTYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\StickerTypeAdmin </summary>
  ADMIN_CONFIGURATION_STICKERTYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STICKERTYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_SUPPLIER_VIEW = 'ROLE_ADMIN_CONFIGURATION_SUPPLIER_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_SUPPLIER_LIST = 'ROLE_ADMIN_CONFIGURATION_SUPPLIER_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_SUPPLIER_EDIT = 'ROLE_ADMIN_CONFIGURATION_SUPPLIER_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_SUPPLIER_CREATE = 'ROLE_ADMIN_CONFIGURATION_SUPPLIER_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_SUPPLIER_DELETE = 'ROLE_ADMIN_CONFIGURATION_SUPPLIER_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_SUPPLIER_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_SUPPLIER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_SUPPLIER_MASTER = 'ROLE_ADMIN_CONFIGURATION_SUPPLIER_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\EquipmentBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_SUPPLIER_EXPORT = 'ROLE_ADMIN_CONFIGURATION_SUPPLIER_EXPORT',
  //#endregion

  //#region Rôles liés à la partie RH (préfixe Sf) - de 900 à 1099
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceAdmin </summary>
  ADMIN_STAFF_ABSENCE_VIEW = 'ROLE_ADMIN_STAFF_ABSENCE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceAdmin </summary>
  ADMIN_STAFF_ABSENCE_LIST = 'ROLE_ADMIN_STAFF_ABSENCE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceAdmin </summary>
  ADMIN_STAFF_ABSENCE_EDIT = 'ROLE_ADMIN_STAFF_ABSENCE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceAdmin </summary>
  ADMIN_STAFF_ABSENCE_CREATE = 'ROLE_ADMIN_STAFF_ABSENCE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceAdmin </summary>
  ADMIN_STAFF_ABSENCE_DELETE = 'ROLE_ADMIN_STAFF_ABSENCE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceAdmin </summary>
  ADMIN_STAFF_ABSENCE_OPERATOR = 'ROLE_ADMIN_STAFF_ABSENCE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceAdmin </summary>
  ADMIN_STAFF_ABSENCE_MASTER = 'ROLE_ADMIN_STAFF_ABSENCE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceAdmin </summary>
  ADMIN_STAFF_ABSENCE_EXPORT = 'ROLE_ADMIN_STAFF_ABSENCE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceTypeAdmin </summary>
  ADMIN_CONFIGURATION_ABSENCETYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_ABSENCETYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceTypeAdmin </summary>
  ADMIN_CONFIGURATION_ABSENCETYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_ABSENCETYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceTypeAdmin </summary>
  ADMIN_CONFIGURATION_ABSENCETYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_ABSENCETYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceTypeAdmin </summary>
  ADMIN_CONFIGURATION_ABSENCETYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_ABSENCETYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceTypeAdmin </summary>
  ADMIN_CONFIGURATION_ABSENCETYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_ABSENCETYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceTypeAdmin </summary>
  ADMIN_CONFIGURATION_ABSENCETYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_ABSENCETYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceTypeAdmin </summary>
  ADMIN_CONFIGURATION_ABSENCETYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_ABSENCETYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AbsenceTypeAdmin </summary>
  ADMIN_CONFIGURATION_ABSENCETYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_ABSENCETYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentAdmin </summary>
  ADMIN_STAFF_ATTACHMENT_VIEW = 'ROLE_ADMIN_STAFF_ATTACHMENT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentAdmin </summary>
  ADMIN_STAFF_ATTACHMENT_LIST = 'ROLE_ADMIN_STAFF_ATTACHMENT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentAdmin </summary>
  ADMIN_STAFF_ATTACHMENT_EDIT = 'ROLE_ADMIN_STAFF_ATTACHMENT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentAdmin </summary>
  ADMIN_STAFF_ATTACHMENT_CREATE = 'ROLE_ADMIN_STAFF_ATTACHMENT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentAdmin </summary>
  ADMIN_STAFF_ATTACHMENT_DELETE = 'ROLE_ADMIN_STAFF_ATTACHMENT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentAdmin </summary>
  ADMIN_STAFF_ATTACHMENT_OPERATOR = 'ROLE_ADMIN_STAFF_ATTACHMENT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentAdmin </summary>
  ADMIN_STAFF_ATTACHMENT_MASTER = 'ROLE_ADMIN_STAFF_ATTACHMENT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentAdmin </summary>
  ADMIN_STAFF_ATTACHMENT_EXPORT = 'ROLE_ADMIN_STAFF_ATTACHMENT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_ATTACHMENTTYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_ATTACHMENTTYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_ATTACHMENTTYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_ATTACHMENTTYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_ATTACHMENTTYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_ATTACHMENTTYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_ATTACHMENTTYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_ATTACHMENTTYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_ATTACHMENTTYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_ATTACHMENTTYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_ATTACHMENTTYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_ATTACHMENTTYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_ATTACHMENTTYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_ATTACHMENTTYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\AttachmentTypeAdmin </summary>
  ADMIN_CONFIGURATION_ATTACHMENTTYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_ATTACHMENTTYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseReceiptAdmin </summary>
  ADMIN_STAFF_EXPENSERECEIPT_VIEW = 'ROLE_ADMIN_STAFF_EXPENSERECEIPT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseReceiptAdmin </summary>
  ADMIN_STAFF_EXPENSERECEIPT_LIST = 'ROLE_ADMIN_STAFF_EXPENSERECEIPT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseReceiptAdmin </summary>
  ADMIN_STAFF_EXPENSERECEIPT_EDIT = 'ROLE_ADMIN_STAFF_EXPENSERECEIPT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseReceiptAdmin </summary>
  ADMIN_STAFF_EXPENSERECEIPT_CREATE = 'ROLE_ADMIN_STAFF_EXPENSERECEIPT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseReceiptAdmin </summary>
  ADMIN_STAFF_EXPENSERECEIPT_DELETE = 'ROLE_ADMIN_STAFF_EXPENSERECEIPT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseReceiptAdmin </summary>
  ADMIN_STAFF_EXPENSERECEIPT_OPERATOR = 'ROLE_ADMIN_STAFF_EXPENSERECEIPT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseReceiptAdmin </summary>
  ADMIN_STAFF_EXPENSERECEIPT_MASTER = 'ROLE_ADMIN_STAFF_EXPENSERECEIPT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseReceiptAdmin </summary>
  ADMIN_STAFF_EXPENSERECEIPT_EXPORT = 'ROLE_ADMIN_STAFF_EXPENSERECEIPT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_EXPENSETYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_EXPENSETYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_EXPENSETYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_EXPENSETYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_EXPENSETYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_EXPENSETYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_EXPENSETYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_EXPENSETYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_EXPENSETYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_EXPENSETYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_EXPENSETYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EXPENSETYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_EXPENSETYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_EXPENSETYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ExpenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_EXPENSETYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EXPENSETYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\LicenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_LICENSETYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_LICENSETYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\LicenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_LICENSETYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_LICENSETYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\LicenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_LICENSETYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_LICENSETYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\LicenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_LICENSETYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_LICENSETYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\LicenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_LICENSETYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_LICENSETYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\LicenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_LICENSETYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_LICENSETYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\LicenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_LICENSETYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_LICENSETYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\LicenseTypeAdmin </summary>
  ADMIN_CONFIGURATION_LICENSETYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_LICENSETYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitAdmin </summary>
  ADMIN_STAFF_MEDICALVISIT_VIEW = 'ROLE_ADMIN_STAFF_MEDICALVISIT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitAdmin </summary>
  ADMIN_STAFF_MEDICALVISIT_LIST = 'ROLE_ADMIN_STAFF_MEDICALVISIT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitAdmin </summary>
  ADMIN_STAFF_MEDICALVISIT_EDIT = 'ROLE_ADMIN_STAFF_MEDICALVISIT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitAdmin </summary>
  ADMIN_STAFF_MEDICALVISIT_CREATE = 'ROLE_ADMIN_STAFF_MEDICALVISIT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitAdmin </summary>
  ADMIN_STAFF_MEDICALVISIT_DELETE = 'ROLE_ADMIN_STAFF_MEDICALVISIT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitAdmin </summary>
  ADMIN_STAFF_MEDICALVISIT_OPERATOR = 'ROLE_ADMIN_STAFF_MEDICALVISIT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitAdmin </summary>
  ADMIN_STAFF_MEDICALVISIT_MASTER = 'ROLE_ADMIN_STAFF_MEDICALVISIT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitAdmin </summary>
  ADMIN_STAFF_MEDICALVISIT_EXPORT = 'ROLE_ADMIN_STAFF_MEDICALVISIT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitTypeAdmin </summary>
  ADMIN_CONFIGURATION_MEDICALVISITTYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_MEDICALVISITTYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitTypeAdmin </summary>
  ADMIN_CONFIGURATION_MEDICALVISITTYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_MEDICALVISITTYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitTypeAdmin </summary>
  ADMIN_CONFIGURATION_MEDICALVISITTYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_MEDICALVISITTYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitTypeAdmin </summary>
  ADMIN_CONFIGURATION_MEDICALVISITTYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_MEDICALVISITTYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitTypeAdmin </summary>
  ADMIN_CONFIGURATION_MEDICALVISITTYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_MEDICALVISITTYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitTypeAdmin </summary>
  ADMIN_CONFIGURATION_MEDICALVISITTYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_MEDICALVISITTYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitTypeAdmin </summary>
  ADMIN_CONFIGURATION_MEDICALVISITTYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_MEDICALVISITTYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\MedicalVisitTypeAdmin </summary>
  ADMIN_CONFIGURATION_MEDICALVISITTYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_MEDICALVISITTYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ServiceAdmin </summary>
  ADMIN_STAFF_SERVICE_VIEW = 'ROLE_ADMIN_STAFF_SERVICE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ServiceAdmin </summary>
  ADMIN_STAFF_SERVICE_LIST = 'ROLE_ADMIN_STAFF_SERVICE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ServiceAdmin </summary>
  ADMIN_STAFF_SERVICE_EDIT = 'ROLE_ADMIN_STAFF_SERVICE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ServiceAdmin </summary>
  ADMIN_STAFF_SERVICE_CREATE = 'ROLE_ADMIN_STAFF_SERVICE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ServiceAdmin </summary>
  ADMIN_STAFF_SERVICE_DELETE = 'ROLE_ADMIN_STAFF_SERVICE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ServiceAdmin </summary>
  ADMIN_STAFF_SERVICE_OPERATOR = 'ROLE_ADMIN_STAFF_SERVICE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ServiceAdmin </summary>
  ADMIN_STAFF_SERVICE_MASTER = 'ROLE_ADMIN_STAFF_SERVICE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\ServiceAdmin </summary>
  ADMIN_STAFF_SERVICE_EXPORT = 'ROLE_ADMIN_STAFF_SERVICE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TeamAdmin </summary>
  ADMIN_STAFF_TEAM_VIEW = 'ROLE_ADMIN_STAFF_TEAM_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TeamAdmin </summary>
  ADMIN_STAFF_TEAM_LIST = 'ROLE_ADMIN_STAFF_TEAM_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TeamAdmin </summary>
  ADMIN_STAFF_TEAM_EDIT = 'ROLE_ADMIN_STAFF_TEAM_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TeamAdmin </summary>
  ADMIN_STAFF_TEAM_CREATE = 'ROLE_ADMIN_STAFF_TEAM_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TeamAdmin </summary>
  ADMIN_STAFF_TEAM_DELETE = 'ROLE_ADMIN_STAFF_TEAM_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TeamAdmin </summary>
  ADMIN_STAFF_TEAM_OPERATOR = 'ROLE_ADMIN_STAFF_TEAM_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TeamAdmin </summary>
  ADMIN_STAFF_TEAM_MASTER = 'ROLE_ADMIN_STAFF_TEAM_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TeamAdmin </summary>
  ADMIN_STAFF_TEAM_EXPORT = 'ROLE_ADMIN_STAFF_TEAM_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingAdmin </summary>
  ADMIN_STAFF_TRAINING_VIEW = 'ROLE_ADMIN_STAFF_TRAINING_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingAdmin </summary>
  ADMIN_STAFF_TRAINING_LIST = 'ROLE_ADMIN_STAFF_TRAINING_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingAdmin </summary>
  ADMIN_STAFF_TRAINING_EDIT = 'ROLE_ADMIN_STAFF_TRAINING_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingAdmin </summary>
  ADMIN_STAFF_TRAINING_CREATE = 'ROLE_ADMIN_STAFF_TRAINING_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingAdmin </summary>
  ADMIN_STAFF_TRAINING_DELETE = 'ROLE_ADMIN_STAFF_TRAINING_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingAdmin </summary>
  ADMIN_STAFF_TRAINING_OPERATOR = 'ROLE_ADMIN_STAFF_TRAINING_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingAdmin </summary>
  ADMIN_STAFF_TRAINING_MASTER = 'ROLE_ADMIN_STAFF_TRAINING_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingAdmin </summary>
  ADMIN_STAFF_TRAINING_EXPORT = 'ROLE_ADMIN_STAFF_TRAINING_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingTypeAdmin </summary>
  ADMIN_CONFIGURATION_TRAININGTYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_TRAININGTYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingTypeAdmin </summary>
  ADMIN_CONFIGURATION_TRAININGTYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_TRAININGTYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingTypeAdmin </summary>
  ADMIN_CONFIGURATION_TRAININGTYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_TRAININGTYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingTypeAdmin </summary>
  ADMIN_CONFIGURATION_TRAININGTYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_TRAININGTYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingTypeAdmin </summary>
  ADMIN_CONFIGURATION_TRAININGTYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_TRAININGTYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingTypeAdmin </summary>
  ADMIN_CONFIGURATION_TRAININGTYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_TRAININGTYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingTypeAdmin </summary>
  ADMIN_CONFIGURATION_TRAININGTYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_TRAININGTYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\TrainingTypeAdmin </summary>
  ADMIN_CONFIGURATION_TRAININGTYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_TRAININGTYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WarningAdmin </summary>
  ADMIN_STAFF_WARNING_VIEW = 'ROLE_ADMIN_STAFF_WARNING_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WarningAdmin </summary>
  ADMIN_STAFF_WARNING_LIST = 'ROLE_ADMIN_STAFF_WARNING_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WarningAdmin </summary>
  ADMIN_STAFF_WARNING_EDIT = 'ROLE_ADMIN_STAFF_WARNING_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WarningAdmin </summary>
  ADMIN_STAFF_WARNING_CREATE = 'ROLE_ADMIN_STAFF_WARNING_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WarningAdmin </summary>
  ADMIN_STAFF_WARNING_DELETE = 'ROLE_ADMIN_STAFF_WARNING_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WarningAdmin </summary>
  ADMIN_STAFF_WARNING_OPERATOR = 'ROLE_ADMIN_STAFF_WARNING_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WarningAdmin </summary>
  ADMIN_STAFF_WARNING_MASTER = 'ROLE_ADMIN_STAFF_WARNING_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WarningAdmin </summary>
  ADMIN_STAFF_WARNING_EXPORT = 'ROLE_ADMIN_STAFF_WARNING_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WorkTimeAdmin </summary>
  ADMIN_CONFIGURATION_WORKTIME_VIEW = 'ROLE_ADMIN_CONFIGURATION_WORKTIME_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WorkTimeAdmin </summary>
  ADMIN_CONFIGURATION_WORKTIME_LIST = 'ROLE_ADMIN_CONFIGURATION_WORKTIME_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WorkTimeAdmin </summary>
  ADMIN_CONFIGURATION_WORKTIME_EDIT = 'ROLE_ADMIN_CONFIGURATION_WORKTIME_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WorkTimeAdmin </summary>
  ADMIN_CONFIGURATION_WORKTIME_CREATE = 'ROLE_ADMIN_CONFIGURATION_WORKTIME_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WorkTimeAdmin </summary>
  ADMIN_CONFIGURATION_WORKTIME_DELETE = 'ROLE_ADMIN_CONFIGURATION_WORKTIME_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WorkTimeAdmin </summary>
  ADMIN_CONFIGURATION_WORKTIME_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_WORKTIME_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WorkTimeAdmin </summary>
  ADMIN_CONFIGURATION_WORKTIME_MASTER = 'ROLE_ADMIN_CONFIGURATION_WORKTIME_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StaffBundle\Admin\WorkTimeAdmin </summary>
  ADMIN_CONFIGURATION_WORKTIME_EXPORT = 'ROLE_ADMIN_CONFIGURATION_WORKTIME_EXPORT',
  //#endregion

  //#region Rôles liés à la partie Stock (préfixe Sk) - de 1100 à 1299
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleAdmin </summary>
  ADMIN_STOCK_ARTICLE_VIEW = 'ROLE_ADMIN_STOCK_ARTICLE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleAdmin </summary>
  ADMIN_STOCK_ARTICLE_LIST = 'ROLE_ADMIN_STOCK_ARTICLE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleAdmin </summary>
  ADMIN_STOCK_ARTICLE_EDIT = 'ROLE_ADMIN_STOCK_ARTICLE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleAdmin </summary>
  ADMIN_STOCK_ARTICLE_CREATE = 'ROLE_ADMIN_STOCK_ARTICLE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleAdmin </summary>
  ADMIN_STOCK_ARTICLE_DELETE = 'ROLE_ADMIN_STOCK_ARTICLE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleAdmin </summary>
  ADMIN_STOCK_ARTICLE_OPERATOR = 'ROLE_ADMIN_STOCK_ARTICLE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleAdmin </summary>
  ADMIN_STOCK_ARTICLE_MASTER = 'ROLE_ADMIN_STOCK_ARTICLE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleAdmin </summary>
  ADMIN_STOCK_ARTICLE_EXPORT = 'ROLE_ADMIN_STOCK_ARTICLE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleTypeAdmin </summary>
  ADMIN_STOCK_ARTICLETYPE_VIEW = 'ROLE_ADMIN_STOCK_ARTICLETYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleTypeAdmin </summary>
  ADMIN_STOCK_ARTICLETYPE_LIST = 'ROLE_ADMIN_STOCK_ARTICLETYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleTypeAdmin </summary>
  ADMIN_STOCK_ARTICLETYPE_EDIT = 'ROLE_ADMIN_STOCK_ARTICLETYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleTypeAdmin </summary>
  ADMIN_STOCK_ARTICLETYPE_CREATE = 'ROLE_ADMIN_STOCK_ARTICLETYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleTypeAdmin </summary>
  ADMIN_STOCK_ARTICLETYPE_DELETE = 'ROLE_ADMIN_STOCK_ARTICLETYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleTypeAdmin </summary>
  ADMIN_STOCK_ARTICLETYPE_OPERATOR = 'ROLE_ADMIN_STOCK_ARTICLETYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleTypeAdmin </summary>
  ADMIN_STOCK_ARTICLETYPE_MASTER = 'ROLE_ADMIN_STOCK_ARTICLETYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ArticleTypeAdmin </summary>
  ADMIN_STOCK_ARTICLETYPE_EXPORT = 'ROLE_ADMIN_STOCK_ARTICLETYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\BrandAdmin </summary>
  ADMIN_CONFIGURATION_BRAND_VIEW = 'ROLE_ADMIN_CONFIGURATION_BRAND_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\BrandAdmin </summary>
  ADMIN_CONFIGURATION_BRAND_LIST = 'ROLE_ADMIN_CONFIGURATION_BRAND_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\BrandAdmin </summary>
  ADMIN_CONFIGURATION_BRAND_EDIT = 'ROLE_ADMIN_CONFIGURATION_BRAND_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\BrandAdmin </summary>
  ADMIN_CONFIGURATION_BRAND_CREATE = 'ROLE_ADMIN_CONFIGURATION_BRAND_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\BrandAdmin </summary>
  ADMIN_CONFIGURATION_BRAND_DELETE = 'ROLE_ADMIN_CONFIGURATION_BRAND_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\BrandAdmin </summary>
  ADMIN_CONFIGURATION_BRAND_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_BRAND_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\BrandAdmin </summary>
  ADMIN_CONFIGURATION_BRAND_MASTER = 'ROLE_ADMIN_CONFIGURATION_BRAND_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\BrandAdmin </summary>
  ADMIN_CONFIGURATION_BRAND_EXPORT = 'ROLE_ADMIN_CONFIGURATION_BRAND_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_COMPANY_VIEW = 'ROLE_ADMIN_CONFIGURATION_STOCK_COMPANY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_COMPANY_LIST = 'ROLE_ADMIN_CONFIGURATION_STOCK_COMPANY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_COMPANY_EDIT = 'ROLE_ADMIN_CONFIGURATION_STOCK_COMPANY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_COMPANY_CREATE = 'ROLE_ADMIN_CONFIGURATION_STOCK_COMPANY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_COMPANY_DELETE = 'ROLE_ADMIN_CONFIGURATION_STOCK_COMPANY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_COMPANY_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STOCK_COMPANY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_COMPANY_MASTER = 'ROLE_ADMIN_CONFIGURATION_STOCK_COMPANY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\CompanyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_COMPANY_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STOCK_COMPANY_EXPORT',
  //[Display(Description = "//[Display(Description",
  ADMIN_CONFIGURATION_STOCK_COMPANY_EDIT_FULL = 'ROLE_ADMIN_CONFIGURATION_STOCK_COMPANY_EDIT_FULL',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_FAMILY_VIEW = 'ROLE_ADMIN_CONFIGURATION_STOCK_FAMILY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_FAMILY_LIST = 'ROLE_ADMIN_CONFIGURATION_STOCK_FAMILY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_FAMILY_EDIT = 'ROLE_ADMIN_CONFIGURATION_STOCK_FAMILY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_FAMILY_CREATE = 'ROLE_ADMIN_CONFIGURATION_STOCK_FAMILY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_FAMILY_DELETE = 'ROLE_ADMIN_CONFIGURATION_STOCK_FAMILY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_FAMILY_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STOCK_FAMILY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_FAMILY_MASTER = 'ROLE_ADMIN_CONFIGURATION_STOCK_FAMILY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_FAMILY_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STOCK_FAMILY_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\InventoryAdmin </summary>
  ADMIN_STOCK_INVENTORY_VIEW = 'ROLE_ADMIN_STOCK_INVENTORY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\InventoryAdmin </summary>
  ADMIN_STOCK_INVENTORY_LIST = 'ROLE_ADMIN_STOCK_INVENTORY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\InventoryAdmin </summary>
  ADMIN_STOCK_INVENTORY_EDIT = 'ROLE_ADMIN_STOCK_INVENTORY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\InventoryAdmin </summary>
  ADMIN_STOCK_INVENTORY_CREATE = 'ROLE_ADMIN_STOCK_INVENTORY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\InventoryAdmin </summary>
  ADMIN_STOCK_INVENTORY_DELETE = 'ROLE_ADMIN_STOCK_INVENTORY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\InventoryAdmin </summary>
  ADMIN_STOCK_INVENTORY_OPERATOR = 'ROLE_ADMIN_STOCK_INVENTORY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\InventoryAdmin </summary>
  ADMIN_STOCK_INVENTORY_MASTER = 'ROLE_ADMIN_STOCK_INVENTORY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\InventoryAdmin </summary>
  ADMIN_STOCK_INVENTORY_EXPORT = 'ROLE_ADMIN_STOCK_INVENTORY_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementAdmin </summary>
  ADMIN_STOCK_MOVEMENT_VIEW = 'ROLE_ADMIN_STOCK_MOVEMENT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementAdmin </summary>
  ADMIN_STOCK_MOVEMENT_LIST = 'ROLE_ADMIN_STOCK_MOVEMENT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementAdmin </summary>
  ADMIN_STOCK_MOVEMENT_EDIT = 'ROLE_ADMIN_STOCK_MOVEMENT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementAdmin </summary>
  ADMIN_STOCK_MOVEMENT_CREATE = 'ROLE_ADMIN_STOCK_MOVEMENT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementAdmin </summary>
  ADMIN_STOCK_MOVEMENT_DELETE = 'ROLE_ADMIN_STOCK_MOVEMENT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementAdmin </summary>
  ADMIN_STOCK_MOVEMENT_OPERATOR = 'ROLE_ADMIN_STOCK_MOVEMENT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementAdmin </summary>
  ADMIN_STOCK_MOVEMENT_MASTER = 'ROLE_ADMIN_STOCK_MOVEMENT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementAdmin </summary>
  ADMIN_STOCK_MOVEMENT_EXPORT = 'ROLE_ADMIN_STOCK_MOVEMENT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementGroupAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_VIEW = 'ROLE_ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementGroupAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_LIST = 'ROLE_ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementGroupAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_EDIT = 'ROLE_ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementGroupAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_CREATE = 'ROLE_ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementGroupAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_DELETE = 'ROLE_ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementGroupAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementGroupAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_MASTER = 'ROLE_ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\MovementGroupAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_STATUS_VIEW = 'ROLE_ADMIN_CONFIGURATION_STATUS_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_STATUS_LIST = 'ROLE_ADMIN_CONFIGURATION_STATUS_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_STATUS_EDIT = 'ROLE_ADMIN_CONFIGURATION_STATUS_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_STATUS_CREATE = 'ROLE_ADMIN_CONFIGURATION_STATUS_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_STATUS_DELETE = 'ROLE_ADMIN_CONFIGURATION_STATUS_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_STATUS_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STATUS_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_STATUS_MASTER = 'ROLE_ADMIN_CONFIGURATION_STATUS_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusAdmin </summary>
  ADMIN_CONFIGURATION_STATUS_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STATUS_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusMovementAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_VIEW = 'ROLE_ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusMovementAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_LIST = 'ROLE_ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusMovementAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_EDIT = 'ROLE_ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusMovementAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_CREATE = 'ROLE_ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusMovementAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_DELETE = 'ROLE_ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusMovementAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusMovementAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_MASTER = 'ROLE_ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StatusMovementAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STOCK_STATUSOVEMENT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StockAdmin </summary>
  ADMIN_STOCK_VIEW = 'ROLE_ADMIN_STOCK_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StockAdmin </summary>
  ADMIN_STOCK_LIST = 'ROLE_ADMIN_STOCK_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StockAdmin </summary>
  ADMIN_STOCK_EDIT = 'ROLE_ADMIN_STOCK_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StockAdmin </summary>
  ADMIN_STOCK_CREATE = 'ROLE_ADMIN_STOCK_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StockAdmin </summary>
  ADMIN_STOCK_DELETE = 'ROLE_ADMIN_STOCK_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StockAdmin </summary>
  ADMIN_STOCK_OPERATOR = 'ROLE_ADMIN_STOCK_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StockAdmin </summary>
  ADMIN_STOCK_MASTER = 'ROLE_ADMIN_STOCK_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\StockAdmin </summary>
  ADMIN_STOCK_EXPORT = 'ROLE_ADMIN_STOCK_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_SUPPLIER_VIEW = 'ROLE_ADMIN_CONFIGURATION_STOCK_SUPPLIER_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_SUPPLIER_LIST = 'ROLE_ADMIN_CONFIGURATION_STOCK_SUPPLIER_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_SUPPLIER_EDIT = 'ROLE_ADMIN_CONFIGURATION_STOCK_SUPPLIER_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_SUPPLIER_CREATE = 'ROLE_ADMIN_CONFIGURATION_STOCK_SUPPLIER_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_SUPPLIER_DELETE = 'ROLE_ADMIN_CONFIGURATION_STOCK_SUPPLIER_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_SUPPLIER_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STOCK_SUPPLIER_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_SUPPLIER_MASTER = 'ROLE_ADMIN_CONFIGURATION_STOCK_SUPPLIER_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\SupplierAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_SUPPLIER_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STOCK_SUPPLIER_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolAdmin </summary>
  ADMIN_STOCK_TOOL_VIEW = 'ROLE_ADMIN_STOCK_TOOL_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolAdmin </summary>
  ADMIN_STOCK_TOOL_LIST = 'ROLE_ADMIN_STOCK_TOOL_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolAdmin </summary>
  ADMIN_STOCK_TOOL_EDIT = 'ROLE_ADMIN_STOCK_TOOL_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolAdmin </summary>
  ADMIN_STOCK_TOOL_CREATE = 'ROLE_ADMIN_STOCK_TOOL_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolAdmin </summary>
  ADMIN_STOCK_TOOL_DELETE = 'ROLE_ADMIN_STOCK_TOOL_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolAdmin </summary>
  ADMIN_STOCK_TOOL_OPERATOR = 'ROLE_ADMIN_STOCK_TOOL_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolAdmin </summary>
  ADMIN_STOCK_TOOL_MASTER = 'ROLE_ADMIN_STOCK_TOOL_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolAdmin </summary>
  ADMIN_STOCK_TOOL_EXPORT = 'ROLE_ADMIN_STOCK_TOOL_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolEventAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLEVENT_VIEW = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLEVENT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolEventAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLEVENT_LIST = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLEVENT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolEventAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLEVENT_EDIT = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLEVENT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolEventAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLEVENT_CREATE = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLEVENT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolEventAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLEVENT_DELETE = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLEVENT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolEventAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLEVENT_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLEVENT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolEventAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLEVENT_MASTER = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLEVENT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolEventAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLEVENT_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLEVENT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolMarkAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMARK_VIEW = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMARK_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolMarkAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMARK_LIST = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMARK_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolMarkAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMARK_EDIT = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMARK_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolMarkAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMARK_CREATE = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMARK_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolMarkAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMARK_DELETE = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMARK_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolMarkAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMARK_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMARK_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolMarkAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMARK_MASTER = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMARK_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolMarkAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMARK_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMARK_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolModelAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMODEL_VIEW = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMODEL_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolModelAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMODEL_LIST = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMODEL_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolModelAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMODEL_EDIT = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMODEL_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolModelAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMODEL_CREATE = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMODEL_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolModelAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMODEL_DELETE = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMODEL_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolModelAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMODEL_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMODEL_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolModelAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMODEL_MASTER = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMODEL_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolModelAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLMODEL_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLMODEL_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolTypeAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLTYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLTYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolTypeAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLTYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLTYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolTypeAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLTYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLTYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolTypeAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLTYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLTYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolTypeAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLTYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLTYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolTypeAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLTYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLTYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolTypeAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLTYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLTYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\StockBundle\Admin\ToolTypeAdmin </summary>
  ADMIN_CONFIGURATION_STOCK_TOOLTYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_STOCK_TOOLTYPE_EXPORT',
  //#endregion

  //#region Rôles liés à la partie Intervention (préfixe Wk) - de 1300 à 1519
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AgreementAdmin </summary>
  ADMIN_CONFIGURATION_AGREEMENT_VIEW = 'ROLE_ADMIN_CONFIGURATION_AGREEMENT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AgreementAdmin </summary>
  ADMIN_CONFIGURATION_AGREEMENT_LIST = 'ROLE_ADMIN_CONFIGURATION_AGREEMENT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AgreementAdmin </summary>
  ADMIN_CONFIGURATION_AGREEMENT_EDIT = 'ROLE_ADMIN_CONFIGURATION_AGREEMENT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AgreementAdmin </summary>
  ADMIN_CONFIGURATION_AGREEMENT_CREATE = 'ROLE_ADMIN_CONFIGURATION_AGREEMENT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AgreementAdmin </summary>
  ADMIN_CONFIGURATION_AGREEMENT_DELETE = 'ROLE_ADMIN_CONFIGURATION_AGREEMENT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AgreementAdmin </summary>
  ADMIN_CONFIGURATION_AGREEMENT_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_AGREEMENT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AgreementAdmin </summary>
  ADMIN_CONFIGURATION_AGREEMENT_MASTER = 'ROLE_ADMIN_CONFIGURATION_AGREEMENT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AgreementAdmin </summary>
  ADMIN_CONFIGURATION_AGREEMENT_EXPORT = 'ROLE_ADMIN_CONFIGURATION_AGREEMENT_EXPORT',

  ///<summary> Lister les alarmes d"une station (Castres\WorkBundle\Admin\AlarmAlx) </summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_ALARMALX_VIEW = 'ROLE_ADMIN_ALARMALX_VIEW',
  ///<summary> Lister toutes les alarmes (Castres\WorkBundle\Admin\AlarmAlx) </summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_ALARMALX_LIST = 'ROLE_ADMIN_ALARMALX_LIST',
  ///<summary> Acquitter une alarme (Castres\WorkBundle\Admin\AlarmAlx) </summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_ALARMALX_EDIT = 'ROLE_ADMIN_ALARMALX_EDIT',
  ///<summary> Créer une intervention depuis une alarme (Castres\WorkBundle\Admin\AlarmAlx) </summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_ALARMALX_CREATE = 'ROLE_ADMIN_ALARMALX_CREATE',
  ///<summary> Supprimer une alarme / un groupe d"alarmes (Castres\WorkBundle\Admin\AlarmAlx) </summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_ALARMALX_DELETE = 'ROLE_ADMIN_ALARMALX_DELETE',

  //[Display(Description = "//[Display(Description",
  ADMIN_ALARMALX_EXPORT = 'ROLE_ADMIN_ALARMALX_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AnomalyAdmin </summary>
  ADMIN_CONFIGURATION_ANOMALY_VIEW = 'ROLE_ADMIN_CONFIGURATION_ANOMALY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AnomalyAdmin </summary>
  ADMIN_CONFIGURATION_ANOMALY_LIST = 'ROLE_ADMIN_CONFIGURATION_ANOMALY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AnomalyAdmin </summary>
  ADMIN_CONFIGURATION_ANOMALY_EDIT = 'ROLE_ADMIN_CONFIGURATION_ANOMALY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AnomalyAdmin </summary>
  ADMIN_CONFIGURATION_ANOMALY_CREATE = 'ROLE_ADMIN_CONFIGURATION_ANOMALY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AnomalyAdmin </summary>
  ADMIN_CONFIGURATION_ANOMALY_DELETE = 'ROLE_ADMIN_CONFIGURATION_ANOMALY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AnomalyAdmin </summary>
  ADMIN_CONFIGURATION_ANOMALY_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_ANOMALY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AnomalyAdmin </summary>
  ADMIN_CONFIGURATION_ANOMALY_MASTER = 'ROLE_ADMIN_CONFIGURATION_ANOMALY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\AnomalyAdmin </summary>
  ADMIN_CONFIGURATION_ANOMALY_EXPORT = 'ROLE_ADMIN_CONFIGURATION_ANOMALY_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\CampaignTypeAdmin </summary>
  ADMIN_CONFIGURATION_CAMPAIGNTYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_CAMPAIGNTYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\CampaignTypeAdmin </summary>
  ADMIN_CONFIGURATION_CAMPAIGNTYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_CAMPAIGNTYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\CampaignTypeAdmin </summary>
  ADMIN_CONFIGURATION_CAMPAIGNTYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_CAMPAIGNTYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\CampaignTypeAdmin </summary>
  ADMIN_CONFIGURATION_CAMPAIGNTYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_CAMPAIGNTYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\CampaignTypeAdmin </summary>
  ADMIN_CONFIGURATION_CAMPAIGNTYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_CAMPAIGNTYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\CampaignTypeAdmin </summary>
  ADMIN_CONFIGURATION_CAMPAIGNTYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_CAMPAIGNTYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\CampaignTypeAdmin </summary>
  ADMIN_CONFIGURATION_CAMPAIGNTYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_CAMPAIGNTYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\CampaignTypeAdmin </summary>
  ADMIN_CONFIGURATION_CAMPAIGNTYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_CAMPAIGNTYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_DOMAIN_VIEW = 'ROLE_ADMIN_CONFIGURATION_DOMAIN_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_DOMAIN_LIST = 'ROLE_ADMIN_CONFIGURATION_DOMAIN_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_DOMAIN_EDIT = 'ROLE_ADMIN_CONFIGURATION_DOMAIN_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_DOMAIN_CREATE = 'ROLE_ADMIN_CONFIGURATION_DOMAIN_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_DOMAIN_DELETE = 'ROLE_ADMIN_CONFIGURATION_DOMAIN_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_DOMAIN_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_DOMAIN_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_DOMAIN_MASTER = 'ROLE_ADMIN_CONFIGURATION_DOMAIN_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainAdmin </summary>
  ADMIN_CONFIGURATION_DOMAIN_EXPORT = 'ROLE_ADMIN_CONFIGURATION_DOMAIN_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainGroupAdmin </summary>
  ADMIN_CONFIGURATION_DOMAINGROUP_VIEW = 'ROLE_ADMIN_CONFIGURATION_DOMAINGROUP_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainGroupAdmin </summary>
  ADMIN_CONFIGURATION_DOMAINGROUP_LIST = 'ROLE_ADMIN_CONFIGURATION_DOMAINGROUP_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainGroupAdmin </summary>
  ADMIN_CONFIGURATION_DOMAINGROUP_EDIT = 'ROLE_ADMIN_CONFIGURATION_DOMAINGROUP_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainGroupAdmin </summary>
  ADMIN_CONFIGURATION_DOMAINGROUP_CREATE = 'ROLE_ADMIN_CONFIGURATION_DOMAINGROUP_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainGroupAdmin </summary>
  ADMIN_CONFIGURATION_DOMAINGROUP_DELETE = 'ROLE_ADMIN_CONFIGURATION_DOMAINGROUP_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainGroupAdmin </summary>
  ADMIN_CONFIGURATION_DOMAINGROUP_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_DOMAINGROUP_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainGroupAdmin </summary>
  ADMIN_CONFIGURATION_DOMAINGROUP_MASTER = 'ROLE_ADMIN_CONFIGURATION_DOMAINGROUP_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\DomainGroupAdmin </summary>
  ADMIN_CONFIGURATION_DOMAINGROUP_EXPORT = 'ROLE_ADMIN_CONFIGURATION_DOMAINGROUP_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\EmergencyLevelAdmin </summary>
  ADMIN_CONFIGURATION_EMERGENCYLEVEL_VIEW = 'ROLE_ADMIN_CONFIGURATION_EMERGENCYLEVEL_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\EmergencyLevelAdmin </summary>
  ADMIN_CONFIGURATION_EMERGENCYLEVEL_LIST = 'ROLE_ADMIN_CONFIGURATION_EMERGENCYLEVEL_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\EmergencyLevelAdmin </summary>
  ADMIN_CONFIGURATION_EMERGENCYLEVEL_EDIT = 'ROLE_ADMIN_CONFIGURATION_EMERGENCYLEVEL_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\EmergencyLevelAdmin </summary>
  ADMIN_CONFIGURATION_EMERGENCYLEVEL_CREATE = 'ROLE_ADMIN_CONFIGURATION_EMERGENCYLEVEL_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\EmergencyLevelAdmin </summary>
  ADMIN_CONFIGURATION_EMERGENCYLEVEL_DELETE = 'ROLE_ADMIN_CONFIGURATION_EMERGENCYLEVEL_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\EmergencyLevelAdmin </summary>
  ADMIN_CONFIGURATION_EMERGENCYLEVEL_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_EMERGENCYLEVEL_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\EmergencyLevelAdmin </summary>
  ADMIN_CONFIGURATION_EMERGENCYLEVEL_MASTER = 'ROLE_ADMIN_CONFIGURATION_EMERGENCYLEVEL_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\EmergencyLevelAdmin </summary>
  ADMIN_CONFIGURATION_EMERGENCYLEVEL_EXPORT = 'ROLE_ADMIN_CONFIGURATION_EMERGENCYLEVEL_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_WORK_FAMILY_VIEW = 'ROLE_ADMIN_CONFIGURATION_WORK_FAMILY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_WORK_FAMILY_LIST = 'ROLE_ADMIN_CONFIGURATION_WORK_FAMILY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_WORK_FAMILY_EDIT = 'ROLE_ADMIN_CONFIGURATION_WORK_FAMILY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_WORK_FAMILY_CREATE = 'ROLE_ADMIN_CONFIGURATION_WORK_FAMILY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_WORK_FAMILY_DELETE = 'ROLE_ADMIN_CONFIGURATION_WORK_FAMILY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_WORK_FAMILY_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_WORK_FAMILY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_WORK_FAMILY_MASTER = 'ROLE_ADMIN_CONFIGURATION_WORK_FAMILY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\FamilyAdmin </summary>
  ADMIN_CONFIGURATION_WORK_FAMILY_EXPORT = 'ROLE_ADMIN_CONFIGURATION_WORK_FAMILY_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\GroupAdmin </summary>
  ADMIN_CONFIGURATION_GROUP_VIEW = 'ROLE_ADMIN_CONFIGURATION_GROUP_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\GroupAdmin </summary>
  ADMIN_CONFIGURATION_GROUP_LIST = 'ROLE_ADMIN_CONFIGURATION_GROUP_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\GroupAdmin </summary>
  ADMIN_CONFIGURATION_GROUP_EDIT = 'ROLE_ADMIN_CONFIGURATION_GROUP_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\GroupAdmin </summary>
  ADMIN_CONFIGURATION_GROUP_CREATE = 'ROLE_ADMIN_CONFIGURATION_GROUP_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\GroupAdmin </summary>
  ADMIN_CONFIGURATION_GROUP_DELETE = 'ROLE_ADMIN_CONFIGURATION_GROUP_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\GroupAdmin </summary>
  ADMIN_CONFIGURATION_GROUP_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_GROUP_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\GroupAdmin </summary>
  ADMIN_CONFIGURATION_GROUP_MASTER = 'ROLE_ADMIN_CONFIGURATION_GROUP_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\GroupAdmin </summary>
  ADMIN_CONFIGURATION_GROUP_EXPORT = 'ROLE_ADMIN_CONFIGURATION_GROUP_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\InterventionAdmin </summary>
  ADMIN_WORK_INTERVENTION_VIEW = 'ROLE_ADMIN_WORK_INTERVENTION_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\InterventionAdmin </summary>
  ADMIN_WORK_INTERVENTION_LIST = 'ROLE_ADMIN_WORK_INTERVENTION_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\InterventionAdmin </summary>
  ADMIN_WORK_INTERVENTION_EDIT = 'ROLE_ADMIN_WORK_INTERVENTION_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\InterventionAdmin </summary>
  ADMIN_WORK_INTERVENTION_CREATE = 'ROLE_ADMIN_WORK_INTERVENTION_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\InterventionAdmin </summary>
  ADMIN_WORK_INTERVENTION_DELETE = 'ROLE_ADMIN_WORK_INTERVENTION_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\InterventionAdmin </summary>
  ADMIN_WORK_INTERVENTION_OPERATOR = 'ROLE_ADMIN_WORK_INTERVENTION_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\InterventionAdmin </summary>
  ADMIN_WORK_INTERVENTION_MASTER = 'ROLE_ADMIN_WORK_INTERVENTION_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\InterventionAdmin </summary>
  ADMIN_WORK_INTERVENTION_EXPORT = 'ROLE_ADMIN_WORK_INTERVENTION_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\JobAdmin </summary>
  ADMIN_CONFIGURATION_JOB_VIEW = 'ROLE_ADMIN_CONFIGURATION_JOB_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\JobAdmin </summary>
  ADMIN_CONFIGURATION_JOB_LIST = 'ROLE_ADMIN_CONFIGURATION_JOB_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\JobAdmin </summary>
  ADMIN_CONFIGURATION_JOB_EDIT = 'ROLE_ADMIN_CONFIGURATION_JOB_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\JobAdmin </summary>
  ADMIN_CONFIGURATION_JOB_CREATE = 'ROLE_ADMIN_CONFIGURATION_JOB_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\JobAdmin </summary>
  ADMIN_CONFIGURATION_JOB_DELETE = 'ROLE_ADMIN_CONFIGURATION_JOB_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\JobAdmin </summary>
  ADMIN_CONFIGURATION_JOB_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_JOB_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\JobAdmin </summary>
  ADMIN_CONFIGURATION_JOB_MASTER = 'ROLE_ADMIN_CONFIGURATION_JOB_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\JobAdmin </summary>
  ADMIN_CONFIGURATION_JOB_EXPORT = 'ROLE_ADMIN_CONFIGURATION_JOB_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_NATURE_VIEW = 'ROLE_ADMIN_CONFIGURATION_NATURE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_NATURE_LIST = 'ROLE_ADMIN_CONFIGURATION_NATURE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_NATURE_EDIT = 'ROLE_ADMIN_CONFIGURATION_NATURE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_NATURE_CREATE = 'ROLE_ADMIN_CONFIGURATION_NATURE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_NATURE_DELETE = 'ROLE_ADMIN_CONFIGURATION_NATURE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_NATURE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_NATURE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_NATURE_MASTER = 'ROLE_ADMIN_CONFIGURATION_NATURE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\NatureAdmin </summary>
  ADMIN_CONFIGURATION_NATURE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_NATURE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportAdmin </summary>
  ADMIN_WORK_REPORT_VIEW = 'ROLE_ADMIN_WORK_REPORT_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportAdmin </summary>
  ADMIN_WORK_REPORT_LIST = 'ROLE_ADMIN_WORK_REPORT_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportAdmin </summary>
  ADMIN_WORK_REPORT_EDIT = 'ROLE_ADMIN_WORK_REPORT_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportAdmin </summary>
  ADMIN_WORK_REPORT_CREATE = 'ROLE_ADMIN_WORK_REPORT_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportAdmin </summary>
  ADMIN_WORK_REPORT_DELETE = 'ROLE_ADMIN_WORK_REPORT_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportAdmin </summary>
  ADMIN_WORK_REPORT_OPERATOR = 'ROLE_ADMIN_WORK_REPORT_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportAdmin </summary>
  ADMIN_WORK_REPORT_MASTER = 'ROLE_ADMIN_WORK_REPORT_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportAdmin </summary>
  ADMIN_WORK_REPORT_EXPORT = 'ROLE_ADMIN_WORK_REPORT_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportCategoryAdmin </summary>
  ADMIN_CONFIGURATION_REPORTCATEGORY_VIEW = 'ROLE_ADMIN_CONFIGURATION_REPORTCATEGORY_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportCategoryAdmin </summary>
  ADMIN_CONFIGURATION_REPORTCATEGORY_LIST = 'ROLE_ADMIN_CONFIGURATION_REPORTCATEGORY_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportCategoryAdmin </summary>
  ADMIN_CONFIGURATION_REPORTCATEGORY_EDIT = 'ROLE_ADMIN_CONFIGURATION_REPORTCATEGORY_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportCategoryAdmin </summary>
  ADMIN_CONFIGURATION_REPORTCATEGORY_CREATE = 'ROLE_ADMIN_CONFIGURATION_REPORTCATEGORY_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportCategoryAdmin </summary>
  ADMIN_CONFIGURATION_REPORTCATEGORY_DELETE = 'ROLE_ADMIN_CONFIGURATION_REPORTCATEGORY_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportCategoryAdmin </summary>
  ADMIN_CONFIGURATION_REPORTCATEGORY_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_REPORTCATEGORY_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportCategoryAdmin </summary>
  ADMIN_CONFIGURATION_REPORTCATEGORY_MASTER = 'ROLE_ADMIN_CONFIGURATION_REPORTCATEGORY_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportCategoryAdmin </summary>
  ADMIN_CONFIGURATION_REPORTCATEGORY_EXPORT = 'ROLE_ADMIN_CONFIGURATION_REPORTCATEGORY_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportItemAdmin </summary>
  ADMIN_CONFIGURATION_REPORTITEM_VIEW = 'ROLE_ADMIN_CONFIGURATION_REPORTITEM_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportItemAdmin </summary>
  ADMIN_CONFIGURATION_REPORTITEM_LIST = 'ROLE_ADMIN_CONFIGURATION_REPORTITEM_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportItemAdmin </summary>
  ADMIN_CONFIGURATION_REPORTITEM_EDIT = 'ROLE_ADMIN_CONFIGURATION_REPORTITEM_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportItemAdmin </summary>
  ADMIN_CONFIGURATION_REPORTITEM_CREATE = 'ROLE_ADMIN_CONFIGURATION_REPORTITEM_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportItemAdmin </summary>
  ADMIN_CONFIGURATION_REPORTITEM_DELETE = 'ROLE_ADMIN_CONFIGURATION_REPORTITEM_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportItemAdmin </summary>
  ADMIN_CONFIGURATION_REPORTITEM_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_REPORTITEM_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportItemAdmin </summary>
  ADMIN_CONFIGURATION_REPORTITEM_MASTER = 'ROLE_ADMIN_CONFIGURATION_REPORTITEM_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportItemAdmin </summary>
  ADMIN_CONFIGURATION_REPORTITEM_EXPORT = 'ROLE_ADMIN_CONFIGURATION_REPORTITEM_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportTypeAdmin </summary>
  ADMIN_CONFIGURATION_REPORTTYPE_VIEW = 'ROLE_ADMIN_CONFIGURATION_REPORTTYPE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportTypeAdmin </summary>
  ADMIN_CONFIGURATION_REPORTTYPE_LIST = 'ROLE_ADMIN_CONFIGURATION_REPORTTYPE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportTypeAdmin </summary>
  ADMIN_CONFIGURATION_REPORTTYPE_EDIT = 'ROLE_ADMIN_CONFIGURATION_REPORTTYPE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportTypeAdmin </summary>
  ADMIN_CONFIGURATION_REPORTTYPE_CREATE = 'ROLE_ADMIN_CONFIGURATION_REPORTTYPE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportTypeAdmin </summary>
  ADMIN_CONFIGURATION_REPORTTYPE_DELETE = 'ROLE_ADMIN_CONFIGURATION_REPORTTYPE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportTypeAdmin </summary>
  ADMIN_CONFIGURATION_REPORTTYPE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_REPORTTYPE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportTypeAdmin </summary>
  ADMIN_CONFIGURATION_REPORTTYPE_MASTER = 'ROLE_ADMIN_CONFIGURATION_REPORTTYPE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\ReportTypeAdmin </summary>
  ADMIN_CONFIGURATION_REPORTTYPE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_REPORTTYPE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\SourceAdmin </summary>
  ADMIN_CONFIGURATION_SOURCE_VIEW = 'ROLE_ADMIN_CONFIGURATION_SOURCE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\SourceAdmin </summary>
  ADMIN_CONFIGURATION_SOURCE_LIST = 'ROLE_ADMIN_CONFIGURATION_SOURCE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\SourceAdmin </summary>
  ADMIN_CONFIGURATION_SOURCE_EDIT = 'ROLE_ADMIN_CONFIGURATION_SOURCE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\SourceAdmin </summary>
  ADMIN_CONFIGURATION_SOURCE_CREATE = 'ROLE_ADMIN_CONFIGURATION_SOURCE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\SourceAdmin </summary>
  ADMIN_CONFIGURATION_SOURCE_DELETE = 'ROLE_ADMIN_CONFIGURATION_SOURCE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\SourceAdmin </summary>
  ADMIN_CONFIGURATION_SOURCE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_SOURCE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\SourceAdmin </summary>
  ADMIN_CONFIGURATION_SOURCE_MASTER = 'ROLE_ADMIN_CONFIGURATION_SOURCE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\SourceAdmin </summary>
  ADMIN_CONFIGURATION_SOURCE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_SOURCE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskAdmin </summary>
  ADMIN_WORK_TASK_VIEW = 'ROLE_ADMIN_WORK_TASK_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskAdmin </summary>
  ADMIN_WORK_TASK_LIST = 'ROLE_ADMIN_WORK_TASK_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskAdmin </summary>
  ADMIN_WORK_TASK_EDIT = 'ROLE_ADMIN_WORK_TASK_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskAdmin </summary>
  ADMIN_WORK_TASK_CREATE = 'ROLE_ADMIN_WORK_TASK_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskAdmin </summary>
  ADMIN_WORK_TASK_DELETE = 'ROLE_ADMIN_WORK_TASK_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskAdmin </summary>
  ADMIN_WORK_TASK_OPERATOR = 'ROLE_ADMIN_WORK_TASK_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskAdmin </summary>
  ADMIN_WORK_TASK_MASTER = 'ROLE_ADMIN_WORK_TASK_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskAdmin </summary>
  ADMIN_WORK_TASK_EXPORT = 'ROLE_ADMIN_WORK_TASK_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskFailureAdmin </summary>
  ADMIN_CONFIGURATION_TASKFAILURE_VIEW = 'ROLE_ADMIN_CONFIGURATION_TASKFAILURE_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskFailureAdmin </summary>
  ADMIN_CONFIGURATION_TASKFAILURE_LIST = 'ROLE_ADMIN_CONFIGURATION_TASKFAILURE_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskFailureAdmin </summary>
  ADMIN_CONFIGURATION_TASKFAILURE_EDIT = 'ROLE_ADMIN_CONFIGURATION_TASKFAILURE_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskFailureAdmin </summary>
  ADMIN_CONFIGURATION_TASKFAILURE_CREATE = 'ROLE_ADMIN_CONFIGURATION_TASKFAILURE_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskFailureAdmin </summary>
  ADMIN_CONFIGURATION_TASKFAILURE_DELETE = 'ROLE_ADMIN_CONFIGURATION_TASKFAILURE_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskFailureAdmin </summary>
  ADMIN_CONFIGURATION_TASKFAILURE_OPERATOR = 'ROLE_ADMIN_CONFIGURATION_TASKFAILURE_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskFailureAdmin </summary>
  ADMIN_CONFIGURATION_TASKFAILURE_MASTER = 'ROLE_ADMIN_CONFIGURATION_TASKFAILURE_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TaskFailureAdmin </summary>
  ADMIN_CONFIGURATION_TASKFAILURE_EXPORT = 'ROLE_ADMIN_CONFIGURATION_TASKFAILURE_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TimeReportingWeek </summary>
  ADMIN_TIMEREPORTINGWEEK_VIEW = 'ROLE_ADMIN_TIMEREPORTINGWEEK_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TimeReportingWeek </summary>
  ADMIN_TIMEREPORTINGWEEK_LIST = 'ROLE_ADMIN_TIMEREPORTINGWEEK_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TimeReportingWeek </summary>
  ADMIN_TIMEREPORTINGWEEK_EDIT = 'ROLE_ADMIN_TIMEREPORTINGWEEK_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TimeReportingWeek </summary>
  ADMIN_TIMEREPORTINGWEEK_CREATE = 'ROLE_ADMIN_TIMEREPORTINGWEEK_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TimeReportingWeek </summary>
  ADMIN_TIMEREPORTINGWEEK_DELETE = 'ROLE_ADMIN_TIMEREPORTINGWEEK_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TimeReportingWeek </summary>
  ADMIN_TIMEREPORTINGWEEK_OPERATOR = 'ROLE_ADMIN_TIMEREPORTINGWEEK_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TimeReportingWeek </summary>
  ADMIN_TIMEREPORTINGWEEK_MASTER = 'ROLE_ADMIN_TIMEREPORTINGWEEK_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\TimeReportingWeek </summary>
  ADMIN_TIMEREPORTINGWEEK_EXPORT = 'ROLE_ADMIN_TIMEREPORTINGWEEK_EXPORT',

  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\VprimAdmin </summary>
  ADMIN_WORK_VPRIM_VIEW = 'ROLE_ADMIN_WORK_VPRIM_VIEW',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\VprimAdmin </summary>
  ADMIN_WORK_VPRIM_LIST = 'ROLE_ADMIN_WORK_VPRIM_LIST',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\VprimAdmin </summary>
  ADMIN_WORK_VPRIM_EDIT = 'ROLE_ADMIN_WORK_VPRIM_EDIT',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\VprimAdmin </summary>
  ADMIN_WORK_VPRIM_CREATE = 'ROLE_ADMIN_WORK_VPRIM_CREATE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\VprimAdmin </summary>
  ADMIN_WORK_VPRIM_DELETE = 'ROLE_ADMIN_WORK_VPRIM_DELETE',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\VprimAdmin </summary>
  ADMIN_WORK_VPRIM_OPERATOR = 'ROLE_ADMIN_WORK_VPRIM_OPERATOR',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\VprimAdmin </summary>
  ADMIN_WORK_VPRIM_MASTER = 'ROLE_ADMIN_WORK_VPRIM_MASTER',
  ///<summary> Classe Admin CMSv1 : Castres\WorkBundle\Admin\VprimAdmin </summary>
  ADMIN_WORK_VPRIM_EXPORT = 'ROLE_ADMIN_WORK_VPRIM_EXPORT',

  /// <summary> Liste des unités de travail </summary>
  ADMIN_WORK_UNIT_LIST = 'ROLE_ADMIN_WORK_UNIT_LIST',
  /// <summary>  Édition des unités de travail </summary>
  ADMIN_WORK_UNIT_EDIT = 'ROLE_ADMIN_WORK_UNIT_EDIT',

  //#endregion

  //#region Rôles liés à Greenflux

  /* Rôles de Greenflux pour l"importation des CDR */

  ///<summary>Greenflux : importation des CDR</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_IMPORT_CDR = 'ROLE_ADMIN_GF_IMPORT_CDR',

  /* Rôles de Greenflux pour les données liées à Greenflux */

  ///<summary>Greenflux : liste des CDR</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_CDR_LIST = 'ROLE_ADMIN_GF_CDR_LIST',
  ///<summary>Greenflux : visualisation d"un CDR</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_CDR_VIEW = 'ROLE_ADMIN_GF_CDR_VIEW',
  ///<summary>Greenflux : édition d"un CDR</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_CDR_EDIT = 'ROLE_ADMIN_GF_CDR_EDIT',
  ///<summary>Greenflux : exportation des CDR</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_CDR_EXPORT = 'ROLE_ADMIN_GF_CDR_EXPORT',

  ///<summary>Greenflux : liste des stations importées</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_STATION_LIST = 'ROLE_ADMIN_GF_STATION_LIST',
  ///<summary>Greenflux : visualisation d"une station importée</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_STATION_VIEW = 'ROLE_ADMIN_GF_STATION_VIEW',
  ///<summary>Greenflux : édition d"une station importée</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_STATION_EDIT = 'ROLE_ADMIN_GF_STATION_EDIT',

  ///<summary>Greenflux : liste des paramètres de configuration</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_SETTING_VIEW = 'ROLE_ADMIN_GF_SETTING_VIEW',
  ///<summary>Greenflux : édition des paramètres de configuration</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_SETTING_EDIT = 'ROLE_ADMIN_GF_SETTING_EDIT',

  /* Rôles de Greenflux pour les notes de virement */

  ///<summary>Greenflux : liste des notes de virement</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_NOTEDEVIREMENT_LIST = 'ROLE_ADMIN_GF_NOTEDEVIREMENT_LIST',
  ///<summary>Greenflux : visualisation d"une note de virement</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_NOTEDEVIREMENT_VIEW = 'ROLE_ADMIN_GF_NOTEDEVIREMENT_VIEW',
  ///<summary>Greenflux : édition d"une note de virement</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_NOTEDEVIREMENT_EDIT = 'ROLE_ADMIN_GF_NOTEDEVIREMENT_EDIT',
  ///<summary>Greenflux : exportation des notes de virement</summary>
  //[Display(Description = "//[Display(Description",
  ADMIN_GF_NOTEDEVIREMENT_EXPORT = 'ROLE_ADMIN_GF_NOTEDEVIREMENT_EXPORT',

  //#endregion

  ADMIN_STUDY_REFERENCE_LIST = 'ROLE_ADMIN_STUDY_REFERENCE_LIST',
  ADMIN_STUDY_REFERENCE_EDIT = 'ROLE_ADMIN_STUDY_REFERENCE_EDIT',
}

export default ROLE;
