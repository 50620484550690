import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { SfService } from '../../../interface/SfType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const SfServiceListConfig: CmsColumnDef<SfService>[] = [
  { header: 'Nom', id: 'labelWithAgency', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Code ENI', id: 'codeEni', Filter: CmsTableFilter.Text },
  { header: 'Code Certas', id: 'codeCertas', Filter: CmsTableFilter.Text },
  { header: 'Agence', id: 'agency.label', Filter: CmsTableFilter.Text },
  { header: 'Couleur', id: 'color', cell: CmsTableCell.Color },
  { header: 'Email', id: 'email', Filter: CmsTableFilter.Text },
];

/**
 * Liste des services
 */
export const SfServiceList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_STAFF_SERVICE_CREATE])
    ? [<AddNavigationButton title="Ajouter un service" to="/castres/staff/service/create" />]
    : [];
  return (
    <CmsFrontendTable
      columns={SfServiceListConfig}
      title="Liste des services"
      navigateTo={(id: number) => `/castres/staff/service/${id}/edit`}
      route={APIRoute.SfService}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou édition d'un service
 * @param id id du service à éditer
 * @param setHttpCodePage fonction pour modifier le code http de la page
 */
export const SfServiceCreateEdit: FC = ({ id }: any) => (
  <CmsForm id={id} currentUrl="/castres/staff/service/" route={APIRoute.SfService} title="un service" thin>
    <CmsFormInput.Text id="label" label="Nom" required max={50} />
    <CmsFormInput.Text id="code" label="Code" max={50} />
    <CmsFormInput.Text id="codeEni" label="Code ENI" max={50} />
    <CmsFormInput.Text id="codeCertas" label="Code Certas" max={50} />
    <CmsFormInput.Select id="agencyId" label="Agence" options={APIRoute.ClAgency + '/Simplified'} required />
    <CmsFormInput.Select
      id="legalEntityId"
      label="Entité légale"
      options={APIRoute.ClLegalEntity + '/Simplified'}
      required
    />
    <CmsFormInput.Color id="color" label="Couleur du service" withoutSharp required />
    <CmsFormInput.Text id="email" label="Email" required max={50} />
    <CmsFormInput.Select id="domainIdList" label="Domaines" options={APIRoute.WkDomain + '/Simplified'} multiple />
  </CmsForm>
);
