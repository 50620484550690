import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { NavProps } from './index';
import ROLE from 'constant/role.constant';

export const Conf: NavProps = {
  name: 'Configuration',
  icon: <SettingsIcon />,
  feature: ROLE.ADMIN_CONFIGURATION,
  subList: [
    {
      name: 'Ressources Humaines',
      subList: [
        {
          name: "Types d'absence",
          link: '/castres/staff/absencetype/list',
          feature: ROLE.ADMIN_CONFIGURATION_ABSENCETYPE_LIST,
        },
        {
          name: 'Temps de travail',
          link: '/castres/staff/worktime/list',
          feature: ROLE.ADMIN_CONFIGURATION_WORKTIME_LIST,
        },
        {
          name: 'Types de permis',
          link: '/castres/staff/licensetype/list',
          feature: ROLE.ADMIN_CONFIGURATION_LICENSETYPE_LIST,
        },
        {
          name: "Types d'habilitation",
          link: '/castres/staff/habilitationtype/list',
          feature: ROLE.ADMIN_CONFIGURATION_TRAININGTYPE_LIST,
        },
        {
          name: "Cellules d'habilitation",
          link: '/castres/staff/habilitationcellposition/list',
          feature: ROLE.HABILITATION_CELL_POSITION,
        },
      ],
    },
    {
      name: 'Agences',
      subList: [
        {
          name: 'Agences',
          link: '/castres/client/agency/list',
          feature: ROLE.ADMIN_CONFIGURATION_AGENCY_LIST,
        },
        {
          name: 'Services',
          link: '/castres/staff/service/list',
          feature: ROLE.ADMIN_STAFF_SERVICE_LIST,
        },
      ],
    },
    {
      name: 'Notification',
      subList: [
        {
          name: 'Configuration de notification',
          link: '/castres/configuration/notification/list',
          feature: ROLE.SUPER_ADMIN,
        },
        {
          name: 'Famille de notification',
          link: '/castres/configuration/notification/family/list',
          feature: ROLE.SUPER_ADMIN,
        },
      ],
    },
    {
      name: 'Sites',
      subList: [
        {
          name: 'Distributeurs',
          link: '/castres/client/company/list',
          feature: ROLE.ADMIN_CONFIGURATION_COMPANY_LIST,
        },
        {
          name: 'Réseaux des sites',
          link: '/castres/client/network/list',
          feature: ROLE.ADMIN_CONFIGURATION_NETWORK_LIST,
        },
        {
          name: 'Départements / Dispatchers',
          link: '/castres/location/department/list',
          feature: ROLE.ADMIN_PARK_STATION_LIST,
        },
      ],
    },
    {
      name: 'Interventions',
      subList: [
        {
          name: 'Source (SDM)',
          linkV1: 'castres/work/source/list',
          feature: ROLE.ADMIN_CONFIGURATION_SOURCE_LIST,
        },
        {
          name: "Corps d'états",
          linkV1: 'castres/work/job/list',
          feature: ROLE.ADMIN_CONFIGURATION_JOB_LIST,
        },
        {
          name: 'Contrats',
          linkV1: 'castres/work/agreement/list',
          feature: ROLE.ADMIN_CONFIGURATION_AGREEMENT_LIST,
        },
        {
          name: 'Groupes de domaines',
          linkV1: 'castres/work/domaingroup/list',
          feature: ROLE.ADMIN_CONFIGURATION_DOMAINGROUP_LIST,
        },
        {
          name: 'Domaines',
          linkV1: 'castres/work/domain/list',
          link: '/castres/work/domain/list',
          feature: ROLE.ADMIN_CONFIGURATION_DOMAIN_LIST,
        },
        {
          name: 'Natures',
          linkV1: 'castres/work/nature/list',
          feature: ROLE.ADMIN_CONFIGURATION_NATURE_LIST,
        },
        {
          name: "Degrés d'urgence",
          linkV1: 'castres/work/emergencylevel/list',
          feature: ROLE.ADMIN_CONFIGURATION_EMERGENCYLEVEL_LIST,
        },
        {
          name: 'Analyse hors délais',
          linkV1: 'castres/work/interventionfailure/list',
          feature: ROLE.ADMIN_WORK_INTERVENTION_LIST,
        },
        {
          name: 'Pannes des tâches',
          linkV1: 'castres/work/taskfailure/list',
          feature: ROLE.ADMIN_CONFIGURATION_TASKFAILURE_LIST,
        },
        {
          name: 'Groupes (Travaux)',
          linkV1: 'castres/work/group/list',
          feature: ROLE.ADMIN_CONFIGURATION_GROUP_LIST,
        },
      ],
    },
    {
      name: "Rapport d'intervention",
      subList: [
        {
          name: "Type Rapport d'intervention",
          link: '/castres/work/reporttype/list',
          feature: ROLE.ADMIN_CONFIGURATION_REPORTTYPE_LIST,
        },
        {
          name: 'IBE Catégorie',
          link: '/castres/work/reportcategory/list',
          feature: ROLE.ADMIN_CONFIGURATION_REPORTCATEGORY_LIST,
        },
        {
          name: 'IBE Lignes',
          link: '/castres/work/reportitem/list',
          feature: ROLE.ADMIN_CONFIGURATION_REPORTITEM_LIST,
        },
      ],
    },
    {
      name: 'Campagnes',
      subList: [
        {
          name: 'Type de campagnes',
          linkV1: 'castres/work/campaigntype/list',
          feature: ROLE.ADMIN_CONFIGURATION_CAMPAIGNTYPE_LIST,
        },
        {
          name: 'Famille',
          linkV1: 'castres/work/family/list',
          feature: ROLE.ADMIN_CONFIGURATION_WORK_FAMILY_LIST,
        },
      ],
    },
    {
      name: 'Equipements',
      subList: [
        {
          name: 'Modèles Commerciaux',
          linkV1: 'castres/equipment/model/list',
          feature: ROLE.ADMIN_CONFIGURATION_MODEL_LIST,
        },
        {
          name: 'Modèles Métrologiques',
          linkV1: 'castres/equipment/metrologicmodel/list',
          feature: ROLE.ADMIN_CONFIGURATION_METROLOGICMODEL_LIST,
        },
        {
          name: 'Fournisseurs',
          linkV1: 'castres/equipment/supplier/list',
          feature: ROLE.ADMIN_CONFIGURATION_SUPPLIER_LIST,
        },
        {
          name: 'Constructeurs',
          linkV1: 'castres/equipment/manufacturer/list',
          feature: ROLE.ADMIN_CONFIGURATION_MANUFACTURER_LIST,
        },
        {
          name: "Type d'equipement",
          linkV1: 'castres/equipment/equipmenttype/list',
          feature: ROLE.ADMIN_CONFIGURATION_EQUIPMENT_TYPE_LIST,
        },
        {
          name: 'Attributs',
          linkV1: 'castres/equipment/equipmentattribute/list',
          feature: ROLE.ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTE_LIST,
        },
        {
          name: "Liste d'attributs",
          linkV1: 'castres/equipment/equipmentattributelist/list',
          feature: ROLE.ADMIN_CONFIGURATION_EQUIPMENT_ATTRIBUTELIST_LIST,
        },
      ],
    },
    {
      name: 'Appareils distributeurs',
      subList: [
        {
          name: 'Domaines',
          link: '/castres/equipment/domain/list',
          feature: ROLE.ADMIN_CONFIGURATION_EQ_DOMAIN_LIST,
        },
        {
          name: 'Natures',
          link: '/castres/equipment/nature/list',
          feature: ROLE.ADMIN_CONFIGURATION_EQ_NATURE_LIST,
        },
        {
          name: 'Actions',
          link: '/castres/equipment/action/list',
          feature: ROLE.ADMIN_CONFIGURATION_EQ_ACTION_LIST,
        },
        {
          name: 'Electrovannes',
          linkV1: 'castres/equipment/solenoidtype/list',
          feature: ROLE.ADMIN_CONFIGURATION_SOLENOID_TYPE_LIST,
        },
        {
          name: 'Moteurs de distribution',
          linkV1: 'castres/equipment/dispensermotortype/list',
          feature: ROLE.ADMIN_CONFIGURATION_DISPENSER_MOTOR_TYPE_LIST,
        },
        {
          name: 'Courroies',
          linkV1: 'castres/equipment/belt/list',
          feature: ROLE.ADMIN_CONFIGURATION_BELT_LIST,
        },
        {
          name: 'Raccord flexibles',
          linkV1: 'castres/equipment/connection/list',
          feature: ROLE.ADMIN_CONFIGURATION_CONNECTION_LIST,
        },
        {
          name: 'Diamètre flexibles',
          linkV1: 'castres/equipment/diameter/list',
          feature: ROLE.ADMIN_CONFIGURATION_DIAMETER_LIST,
        },
        {
          name: 'Longueurs flexibles',
          linkV1: 'castres/equipment/flexlength/list',
          feature: ROLE.ADMIN_CONFIGURATION_FLEXLENGTH_LIST,
        },
        {
          name: 'Produits',
          linkV1: 'castres/equipment/product/list',
          feature: ROLE.ADMIN_CONFIGURATION_PRODUCT_LIST,
        },
        {
          name: "Décision d'approbation",
          linkV1: 'castres/equipment/approvaldecision/list',
          feature: ROLE.ADMIN_PARK_APPROVALDECISION_LIST,
        },
        {
          name: 'Type de vignettes',
          linkV1: 'castres/equipment/stickertype/list',
          feature: ROLE.ADMIN_CONFIGURATION_STICKERTYPE_LIST,
        },
        {
          name: 'Anomalies',
          linkV1: 'castres/work/anomaly/list',
          feature: ROLE.ADMIN_CONFIGURATION_ANOMALY_LIST,
        },
      ],
    },
    {
      name: 'Commande',
      subList: [
        {
          name: 'Nature des commandes',
          linkV1: 'castres/billing/nature/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_NATURE_LIST,
        },
        {
          name: 'Type de livraison',
          linkV1: 'castres/billing/deliverytype/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_DELIVERYTYPE_LIST,
        },
        {
          name: 'Statuts',
          linkV1: 'castres/billing/statusorder/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_STATUSORDER_LIST,
        },
      ],
    },
    {
      name: 'Articles',
      subList: [
        {
          name: 'Famille',
          link: '/castres/stock/family/list',
          feature: ROLE.ADMIN_CONFIGURATION_STOCK_FAMILY_LIST,
        },
        {
          name: 'Fournisseurs',
          link: '/castres/stock/company/list',
          feature: ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_LIST,
        },
        {
          name: 'Magasins',
          link: '/castres/stock/supplier/list',
          feature: ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_LIST,
        },
        {
          name: 'Statut',
          link: '/castres/stock/status/list',
          feature: ROLE.ADMIN_CONFIGURATION_STATUS_LIST,
        },
      ],
    },
    {
      name: 'Stock',
      subList: [
        {
          name: 'Stock',
          linkV1: 'castres/stock/stock/list',
          feature: ROLE.ADMIN_STOCK_LIST,
        },
        {
          name: 'Etats',
          linkV1: 'castres/stock/statusmovement/list',
          feature: ROLE.ADMIN_STOCK_MOVEMENT_LIST,
        },
      ],
    },
    {
      name: 'Matériel',
      subList: [
        {
          name: "Type d'outil",
          link: '/castres/stock/tooltype/list',
          feature: ROLE.ADMIN_CONFIGURATION_STOCK_TOOLTYPE_LIST,
        },
        {
          name: "Modèle d'outil",
          link: '/castres/stock/toolmodel/list',
          feature: ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMODEL_LIST,
        },
        {
          name: "Marque d'outil",
          link: '/castres/stock/toolmark/list',
          feature: ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMARK_LIST,
        },
      ],
    },
    {
      name: 'Devis/Facturation',
      subList: [
        {
          name: 'Statuts devis/factures',
          link: '/castres/billing/status/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_STATUS_LIST,
        },
        {
          name: 'Fréquence de facturation',
          link: 'castres/billing/contractfrequencyinvoice/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_LIST,
        },
        {
          name: 'Type de facturation',
          link: 'castres/billing/contracttypeinvoice/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTTYPEINVOICE_LIST,
        },
        {
          name: 'Statut de contrat',
          link: 'castres/billing/contractstatus/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_LIST,
        },
        {
          name: "Statut des factures de contrats à l'acte",
          link: 'castres/billing/contractlineinterventionstatus/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTLINEINTERVENTIONSTATUS_LIST,
        },
        {
          name: 'Unité',
          link: '/castres/work/unit/list',
          feature: ROLE.ADMIN_WORK_UNIT_LIST,
        },
      ],
    },
    {
      name: 'Bordereaux',
      subList: [
        {
          name: 'Bordereaux',
          link: '/castres/billing/priceschedule/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_LIST,
        },
        {
          name: 'Type de ligne de bordereau',
          link: 'castres/billing/pricescheduletype/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_LIST,
        },
        {
          name: 'Type de bordereau',
          link: 'castres/billing/priceschedulegrouptype/list',
          feature: ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_LIST,
        },
        {
          name: "Catégorie d'étude",
          link: 'castres/billing/studycategory/list',
          feature: ROLE.ADMIN_STUDY_REFERENCE_LIST,
        },
        {
          name: "Ligne de référence d'étude",
          link: 'castres/billing/referencestudyline/list',
          feature: ROLE.ADMIN_STUDY_REFERENCE_LIST,
        },
        {
          name: "Groupe de référence d'étude",
          link: 'castres/billing/referencestudygroup/list',
          feature: ROLE.ADMIN_STUDY_REFERENCE_LIST,
        },
      ],
    },
    {
      name: 'Comptabilité',
      subList: [
        {
          name: 'Codes Client',
          link: '/castres/client/clientcode/list',
          feature: ROLE.ADMIN_CONFIGURATION_CLIENTCODE_LIST,
        },
        {
          name: 'Taux de TVA',
          link: '/castres/billing/vat/list',
          feature: ROLE.ADMIN_CONFIGURATION_VAT_LIST,
        },
        {
          name: 'Modes de paiement',
          link: '/castres/billing/paymentmode/list',
          feature: ROLE.ADMIN_CONFIGURATION_PAYMENTMODE_LIST,
        },
        {
          name: 'Entités Juridiques',
          link: '/castres/client/legalentity/list',
          feature: ROLE.ADMIN_CONFIGURATION_LEGALENTITY_LIST,
        },
        {
          name: 'Comptes imputation Vente',
          link: '/castres/billing/account/list',
          feature: ROLE.ADMIN_CONFIGURATION_ACCOUNT_LIST,
        },
        {
          name: 'Comptes imputation Achat',
          link: '/castres/billing/accountorder/list',
          feature: ROLE.ADMIN_CONFIGURATION_ACCOUNTORDER_LIST,
        },
        {
          name: 'Tolérance',
          linkV1: 'castres/billing/tolerance/list',
          feature: ROLE.ADMIN_CONFIGURATION_TOLERANCE_LIST,
        },
      ],
    },
    {
      name: 'Configurations Externes',
      link: '/castres/configuration/settings/edit',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Cron Manager',
      linkV1: 'cron/',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Traduction Manager',
      linkV1: 'trans/',
      feature: ROLE.ADMIN_CONFIGURATION,
    },
    {
      name: 'MAJ Datas pour tablettes',
      link: 'majDatasTablet', //passe par le v1 pour appeler api/Apk/cron/generateCommonDataJson, TODO voir pour faire une page qui permette d'appeler et d'afficher des logs de cron en v2
      feature: ROLE.ADMIN_CONFIGURATION,
    },
  ],
};
