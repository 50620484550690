import { default as dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/fr';
import { UserSettings } from 'interface/User';
import { IdLabel } from '../interface/CommonType';
import { BASE_URL } from '../constant/API_URL';
import JSZip from 'jszip';

dayjs.extend(relativeTime); //pour affichage des dates en relatif
dayjs.extend(duration); //pour affichage des durées
dayjs.locale('fr'); // pour affichage en français

/**
 * Transforme un objet Date pour l'affchier en html au format DD/MM/YYYY
 * On peut ajouter l'heure avec le booleen 'withtime'
 * @param str Date ou string à afficher
 * @param withTime booleen pour afficher l'heure
 */
export const displayDate = (str?: string | Date, withTime = false): string => {
  return str ? dayjs(str).format('DD/MM/YYYY' + (withTime ? ' HH:mm' : '')) : '';
};

/**
 * Transforme un objet Date pour l'afficher en html au format DD/MM/YYYY hh:mm (UTC)
 * @param str la date à afficher
 * @param withTime booleen pour afficher l'heure
 */
export const displayUtcDate = (str?: string | Date, withTime = false): string => {
  const date = new Date(str ?? '');
  if (!Utils.isDate(date)) return '';
  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth() + 1; // + 1 car sinon janvier == 0
  const utcDay = date.getUTCDate();
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();
  const padNumber = (number: number): string => (number < 10 ? '0' : '') + number;
  let result = `${padNumber(utcDay)}/${padNumber(utcMonth)}/${utcYear}`;
  if (withTime) result += ` ${padNumber(utcHours)}:${padNumber(utcMinutes)}`;
  return result + ' (UTC)';
};

/**
 * Transforme un objet Date pour l'afficher en html au format ddd DD MMM HH:mm ou DD/MM/YYYY HH:mm:ss
 * @param str Date ou string à afficher
 * @param humanize booleen pour afficher la date au format ddd DD MMM HH:mm. L'année est ajoutée si elle est différente de l'année en cours
 */
export const displayDateTime = (str?: string, humanize = false): string => {
  let date = dayjs(str).toDate();
  if (!Utils.isDate(date)) return '';
  if (!humanize) return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
  else if (date.getFullYear() !== new Date().getFullYear()) return dayjs(date).format('ddd DD MMM YYYY HH:mm');
  else return dayjs(date).format('ddd DD MMM HH:mm');
};

/**
 * Affiche le nombre de jours qui nous sépare de la date actuelle
 * @param str Date ou string à afficher
 */
export const displayRelativeDate = (str?: string): string => (str ? dayjs(str).fromNow() : '');

/**
 * Affiche une durée entre une date de début et de fin, ou par rapport à la date actuelle si
 * aucune date de fin n'est précisée
 * @param start Date de début
 * @param stop Date de fin
 */
export const displayDuration = (start: string, stop?: string): string => {
  if (stop) {
    //terminé
    return dayjs.duration(dayjs(start).diff(stop)).humanize();
  } else {
    //en cours
    let duration = dayjs(start).fromNow();
    return duration.replace('il y a', 'depuis');
  }
};

type FormatType = 'default' | 'Hm' | 'Hms';

/**
 * Formats minutes and seconds with leading zeros if needed.
 * @param minutes Number of minutes.
 * @param seconds Number of seconds.
 * @param includeSeconds Whether to include seconds in the output.
 */
const formatTime = (minutes: number, seconds: number, includeSeconds: boolean): string => {
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return includeSeconds ? `${minutes}min ${formattedSeconds}s` : `${minutes}min`;
};

/**
 * Displays a duration from a number of seconds in different formats.
 * @param nbSeconds Number of seconds to display.
 * @param formatType The format type ('default', 'Hm', or 'Hms').
 */
export const displayDurationFromSeconds = (nbSeconds: number, formatType: FormatType = 'default'): string => {
  if (!nbSeconds) return '';
  if (nbSeconds < 60) return `${Math.floor(nbSeconds)}s`;

  const hours = Math.floor(nbSeconds / 3600);
  const minutes = Math.floor((nbSeconds % 3600) / 60);
  const seconds = Math.floor(nbSeconds % 60);

  switch (formatType) {
    case 'Hm':
      return hours > 0 ? `${hours}h ${formatTime(minutes, seconds, false)}` : formatTime(minutes, seconds, false);

    case 'Hms':
      return hours > 0 ? `${hours}h ${formatTime(minutes, seconds, true)}` : formatTime(minutes, seconds, true);

    default:
      if (nbSeconds < 3600) return formatTime(minutes, seconds, false);
      if (nbSeconds < 86400) return `${hours}h ${formatTime(minutes, seconds, false)}`;
      return dayjs.duration(nbSeconds, 'seconds').humanize();
  }
};

export const formatCurrency = (amount: number): string => {
  return amount !== 0
    ? amount.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      })
    : '- €';
};

/**
 * Transforme un objet Date pour l'afficher en html au format DD-MM-YYYY
 * @param date Date à afficher
 */
export const setToKebabDate = (date: Date): string => {
  if (!isDate(date)) return date?.toString();
  const day = (date.getDate() < 9 ? '0' : '') + date.getDate();
  const month = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1);
  return day + '-' + month + '-' + date.getFullYear();
};

/**
 * Transforme une string au format DD-MM-YYYY pour en déduire une date
 * @param stringDate la string à transformer
 */
export const getKebabDateToDate = (stringDate: string) => {
  const arr = stringDate.split('-');
  if (arr.length === 3) return new Date(arr[1] + '/0' + arr[0] + '/' + arr[2]);
};

/**
 * Transforme une string au format DD/MM/YYYY pour en déduire une date
 * @param strDate la string à transformer
 */
export const stringToDate = (strDate: string): Date => {
  const dateParts = strDate.split('/').map((x) => Number(x));
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
};

/**
 * Transforme un nombre X exprimé en minutes en un affichage HH:mm
 * @param minutes le nombre de minutes à transformer
 */
export const minuteToStringHour = (minutes: number): string => {
  if (!minutes) return '';
  const remainingMinutes = minutes % 60;
  const hours = (minutes - remainingMinutes) / 60;
  return hours + 'h' + (remainingMinutes < 10 ? '0' : '') + remainingMinutes;
};

/**
 * Transforme un nombre X exprimé en minutes en un affichage : 1.75 h (calcule décimale)
 * @param minutes
 */
export const minuteToDecimalHour = (minutes: number): string => {
  const remainingMinutes = minutes % 60;
  const hours = (minutes - remainingMinutes) / 60;
  const min = Math.round(remainingMinutes * 1.66667 + Number.EPSILON);
  return `${hours}.${min < 10 ? '0' + min : min} h`;
};

/**
 * Transforme un objet Date pour l'affchier en html au format HH:mm
 * @param date Date à afficher
 */
const dateToHourString = (date: Date): string => date.toTimeString().split(' ')[0];

/**
 * Tente de transformer une valeur en date, sinon renvoi undefined
 * @param date la valeur à transformer
 */
export const tryParseDate = (date: string | undefined | Date): Date | undefined => {
  if (!date || date === '') return undefined;
  if (isDate(date)) return date as Date;
  const dateParsed = new Date(date);
  return isDate(dateParsed) ? dateParsed : undefined;
};

/**
 * Test si l'objet en entrer est un objet de type Date valide (donc pas 1970-01-01T00:00:00.000Z ni null, etc...)
 * @param x l'objet à tester
 */
export const isDate = (x: any): boolean => x instanceof Date && !isNaN(x.getTime());

/**
 * Test si la date n'est pas une date par défaut (01/01/01 ou 01/01/1970)
 * @param x la date à tester
 */
export const isDateValid = (x: Date): boolean => {
  if (!isDate(x)) return false;
  if (isNaN(x.getTime()) || x.getTime() === -62135596800000) return false;
  return x.getTime() !== 0;
};

// Liste des jours de la semaine au format IdLabel
export const dayOfTheWeek: Array<IdLabel> = [
  { id: 1, label: 'Lundi' },
  { id: 2, label: 'Mardi' },
  { id: 3, label: 'Mercredi' },
  { id: 4, label: 'Jeudi' },
  { id: 5, label: 'Vendredi' },
  { id: 6, label: 'Samedi' },
  { id: 0, label: 'Dimanche' },
];

// Liste des mois de l'année au format IdLabel
export const monthOfTheYear: Array<IdLabel> = [
  { id: 1, label: 'Janvier' },
  { id: 2, label: 'Février' },
  { id: 3, label: 'Mars' },
  { id: 4, label: 'Avril' },
  { id: 5, label: 'Mai' },
  { id: 6, label: 'Juin' },
  { id: 7, label: 'Juillet' },
  { id: 8, label: 'Août' },
  { id: 9, label: 'Septembre' },
  { id: 10, label: 'Octobre' },
  { id: 11, label: 'Novembre' },
  { id: 12, label: 'Décembre' },
];

/**
 * Renvoi le temps entre deux dates au format HH:mm
 * @param start la date de début
 * @param end la date de fin
 */
export const timeBetweenDates = (start: Date, end: Date) =>
  minuteToStringHour((end.getTime() - start.getTime()) / 60000);

/**
 * Renvoi le jour de la semaine en français à partir d'un objet Date
 * @param day Le jour à transformer
 */
export const getDayOfTheWeek = (day: Date): string => dayOfTheWeek.find((x) => x.id === day.getDay())?.label ?? '';

/**
 * Renvoi le numéro de la semaine dans l'année (entre 1 et 53)
 * @param date la date à transformer
 */
export const getWeekNumber = (date: Date): number => {
  if (!isDate(date)) return -1;
  const fourthDay = new Date(date.getFullYear(), 0, 4);
  const pastDaysOfYear = (date.getTime() - fourthDay.getTime() - 86400000) / 86400000;
  return Math.ceil((pastDaysOfYear + fourthDay.getDay() + 1) / 7);
};

/**
 * Renvoi le mois de l'année en français à partir d'un objet Date
 * @param date la date à transformer
 */
export const displayTime = (date: Date | string): string => {
  if (typeof date === 'string') date = new Date(date);
  if (!date) return '';
  const minute = date.getMinutes();
  const hour = date.getHours();
  return (hour < 10 ? '0' : '') + hour + 'h' + (minute < 10 ? '0' : '') + minute;
};

/**
 * Renvoi un objet Date au format GmtIso (format international Gmt)
 * @param date la date à transformer
 */
export const toGmtIsoString = (date: Date | null | undefined): string => {
  if (!date) return '';
  const pad = function (num: number) {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds())
  );
};

/**
 * Permet de transformer tout les objets Date recu par le backend (Date en string à cause de JSON)
 * dans une liste d'objet, à partir d'une liste de paramètre à transformer
 * @param dataList la liste d'objet à transformer
 * @param dateAttributeList la liste des attributs à transformer dans les objets
 */
export const mapDateAttributesInArray = (dataList: Array<any>, dateAttributeList: Array<string>): Array<any> => {
  if (!dataList || !dateAttributeList) return dataList;
  for (let dateAttr of dateAttributeList)
    for (let data of dataList) data[dateAttr] = data[dateAttr] ? new Date(data[dateAttr]) : new Date(0);
  return dataList;
};

/**
 * Ajoute à une valeur numérique, les espacements marquant les milliers / millions / etc... (ex: 1 000 000)
 * @param val la valeur à transformer
 */
export const ThousandSpacing = (val: string | number): string => {
  let retVal = val ? parseFloat(val.toString().replace(/,/g, '')) : 0;
  return retVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

/**
 * Permet de télécharger un fichier à partir d'un blob (déclenche le téléchargement)
 * @param target le blob(fichier) à télécharger ou l'url du fichier
 * @param filename le nom du fichier à télécharger (l'extension est obligatoire)
 */
export const downloadFile = (target: Blob | string | undefined, filename: string) => {
  if (!target || (target as any).size === 0) return;
  const url = typeof target === 'string' ? target : window.URL.createObjectURL(new Blob([target]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

/**
 * Renvoi les paramètres de préférence de l'utilisateur (stoker dans le localStorage)
 */
export const getUserSettings = (): UserSettings | null => {
  const userSettingsStr = localStorage.getItem('userSettings');
  if (userSettingsStr) {
    const userSettings: UserSettings = JSON.parse(userSettingsStr);
    return userSettings || null;
  }
  return null;
};

/**
 * Mets à jours les paramètre de préférence de l'utilisateur (stoker dans le localStorage)
 * @param property le nom de la propriété à mettre à jour
 * @param value la valeur de la propriété à mettre à jour
 */
export const updateUserSettings = (property: string, value: any) => {
  if (property) {
    const userSettingsStr = localStorage.getItem('userSettings');
    const userSettings: UserSettings = userSettingsStr ? JSON.parse(userSettingsStr) : {};
    userSettings[property] = value;
    localStorage.setItem('userSettings', JSON.stringify(userSettings));
  }
};

/**
 * Vérifie si deux Date sont bien du même jour (supprime les heures / minutes / etc)
 * @param date1 première date à comparer
 * @param date2 deuxième date à comparer
 */
export function isSameDay(date1: Date | string | undefined, date2: Date | string | undefined): boolean {
  if (!date1 || !date2) return false;
  if (!(date1 instanceof Date)) date1 = new Date(date1);
  if (!(date2 instanceof Date)) date2 = new Date(date2);
  if (!date1 || !date2) return false;
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

/**
 * Ordonne une liste en fonction de l'attribut spécifié
 * @param list la liste à trier
 * @param attr l'attribut à utiliser pour le tri
 * @param reverse si true, le tri est inversé
 */
export function orderListByAttr<T>(list: Array<T>, attr: string, reverse = false): Array<T> {
  if (list?.length < 1) return [];
  const newList = Object.assign([], list);
  if (reverse) newList.sort((a, b) => (a[attr] < b[attr] ? 1 : b[attr] < a[attr] ? -1 : 0));
  else newList.sort((a, b) => (a[attr] > b[attr] ? 1 : b[attr] > a[attr] ? -1 : 0));
  return newList;
}

/**
 * Réconcilie deux tableaux de donnée, attention, toutes les données doivent impérativement avoir un attribut 'id'
 * @param arr1 le tableau à mettre à jour
 * @param arr2 le tableau contenant les nouvelles données
 */
export function arrayReconciliation(arr1: Array<any>, arr2: Array<any>) {
  if (!arr1 || !arr2) return [];
  const result = [];
  for (const item of arr1) result[item.id] = item;
  for (const item of arr2) result[item.id] = item;
  return [...result].filter((x) => x !== undefined);
}

/**
 * Permets de savoir si toute les valeurs d'un tableau est bien contenue dans un autre
 * TODO: Peut être perfectionner pour faire du deepEqual afin de vérifier les attribut des objet.
 * @param arr1 le premier tableau à comparer
 * @param arr2 le deuxième tableau à comparer
 */
export function areArrayEquals(arr1: Array<any>, arr2: Array<any>): boolean {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1?.length !== arr2?.length) return false;
  for (const item of arr1) if (!arr2.includes(item)) return false;
  return true;
}

/**
 * Mets automatiquement en majuscule la première lettre de la chaîne de caractère donnée
 * @param string la chaîne de caractère à transformer
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Mets automatiquement en minuscule la première lettre de la chaîne de caractère donnée
 * @param string la chaîne de caractère à transformer
 */
export function lowerCaseFirstLetter(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

/**
 * Détermine si une couleur est considérée comme 'foncé' ou 'claire'.
 * Pour cela, on calcule la moyenne des valeurs RGB de la couleur et on considère que si elle est inférieur à 26, la couleur est foncée.
 * @param hexColor la couleur à tester
 */
export function isHexColorDark(hexColor: string): boolean {
  if (!hexColor.match(/^#[0-9a-fA-F]{3,6}$/)) throw new Error("Ceci n'est pas une couleur");
  hexColor = hexColor.match(/^#./) ? hexColor.substring(1) : hexColor;
  let result: number;
  if (hexColor.length === 3) {
    result = parseInt(hexColor[0], 16) + parseInt(hexColor[1], 16) + parseInt(hexColor[2], 16);
    return result < 26;
  }
  result =
    parseInt(hexColor.substring(0, 2), 16) +
    parseInt(hexColor.substring(2, 4), 16) +
    parseInt(hexColor.substring(4, 6), 16);
  return result / 16 < 26;
}

export interface GroupByOutput {
  // L'identifiant du groupe
  id: string | number;
  // Le libellé du groupe
  label: string;
  // La liste des éléments du groupe
  list: Array<any>;
}

/**
 * Groupe les élément d'une liste en fonction d'un attribut spécifier
 * Le format de sortit est une liste d'objet contenant {id, label, list};
 * @param list la liste à grouper
 * @param selector l'attribut à utiliser pour grouper
 * @param selectorLabel l'attribut à utiliser pour le libellé du groupe
 * @param otherLabel le libellé à utiliser pour les éléments qui n'ont pas d'attribut spécifié
 */
function groupBySelector(
  list: Array<any>,
  selector: string | number,
  selectorLabel: string | null,
  otherLabel = 'Autres',
): Array<GroupByOutput> {
  if (!list || list.length === 0) return [];
  const result: Array<GroupByOutput> = [];
  for (let item of list) {
    if (!item[selector]) item[selector] = 'other';
    if (!result.find((x) => x.id === item[selector]))
      result.push({ id: item[selector], label: item[selectorLabel ?? ''] ?? otherLabel, list: [] });
    result.find((x) => x.id === item[selector])?.list.push(item);
  }
  return result;
}

/**
 * Groupe les éléments d'une liste en fonction d'un attribut spécifier, méthode plus simple que groupBySelector mais
 * avec moins de possibilité de personnalisation (le format de sortie est différent)
 * @param list la liste à grouper
 * @param key l'attribut à utiliser pour grouper
 */
function groupBy(list: any[], key: string): any {
  if (!list || list.length === 0) return [];
  return list.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
}

/**
 * Supprime les doublons d'une liste d'objet, le selector détermine d'attribut qui doit être unique
 * @param list la liste à traiter
 * @param selector l'attribut à utiliser pour déterminer si un élément est unique
 * @param returnWholeObject renvoi l'objet complet ou juste l'attribut spécifié
 */
function distinct(list: Array<any>, selector?: string, returnWholeObject = false): Array<any> {
  if (!list || list.length === 0) return [];
  // @ts-ignore
  if (!selector) return [...new Set(list)];
  if (returnWholeObject) {
    // @ts-ignore
    return [...new Set(list.map((x) => x[selector]))].map((x) => list.find((y) => y[selector] === x));
  }
  // @ts-ignore
  if (selector.indexOf('.') === -1) return [...new Set(list.map((x) => x[selector]))];
  const splitSel = selector.split('.');
  // @ts-ignore
  return [...new Set(list.map((x) => x[splitSel[0]][splitSel[1]]))];
}

/**
 * Dans une liste, remplace les attributs de type date (en string du json généralement) par des objets Date.
 * Attention, ne vérifie pas si l'attribut est bien une date, il faut donc s'assurer que l'attribut est bien une date avant de l'utiliser.
 * @param list la liste à traiter
 * @param attrDateList la liste des attributs à traiter
 * @param dateOnly si true (par défaut), ne prends en compte que la date et pas l'heure
 */
function parseToDateInList<T>(list: Array<T>, attrDateList: Array<string>, dateOnly = false): Array<T> {
  const result = new Array<T>();
  for (const originalItem of list) {
    const item: any = Object.assign({}, originalItem);
    for (let attr of attrDateList) {
      const dateToFill = new Date(item[attr]);
      if (!isDate(dateToFill) || dateToFill.getTime() < 1) item[attr] = undefined;
      else item[attr] = dateOnly ? pureDate(dateToFill) : dateToFill;
    }
    result.push(item);
  }
  return result;
}

/**
 * Génère une chaîne de caractère aléatoire
 * @param length taille de la chaîne à générer
 * @param type le type de chaîne à générer (string, number, all)
 */
function generateRandomString(length = 10, type: 'string' | 'number' | 'all' = 'all'): string {
  let library = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  if (type === 'string') library = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  else if (type === 'number') library = '0123456789';
  const libraryLength = library.length;
  let result = '';
  for (let i = 0; i < length; i++) result += library[Math.floor(Math.random() * libraryLength)];
  return result;
}

/**
 * Remplace les numéro de téléphone 'collé' par leurs équivalent avec des espaces
 * le numéro doit être de la forme 0612345678 pour être transformé en 06 12 34 56 78
 * @param phoneNumber le numéro à transformer
 */
function phoneNumber(phoneNumber: string): string {
  if (!phoneNumber?.match(/^[0-9]{10}$/g)) return phoneNumber;
  return phoneNumber.replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
}

/**
 * Transforme un objet en un string de query url {toto:'tutu', tata: 5} => ?toto=tutu&tata=5
 * Attention, transforme tous les attributs sans exception.
 * @param attrObj l'objet à transformer
 */
function queryAttr(attrObj?: Object): string {
  if (!attrObj) return '';
  let query = '?';
  for (const [key, value] of Object.entries(attrObj))
    query += `${key}=${isDate(value) ? toGmtIsoString(value) : value}&`;
  return query.slice(0, -1);
}

/**
 * Transforme un tableau relationnel en une string de query url [toto:'tutu', tata: 5] => ?toto=tutu&tata=5
 * @param list le tableau à transformer
 * @param accessor l'attribut à utiliser pour l'accessor
 * @param value l'attribut à utiliser pour la valeur
 */
function queryAccessorInArray(list: Array<any>, accessor = 'accessor', value = 'value'): string {
  if (!list || !Array.isArray(list)) return '';
  let query = '?';
  for (const item of list) {
    if (!item[accessor] || !item[value]) continue;
    query += `${item[accessor]}=${isDate(item[value]) ? toGmtIsoString(item[value]) : item[value]}&`;
  }
  return query.slice(0, -1);
}

function queryObject(item: object) {
  if (!item) return '';
  let query = '?';
  for (const [key, value] of Object.entries(item))
    query += `${key}=${isDate(value) ? toGmtIsoString(value as any) : value}&`;
  return query.slice(0, -1);
}

/**
 * Transforme une string en un slug: To to$to => to-toto
 * @param toSlug la string à transformer
 */
function slugify(toSlug: string): string {
  let result = toSlug.replace(/[&/\\#,+()$~%.'":*?<>{}_]/g, '');
  result = result.replace(/[ ]{1,10}/g, '-').toLowerCase();
  return result.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Ajoute X heures à une date
 * @param date la date à traiter
 * @param hours le nombre d'heure à ajouter
 */
const addHours = (date: string | Date, hours: number): Date => {
  if (typeof date === 'string') date = new Date(date);
  return new Date(date.getTime() + 3600000 * hours);
};

/**
 * Renvoie une date à minuit UTC (compense le décalage horaire)
 * @param date la date à traiter
 */
const convertDateToUtc = (date?: Date | string): Date | undefined => {
  if (!date) return;
  if (typeof date === 'string') date = new Date(date);
  return new Date(date.setHours(0, -date.getTimezoneOffset(), 0, 0));
};

/**
 * Ajoute X jours à une date
 * @param date la date à traiter
 * @param days le nombre de jours à ajouter
 */
const addDays = (date: string | Date, days: number): Date => {
  if (typeof date === 'string') date = new Date(date);
  return new Date(date.getTime() + 86400000 * days);
};

/**
 * Transforme 300 minutes en 05:00 heures
 * @param time le temps à transformer
 * @param separator le séparateur à utiliser
 */
const intTimeToString = (time: number, separator: string = ':'): string => {
  if (!time) return `00${separator}00`;
  const hours = Math.floor(+time / 60);
  const minutes = time % 60;
  return `${hours < 10 ? '0' + hours : hours}${separator}${minutes < 10 ? '0' + minutes : minutes}`;
};

/**
 * Transforme 05:00 heures en 300 minutes
 * @param time le temps à transformer
 * @param separator le séparateur à utiliser
 */
const stringTimeToInt = (time: string, separator: string = ':'): number => {
  if (!time) return 0;
  if (Number.isInteger(time)) return +time;
  const split = time.split(separator);
  return +split[0] * 60 + +split[1];
};

/**
 * Ajoute X mois à une date
 * @param date la date à traiter
 * @param months le nombre de mois à ajouter
 */
const addMonth = (date: Date, months: number): Date => {
  const tempDate = new Date(date);
  const d = tempDate.getDate();
  tempDate.setMonth(tempDate.getMonth() + +months);
  if (tempDate.getDate() !== d) tempDate.setDate(0);
  return tempDate;
};

/**
 * Retourne la date sans l'heure
 * @param date la date à traiter
 */
const pureDate = (date: Date): Date => (!date ? date : new Date(date.setHours(0, 0, 0, 0)));

// Return a date in the format 'YYYY-MM-DD'
const dateOnlyFormat = (date?: Date): string => {
  if (!date) return '';
  const withoutOffset = setHours(date, -date.getTimezoneOffset() / 60);
  return withoutOffset.toISOString().split('T')[0];
};

/**
 * Redéfinie l'heure d'une journée
 * @param date la date à traiter
 * @param hours l'heure à définir
 */
const setHours = (date: string | Date, hours: number): Date => {
  if (typeof date === 'string') date = new Date(date);
  date.setHours(hours, 0, 0, 0);
  return date;
};

/**
 * Redéfinie la date à la fin de la journée à 23h59 (utile pour les filtres/requêtes)
 * @param date la date à traiter
 */
const setDateToEndOfDay = (date: string | Date): Date => {
  if (typeof date === 'string') date = new Date(date);
  date.setHours(23, 59, 59, 0);
  return date;
};

/**
 * Créer une "copie légère" de tous les objets d'une liste
 * @param list la liste à cloner
 */
const cloneList = (list?: any[]): any[] => {
  if (!list) return [];
  const result: any[] = [];
  for (let item of list) result.push({ ...item });
  return result;
};

/**
 * Renvoi le temps en milliseconde depuis le 01/01/70 à partir d'une date
 * @param date la date à traiter
 */
function dateOrStringDateToGetTime(date: Date | string | number): number {
  if (typeof date === 'number') return date;
  if (typeof date === 'string') return new Date(date).getTime();
  if (typeof date.getMonth === 'function') return date.getTime();
  return 0;
}

/**
 * Permet de déterminer si deux durées sont en collision
 * @param startX la date de début de la première durée
 * @param endX la date de fin de la première durée
 * @param startY la date de début de la deuxième durée
 * @param endY la date de fin de la deuxième durée
 */
function isDateSpanColliding(startX: any, endX: any, startY: any, endY: any): boolean {
  const sx = dateOrStringDateToGetTime(startX);
  const ex = dateOrStringDateToGetTime(endX);
  const sy = dateOrStringDateToGetTime(startY);
  const ey = dateOrStringDateToGetTime(endY);
  return sx < ey && ex > sy;
}

/**
 * Créer l'url d'un blob pour permettre le téléchargement ou l'affichage (images par exemple)
 * @param blob le blob à transformer
 */
function createPicUrl(blob: Blob | undefined): string {
  if (!blob) return '';
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator.createObjectURL(blob);
}

/**
 * Ouvre un lien dans un nouvel onglet
 * @param link le lien à ouvrir
 * @param focus détermine si l'onglet doit être mis en avant
 */
function openInNewTab(link: string, focus = false): void {
  if (!link.includes('http')) link = BASE_URL + link;
  const win = window.open(link, '_blank');
  if (focus) win?.focus();
}

/**
 * Ouvre un lien dans le même onglet
 * @param link le lien à ouvrir
 */
function openInSameTab(link: string): void {
  if (!link.includes('http')) link = BASE_URL + link;
  window.open(link, '_self');
}

interface DownLoadProps {
  // Nom du fichier
  fileName: string;
  // Le blob à télécharger
  blob: Blob;
}

/**
 * Transforme une liste de blob en un zip pour être téléchargé (utilise JSZip)
 * @param blobList la liste de blob à télécharger
 * @param zipName le nom du zip
 */
async function downLoadList(blobList: DownLoadProps[], zipName: string) {
  const zip = new JSZip();
  for (let blob of blobList) zip.file(blob.fileName, blob.blob);
  const content = await zip.generateAsync({ type: 'blob' });
  downloadFile(content, zipName);
}

/**
 * Génère un Id unique par rapport à une liste donnée (vérification sur l'attribut spécifié pour l'unicité)
 * @param list la liste à vérifier
 * @param attr l'attribut à vérifier
 */
function getRandomUniqId(list: any[], attr: string): string {
  if (!list || list.length === 0) return generateRandomString();
  for (let i = 0; i < 100; i++) {
    const uniqId = generateRandomString();
    if (list.find((x) => x[attr] === uniqId) == null) return uniqId;
  }
  return 'Fail to generate random string';
}

// Récupère la durée entre deux dates en : 'millisecond' | 'second' | 'minute' | 'hour' | 'day'
/**
 * Récupère la durée entre deux dates en : 'millisecond' | 'second' | 'minute' | 'hour' | 'day'
 * @param start la date de début
 * @param end la date de fin
 * @param timing le type de temps à récupérer : 'millisecond' | 'second' | 'minute' | 'hour' | 'day'
 */
function getTimeSpan(
  start: Date,
  end: Date,
  timing: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' = 'millisecond',
): number {
  if (!isDate(start)) {
    console.error('getTimeSpan => start is not a date');
    return 0;
  }
  if (!isDate(end)) {
    console.error('getTimeSpan => end is not a date');
    return 0;
  }
  const timeSpam = end.getTime() - start.getTime();
  switch (timing) {
    case 'day':
      return timeSpam / 86400000;
    case 'hour':
      return timeSpam / 3600000;
    case 'minute':
      return timeSpam / 60000;
    case 'millisecond':
      return timeSpam;
  }
  return timeSpam;
}

/**
 * Renvoi l'url de l'icone ou la couleur de l'environnement
 * Utile pour mieux différencier les environnements (local, develop, preprod, prod)
 * @param theme le thème de l'application
 * @param color détermine si on veut l'icone ou la couleur
 */
function getEnvIconUrl(theme: any, color = false): string {
  const hostname = window.location.hostname;
  const isLight = theme.get().palette.mode === 'light';
  if (hostname.includes('cms-develop')) return color ? (isLight ? '#447dff' : '#001090') : '/env_icon/develop.svg';
  if (hostname.includes('cms-preprod')) return color ? (isLight ? '#e25eff' : '#89008e') : '/env_icon/preprod.svg';
  if (!hostname.includes('cms.')) return color ? (isLight ? '#a8ffa1' : '#209300') : '/env_icon/localhost.svg';
  if (color) return isLight ? '#cecece' : '#37384a';
  return isLight ? '/logo_dark.svg' : '/logo.svg';
}

/**
 * Détermine si l'utilisateur est sur un mobile ou un écran de taille réduite
 */
function isMobileBrowser(): boolean {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return true;
  return window.innerWidth <= 800 && window.innerHeight <= 600;
}

/**
 * Réapplique les attributs d'un nouvelle objet sur un ancien
 * @param old l'ancien objet
 * @param newObject le nouvel objet
 */
function remapOldToNew(old: any, newObject: any): any {
  for (let key in old) if (newObject[key] != null) newObject[key] = old[key];
  return newObject;
}

/**
 * Ouvre un nouvel onglet avec un pdf (pour utiliser le lecteur pdf du navigateur)
 * @param path le chemin du pdf
 * @param name le nom du pdf (affiché dans le titre de l'onglet)
 */
function openPdfInNewTabWithTitle(path: string, name: string) {
  const newTab = window.open();
  newTab?.document.write(
    `<html><head><title>${name}</title></head><body><embed width="100%" height="100%" name="plugin" src="${path}"
    type="application/pdf" internalinstanceid="21"></body></html>`,
  );
  newTab?.document.close();
}

/**
 * Permet de transformer un string en nombre si possible, sinon renvoye le string
 * @param str le string a parse
 */
function tryParseNumber(str: string): number | string {
  const parsed = parseInt(str);
  return isNaN(parsed) ? str : parsed;
}

// Librairie CMS Utils, rajouter tous nouveaux composants ici pour unifier les appels
const Utils = {
  groupBySelector,
  groupBy,
  displayDate,
  toGmtIsoString,
  displayDateTime,
  setToKebabDate,
  getKebabDateToDate,
  stringToDate,
  minuteToStringHour,
  minuteToDecimalHour,
  timeBetweenDates,
  getDayOfTheWeek,
  displayRelativeDate,
  displayDuration,
  displayDurationFromSeconds,
  getWeekNumber,
  isDate,
  displayTime,
  mapDateAttributesInArray,
  ThousandSpacing,
  downloadFile,
  downLoadList,
  getUserSettings,
  updateUserSettings,
  isSameDay,
  orderListByAttr,
  arrayReconciliation,
  capitalizeFirstLetter,
  lowerCaseFirstLetter,
  isHexColorDark,
  parseToDateInList,
  distinct,
  generateRandomString,
  getRandomUniqId,
  phoneNumber,
  dateToHourString,
  queryAttr,
  queryAccessorInArray,
  queryObject,
  slugify,
  cloneList,
  createPicUrl,
  openInNewTab,
  openInSameTab,
  areArrayEquals,
  getEnvIconUrl,
  isMobileBrowser,
  remapOldToNew,
  openPdfInNewTabWithTitle,
  tryParseNumber,
  Date: {
    isDateValid,
    displayUtcDate,
    addHours,
    addDays,
    addMonth,
    getTimeSpan,
    pureDate,
    dateOnlyFormat,
    setHours,
    setDateToEndOfDay,
    tryParseDate,
    dateOrStringDateToGetTime,
    isDateSpanColliding,
    getDayOfTheWeekList: () => dayOfTheWeek,
    getMonthOfTheYearList: () => monthOfTheYear,
    stringTimeToInt,
    toUtc: convertDateToUtc,
    intTimeToString,
  },
};

export default Utils;
