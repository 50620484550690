import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppBar, Badge, Collapse, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import { CMSNotification, NotifCategories, NotificationContext } from '../../context/Notification.context';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { API_URL } from '../../constant/API_URL';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Utils, { GroupByOutput } from '../../helper/Utils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NotificationService from '../../service/NotificationService';
import { InputUI, UI } from '../../component/shared';
import AccessFilter from '../../helper/AccessFilter';
import { DebouncedInput } from '../../component/shared/FormUi';
import './Notification.scss';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { GlobalContext } from '../../context/Global.context';
import themingService from '../../component/ThemingService';
import CmsIcon from '../../component/shared/CmsIcon';
import { authenticationService } from '../../service/Authentication.service';
import ROLE from '../../constant/role.constant';

/**
 * Composant du bouton de navigation menant à l'ouverture de panneau de notification
 * @param onClick Fonction de callback pour ouvrir le panneau de notification
 */
export const NotificationNavigationButton: FC<{ onClick: any }> = ({ onClick }) => {
  const { notifCategories, setOpen, isOpen } = useContext(NotificationContext);
  const handleOpen = () => {
    onClick(!isOpen);
    setOpen(!isOpen);
  };

  return (
    <ListItem button onClick={handleOpen}>
      <ListItemIcon>
        <Badge color="primary" badgeContent={notifCategories?.newNotif?.length ?? 0} max={99}>
          <MailIcon />
        </Badge>
      </ListItemIcon>
      <ListItemText style={{ whiteSpace: 'nowrap' }} primary="Notification" />
    </ListItem>
  );
};

/**
 * Composant du panneau de notification affichant les modes de notification (thème, historique, urgence)
 * @param value Valeur du mode de notification
 * @param onChange Fonction de callback pour changer le mode de notification
 * @param nbrUrgency Nombre d'urgence
 */
export const NotificationModSelector: FC<{ value: number; onChange: any; nbrUrgency: number }> = ({
  value,
  onChange,
  nbrUrgency,
}) => {
  const options = [
    { id: 0, label: 'Thème' },
    { id: 1, label: 'Historique' },
    { id: 2, label: 'Urgences', badge: true, badgeValue: nbrUrgency },
  ];
  return <UI.SelectBetweenButton options={options} value={value} onChange={onChange} />;
};

/**
 * Ajoute une bulle d'information avec un titre et une couleur
 * @param title Titre de la bulle
 * @param color Couleur de la bulle
 */
const addChip = (title: string, color: string) => (
  <>
    <UI.SquareChip color={color} />
    {title}
  </>
);

export const NotificationTypeList = (): Array<any> => {
  const status = themingService.get().cms.notif.status;
  return [
    { id: 0, label: addChip('Info', status.info), color: status.info, show: true },
    { id: 1, label: addChip('Succès', status.success), color: status.success, show: true },
    { id: 2, label: addChip('Attention', status.warning), color: status.warning, show: true },
    { id: 3, label: addChip('Urgence', status.emergency), color: status.emergency, show: true },
    { id: 4, label: 'Personnel', color: status.staff, show: false },
    { id: 5, label: 'Admin', color: status.admin, show: false },
  ];
};

/**
 * Composant de la liste des types de notification (panneau latéral droit)
 */
export const NotificationComponent: FC = () => {
  const navigate = useNavigate();
  const { notifCategories, setNotification, setOpen, isOpen } = useContext(NotificationContext);
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [mod, setMod] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const latestNotif = useRef<any | NotifCategories>(null);
  const nbrUrgency = notifCategories.newNotif.filter((x) => x.type === 3)?.length ?? 0;
  latestNotif.current = notifCategories;
  const { theming } = useContext(GlobalContext);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(API_URL + 'notificationHub', {
        accessTokenFactory(): string | Promise<string> {
          return authenticationService.getCurrentUser()?.accessToken;
        },
      })
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (!connection) return;
    if (connection.state !== 'Disconnected') return;
    connection
      .start()
      .then(() => {
        connection.on('ReceiveMessage', (message: CMSNotification) => {
          const updatedNewNotification = [message, ...latestNotif.current?.newNotif];
          setNotification({
            newNotif: updatedNewNotification,
            oldNotif: latestNotif.current?.oldNotif ?? [],
          });
        });
      })
      .catch((e: any) => console.log('Connection failed: ', e));
  }, [connection, notifCategories, setNotification]);

  if (!isOpen) return <></>;

  const gotoDashboard = () => {
    navigate('/castres/notification/dashboard');
    setOpen(false);
  };

  return (
    <div className="notification-container" style={{ backgroundColor: theming.get().cms.main.navOpened }}>
      <div className="notification-title" style={{ color: theming.get().palette.text.primary }}>
        <h2>Notifications</h2>
        <IconButton
          className="new-notif-icon"
          title="Envoyer une notification"
          onClick={() => setOpenModal(true)}
          size="large"
        >
          <AddIcon />
        </IconButton>
        <IconButton className="dashboard-icon" title="Centre de notification" onClick={gotoDashboard} size="large">
          <OpenInNewIcon />
        </IconButton>
        <IconButton title="Fermer" onClick={() => setOpen(false)} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <div className="history" style={{ color: theming.get().palette.text.primary }}>
        <NotificationModSelector value={mod} onChange={setMod} nbrUrgency={nbrUrgency} />
      </div>
      <NotificationScrollableBody mod={mod} notifList={notifCategories.newNotif} />
      <SendNotifDialog openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

/**
 * Composant du corps de la liste des notifications
 * @param mod Mode de notification
 * @param notifList Liste des notifications
 * @param isDashBoard Si on est sur le dashboard
 * @param isOldNotif Si on est sur les anciennes notifications
 */
export const NotificationScrollableBody: FC<{
  mod: number;
  notifList: Array<CMSNotification>;
  isDashBoard?: boolean;
  isOldNotif?: boolean;
}> = ({ mod, notifList, isDashBoard = false, isOldNotif = false }) => {
  const historizedList = Utils.orderListByAttr(notifList, 'createdAt', true);
  let dangerList = historizedList.filter((x) => x.type === 3);
  dangerList = Utils.orderListByAttr(dangerList, 'createdAt', true);
  const displayedList = Utils.groupBySelector(historizedList, 'familyId', 'familyLabel');

  return (
    <div className="notification-list styled-scroll">
      {mod === 2 &&
        dangerList &&
        dangerList.map((notif, index) => (
          <NotificationCard notification={notif} key={index} isDashBoard={isDashBoard} isOldNotif={isOldNotif} />
        ))}
      {mod === 1 &&
        historizedList &&
        historizedList.map((notif, index) => (
          <NotificationCard notification={notif} isDashBoard={isDashBoard} isOldNotif={isOldNotif} key={index} />
        ))}
      {mod === 0 &&
        displayedList &&
        displayedList.map((group: GroupByOutput, index) => (
          <SubMenu key={index} group={group} isDashBoard={isDashBoard} isOldNotif={isOldNotif} />
        ))}
    </div>
  );
};

/**
 * Composant de la liste des notifications d'un groupe (sous-menu)
 * @param group Groupe de notifications (personnel, absence, outils...)
 * @param isOldNotif Si on est sur les anciennes notifications
 * @param isDashBoard Si on est sur le dashboard
 */
const SubMenu: FC<{ group: GroupByOutput; isDashBoard: boolean; isOldNotif: boolean }> = ({
  group,
  isOldNotif,
  isDashBoard,
}) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const { notifCategories, setNotification, channel } = useContext(NotificationContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { theming } = useContext(GlobalContext);

  const setAllReaded = () => {
    const idListToArchive = [];
    for (const notif of group.list) idListToArchive.push(notif.id);
    CRUD.put(APIRoute.UsNotification, idListToArchive).then(() => {
      const newNotif = notifCategories.newNotif.filter((x) => !group.list.includes(x));
      const oldNotif = [...group.list, ...notifCategories.oldNotif];
      setNotification({ newNotif, oldNotif });
      channel.postMessage({ newNotif, oldNotif });
    });
    handleClose();
  };

  const setAllDelete = () => {
    const idListToDelete = [];
    for (const notif of group.list) idListToDelete.push(notif.id);
    CRUD.deletes(APIRoute.UsNotification, idListToDelete).then(() => {
      NotificationService.info(`Les messages du group ${group.label} ont été supprimé.`);
      const oldNotif = notifCategories.oldNotif.filter((x) => !group.list.includes(x));
      setNotification({ newNotif: notifCategories.newNotif, oldNotif });
      channel.postMessage({ newNotif: notifCategories.newNotif, oldNotif });
    });
  };

  return (
    <div className="sub-menu">
      <AppBar position="static">
        <Toolbar variant="dense" style={{ backgroundColor: theming.get().cms.main.sectionTitle }}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick} size="large">
            <MenuIcon style={{ color: theming.get().palette.text.primary }} />
          </IconButton>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {!isOldNotif && <MenuItem onClick={setAllReaded}>Marquer tout ce thème comme lu</MenuItem>}
            {isOldNotif && <MenuItem onClick={setAllDelete}>Supprimer tous ces messages</MenuItem>}
          </Menu>
          <Typography variant="h6" style={{ color: theming.get().palette.text.primary }}>
            {group.label}
          </Typography>
          <UI.DeployIcon value={isOpen} onchange={setOpen} />
        </Toolbar>
      </AppBar>
      <Collapse in={isOpen}>
        {group?.list &&
          group.list.map((notif, index) => (
            <NotificationCard notification={notif} isDashBoard={isDashBoard} isOldNotif={isOldNotif} key={index} />
          ))}
      </Collapse>
    </div>
  );
};

/**
 * Affichage d'une notification dans la liste (tuile)
 * @param notification Notification à afficher
 * @param isDashBoard Si on est sur le dashboard
 * @param isOldNotif Si on est sur les anciennes notifications
 */
const NotificationCard: FC<{ notification: CMSNotification; isDashBoard: boolean; isOldNotif: boolean }> = ({
  notification,
  isDashBoard,
  isOldNotif,
}) => {
  const { id, title, urlAction, message, createdAt } = notification;
  const { notifCategories, setNotification, channel } = useContext(NotificationContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = useNavigate();
  const { theming } = useContext(GlobalContext);

  const deleteNotif = () => {
    CRUD.deleteById(APIRoute.UsNotification, notification.id).then(() => {
      const newNotif = notifCategories.newNotif.filter((x) => x.id !== id);
      const oldNotif = notifCategories.oldNotif.filter((x) => x.id !== id);
      setNotification({ newNotif, oldNotif });
      channel.postMessage({ newNotif, oldNotif });
    });
    handleClose();
  };

  const markAsReaded = () => {
    CRUD.put(APIRoute.UsNotification, [id]).then(() => {
      const newNotif = notifCategories.newNotif.filter((x) => x.id !== id);
      const oldNotif = [notification, ...notifCategories.oldNotif];
      setNotification({ newNotif, oldNotif });
      channel.postMessage({ newNotif, oldNotif });
    });
    handleClose();
  };

  const goto = () => {
    markAsReaded();
    if (!urlAction || urlAction === '') return;
    let url = urlAction;
    if (url.indexOf('localhost:3000/') !== -1) url = url.split('localhost:3000/')[1];
    else if (url.indexOf('.rousseau-batiment.com/') !== -1) url = url.split('.rousseau-batiment.com/')[1];
    if (url.charAt(0) === '/') url = url.substr(1);
    navigate('/' + (url ?? 'homepage'));
    window.location.reload();
  };

  const handleDownload = () => {
    if (!notification.attachementName) return;
    CRUD.getBlob(APIRoute.UsNotification + '/Attachment', { id: notification.id }).then((blob) => {
      if (blob) Utils.downloadFile(blob, notification.attachementName as string);
    });
  };

  const backgroundColor = NotificationTypeList()[notification?.type ?? 3]?.color;

  return (
    <div
      className="notification-card"
      style={{ borderColor: backgroundColor, color: theming.get().palette.text.primary }}
    >
      <div className="notif-title-container">
        <div className="flex-h-bet">
          <h3>
            <UI.TextEllipse text={title} />
          </h3>
          <small>({Utils.displayRelativeDate(createdAt)})</small>
        </div>
        {(isDashBoard && <span>{message}</span>) || <small>{message}</small>}
      </div>
      <IconButton className="more" aria-label="settings" onClick={handleClick} size="small">
        <CmsIcon icon="moreVertical" />
      </IconButton>
      {notification.attachementName && (
        <CmsIcon
          className="download"
          icon="download"
          textPos="left"
          tooltip={notification.attachementName}
          onClick={handleDownload}
        />
      )}
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {urlAction && <MenuItem onClick={goto}>Aller à</MenuItem>}
        {!isOldNotif && <MenuItem onClick={markAsReaded}>Marqué comme lu</MenuItem>}
        <MenuItem onClick={deleteNotif}>Supprimer</MenuItem>
      </Menu>
    </div>
  );
};

/**
 * Modale permettant d'envoyer une notification à un utilisateur
 * @param openModal Si la modale est ouverte
 * @param setOpenModal Fonction permettant de fermer la modale
 */
export const SendNotifDialog: FC<{ openModal: boolean; setOpenModal: any }> = ({ openModal, setOpenModal }) => {
  const [firstCall, setFirstCall] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [isUrgentMessage, setUrgentMessage] = useState<boolean>(false);
  const [userIdList, setUserIdList] = useState<Array<number> | number>();
  const [familyId, setFamilyId] = useState<number>(1);
  const [filterState, setFilterState] = useState<any>({});
  const [stateList, setStateList] = useState<any>();
  const [attachment, setAttachment] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSendToAll, SetIsSendToAll] = useState<boolean>(false);
  const isUserMaster = useMemo(() => AccessFilter([ROLE.NOTIFICATION_MASTER]), []);
  const haveNoRight = useMemo(
    () => !AccessFilter([ROLE.NOTIFICATION_MASTER, ROLE.NOTIFICATION_OPERATOR, ROLE.NOTIFICATION_LIST]),
    [],
  );

  useEffect(() => {
    if (!firstCall || !openModal) return;
    setFirstCall(false);
    CRUD.getCustomObject<any>(APIRoute.UsNotification + '/GetAllData').then(setStateList);
  }, [firstCall, openModal]);

  const handleSendToAllSwitch = () => {
    setUserIdList(isSendToAll ? [] : stateList.userList.map((x: any) => x.id));
    SetIsSendToAll(!isSendToAll);
  };

  let disableValidation = !message || message.length < 3 || (Array.isArray(userIdList) && userIdList.length < 1);
  const handleSubmit = () => {
    if (disableValidation) return;
    setLoading(true);
    const payload = { message, usNotifConfUserIdList: userIdList, isUrgentMessage, url, familyId, attachment };
    CRUD.postFormData(APIRoute.UsNotification, payload)
      .then(() => {
        NotificationService.success('Notification envoyé');
        setOpenModal(false);
      })
      .finally(() => setLoading(false));
  };

  const handleFilterChange = (filter: any) => {
    if (typeof userIdList === 'number') return;
    let result = [];
    const allFilters = { ...filterState, ...filter };
    const userList: any[] = stateList?.userList ?? [];
    if (allFilters.serviceId && allFilters.serviceId.length > 0)
      result = userList.filter((x) => allFilters.serviceId.includes(x.serviceId));
    if (allFilters.legalEntityId && allFilters.legalEntityId.length > 0)
      result = [...result, ...userList.filter((x) => allFilters.legalEntityId.includes(x.legalEntityId))];
    if (allFilters.agencyId && allFilters.agencyId.length > 0)
      result = [...result, ...userList.filter((x) => allFilters.agencyId.includes(x.agencyId))];
    if (allFilters.groupId && allFilters.groupId.length > 0)
      result = result.filter((x: any) => allFilters.groupId?.some((y: any) => x.groupIdList?.includes(y)));
    setUserIdList(Utils.distinct(result.map((x: any) => x.id)));
    setFilterState(allFilters);
  };

  return (
    <UI.Dialog maxWidth="xl" onClose={() => setOpenModal(false)} open={openModal}>
      <UI.Paper
        isLoading={loading || !stateList}
        style={{ marginBottom: 0 }}
        title="Envoyer une notification"
        actions={[<CmsIcon icon="close" onClick={() => setOpenModal(false)} />]}
      >
        <Grid container spacing={2} justifyContent="center">
          {!isSendToAll && !haveNoRight && (
            <Grid item style={{ width: '25rem' }}>
              <InputUI.AutoCompletor
                label="Entité légale"
                value={filterState.legalEntityId}
                onChange={(legalEntityId: any) => handleFilterChange({ legalEntityId })}
                options={stateList?.legalEntityList ?? []}
                multiple
              />
              <InputUI.AutoCompletor
                label="Agence"
                value={filterState.agencyId}
                onChange={(agencyId: any) => handleFilterChange({ agencyId })}
                options={stateList?.agencyList ?? []}
                multiple
              />
              <InputUI.AutoCompletor
                label="Service"
                value={filterState.serviceId}
                onChange={(serviceId: any) => handleFilterChange({ serviceId })}
                options={stateList?.serviceList ?? []}
                multiple
              />
              <InputUI.AutoCompletor
                label="Groupe (filtre les champs précédents)"
                value={filterState.groupId}
                onChange={(groupId: any) => handleFilterChange({ groupId })}
                options={stateList?.groupList ?? []}
                multiple
              />
              <p>Sélectionner un filtre réinitialisera la liste des destinataires.</p>
            </Grid>
          )}
          <Grid item style={{ width: '50rem' }}>
            <DebouncedInput label="Message" required multiline value={message} onChange={setMessage} />
            <DebouncedInput label="Url (facultatif)" value={url} onChange={setUrl} />
            {!isSendToAll && (
              <InputUI.AutoCompletor
                multiple
                label="Destinataire"
                required
                value={userIdList}
                onChange={setUserIdList}
                options={stateList?.userList ?? []}
                optionLabel="getFormattedName"
              />
            )}
            {isUserMaster && (
              <>
                <InputUI.AutoCompletor
                  label="Famille de notification"
                  value={familyId}
                  onChange={(familyId: any) => setFamilyId(familyId)}
                  options={stateList?.notificationTypeList ?? []}
                />
                <InputUI.InputFile label="Pièce jointe" onFileSelected={setAttachment} />
                <InputUI.CmsSwitch value={isSendToAll} label="Envoyer à tous" onChange={handleSendToAllSwitch} inline />
                <InputUI.CmsSwitch value={isUrgentMessage} label="Message Urgent" onChange={setUrgentMessage} inline />
              </>
            )}
          </Grid>
        </Grid>
        <UI.Divider />
        <UI.Button color="primary" variant="outlined" onClick={handleSubmit} disabled={disableValidation}>
          Envoyer une notification
        </UI.Button>
      </UI.Paper>
    </UI.Dialog>
  );
};

export default NotificationComponent;
