import React, { FC, useState } from 'react';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import APIRoute from '../../../constant/API.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import ROLE from '../../../constant/role.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { BlReferenceStudyLine } from '../../../interface/BlType';

const BlReferenceStudyLineListConfigList: CmsColumnDef<BlReferenceStudyLine>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Unité', id: 'unitLabel', Filter: CmsTableFilter.Select },
  { header: 'Catégorie', id: 'categoryLabel', Filter: CmsTableFilter.Select },
  { header: 'Article', id: 'articleLabel', Filter: CmsTableFilter.Select },
  { header: "Type de main d'oeuvre", id: 'jobLabel', Filter: CmsTableFilter.Number },
  { header: 'Commentaire', id: 'comment', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'Perte', id: 'lossFactor', Filter: CmsTableFilter.Number },
  { header: "Coùt main d'oeuvre", id: 'manpowerTime', Filter: CmsTableFilter.Number },
  { header: 'Coùt machine', id: 'machineCost', Filter: CmsTableFilter.Number },
];

export const BlReferenceStudyLineList: FC = () => {
  return (
    <CmsFrontendTable
      route={APIRoute.BlReferenceStudyLine}
      columns={BlReferenceStudyLineListConfigList}
      title="Liste des lignes de référence d'études"
      navigateTo={(id: number) => `/castres/billing/referencestudyline/${id}/edit`}
      actions={[
        <AddNavigationButton
          title="Ajouter une ligne de référence d'étude"
          to="/castres/billing/referencestudyline/create"
          access={[ROLE.ADMIN_STUDY_REFERENCE_EDIT]}
        />,
      ]}
      setFiltersInUrl
    />
  );
};

export const BlReferenceStudyLineCreateEdit: FC = ({ id }: any) => {
  const [state, setState] = useState<any>();
  const props = { currentUrl: '/castres/billing/referencestudyline/', route: APIRoute.BlReferenceStudyLine };
  return (
    <div className="cms-grid auto2">
      <CmsForm
        id={id}
        {...props}
        title="Catégorie d'étude"
        onChange={(x) => (!state && !!x.blReferenceStudyLineCostHistory ? setState(x) : undefined)}
        renderForm={(form) => (
          <>
            <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
            <CmsFormInput.Select id="jobId" label="Type de main d'oeuvre" options={APIRoute.WkJob + '/Simplified'} />
            <CmsFormInput.Select id="unitId" label="Unité" options={APIRoute.WkUnit + '/Simplified'} />
            <CmsFormInput.Suggest
              id="articleId"
              label="Article fournisseur"
              route={APIRoute.SkArticle}
              defaultValueHolder={form.watch().article?.label}
            />
            <CmsFormInput.Select
              id="categoryId"
              label="Catégorie d'étude"
              options={APIRoute.BlStudyCategory + '/Simplified'}
            />
            <CmsFormInput.Number id="lossFactor" label="Taux de perte" />
            <CmsFormInput.Number id="manpowerTime" label="Coût temps homme" />
            <CmsFormInput.Number id="machineCost" label="Coût machine" />
            <CmsFormInput.Number id="articleQuantity" label="Quantité d'article" />
          </>
        )}
      />
      <div>
        {state && (
          <CmsFrontendTable
            columns={historyConf}
            title="Historique de la ligne de référence d'étude"
            route="none"
            controlledState={{
              state: state.blReferenceStudyLineCostHistory,
              setState: (x: any) => setState({ ...state, blReferenceStudyLineCostHistory: x }),
            }}
          />
        )}
      </div>
    </div>
  );
};

const historyConf: CmsColumnDef<BlReferenceStudyLine>[] = [
  { header: 'Date de mise à jour', id: 'startDate', Filter: CmsTableFilter.Date },
  { header: "Type de main d'oeuvre", id: 'jobLabel', Filter: CmsTableFilter.Number },
  { header: 'Perte', id: 'lossFactor', Filter: CmsTableFilter.Number },
  { header: "Coùt main d'oeuvre", id: 'manpowerTime', Filter: CmsTableFilter.Number },
  { header: 'Coùt machine', id: 'machineCost', Filter: CmsTableFilter.Number },
];
