import React, { useEffect, useState } from 'react';
import { authenticationService } from '../service/Authentication.service';
import CRUD from '../service/CRUD.service';
import APIRoute from '../constant/API.constant';

export interface CMSNotificationState {
  // Est-ce que la notification est ouverte
  isOpen: boolean;
}

export interface CMSNotification {
  // Identifiant de la notification
  id: number;
  // Est-ce que la notification est archivée
  archived: boolean;
  // Titre de la notification
  title: string;
  // Type de la notification
  type: number;
  // Message de la notification
  message: string;
  // Date de création de la notification
  createdAt: string;
  // Identifiant de la famille de notification (ex: asbence, user, tool, ...)
  familyId: number;
  // Nom de la famille de notification (ex: asbence, user, tool, ...)
  familyLabel: string;
  // Si une url est associée à la notification, on propose un bouton pour y accéder
  urlAction?: string;
  attachementName?: string;
}

/**
 * Classe permettant de regrouper les notifications en fonction de si elle ont déjà été lues ou non
 */
export class NotifCategories {
  newNotif: Array<CMSNotification>;
  oldNotif: Array<CMSNotification>;

  constructor() {
    this.newNotif = new Array<CMSNotification>();
    this.oldNotif = new Array<CMSNotification>();
  }
}

/**
 * Contexte permettant de gérer les notifications, création à vide
 */
const NotificationContext = React.createContext({
  notifCategories: new NotifCategories(),
  setNotification: (notification: NotifCategories) => {},
  isOpen: false,
  setOpen: (isOpen: boolean) => {},
  setTitle: (title: string) => {},
  channel: new BroadcastChannel('notification'),
});

/**
 * Provider permettant de gérer les notifications
 * La particularité de ce provider est qu'il va synchroniser les notifications avec tout les onglets ouverts,
 * ainsi, si une notification est lue dans un onglet, elle sera lue dans tout les autres
 * C'est également lui qui s'occupe de mettre à jour le titre de la page avec le nombre de notifications non lue
 * @param props uniquement le children qui sera passer à la suite
 */
const NotificationProvider = (props: { children: React.ReactNode }) => {
  const [notifCategories, setNotification] = useState<NotifCategories>(new NotifCategories());
  const [isOpen, setOpen] = useState<boolean>(false);
  const [channel] = useState<BroadcastChannel>(new BroadcastChannel('notification'));
  const newNotifCount = notifCategories?.newNotif?.length ?? 0;
  const routeTitle = document.title.split('CMS')[1] ?? '';
  document.title = (newNotifCount > 0 ? `(${newNotifCount}) ` : '') + `CMS${routeTitle}`;

  useEffect(() => {
    const userId = authenticationService.getCurrentUser().userId;
    CRUD.getList<CMSNotification>(APIRoute.UsNotification + '/' + userId).then((result) => {
      const newNotif = result.filter((x) => !x.archived);
      const oldNotif = result.filter((x) => x.archived);
      setNotification({ newNotif, oldNotif });
    });
    channel.addEventListener('message', (event) => {
      setNotification(event.data);
    });
  }, [setNotification, channel]);

  const setTitle = (title: string) => {
    document.title = (newNotifCount > 0 ? `(${newNotifCount})` : '') + ` CMS - ${title ?? ''}`;
  };

  return (
    <NotificationContext.Provider value={{ notifCategories, setNotification, setOpen, isOpen, channel, setTitle }}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, NotificationContext };
