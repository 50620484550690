import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { BlPriceSchedule, BlPriceScheduleChapter, BlPriceScheduleGroup } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import ROLE from '../../../constant/role.constant';

//#region BlPriceScheduleGroup

export const BlPriceScheduleGroupConfigList: CmsColumnDef<BlPriceScheduleGroup>[] = [
  { header: 'Référence', id: 'ref', Filter: CmsTableFilter.Search },
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Search },
  { header: 'Type', id: 'typeLabel', Filter: CmsTableFilter.Search },
  { header: 'Fournisseur', id: 'companyNameList' },
  { header: 'Actif', id: 'active', Filter: CmsTableFilter.Bool },
];

export const BlPriceScheduleGroupList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULE_CREATE])
    ? [<AddNavigationButton title="Ajouter un bordereau" to="/castres/billing/priceschedule/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des bordereaux"
      route={APIRoute.BlPriceScheduleGroup}
      columns={BlPriceScheduleGroupConfigList}
      navigateTo={(id: number) => `/castres/billing/priceschedule/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};
//#endregion

//#region BlPriceScheduleChapter

export const BlPriceScheduleChapterConfigList: CmsColumnDef<BlPriceScheduleChapter>[] = [
  { header: 'Référence', id: 'ref', Filter: CmsTableFilter.Search },
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Search },
  { header: 'Groupe', id: 'groupLabel', Filter: CmsTableFilter.Select },
];

export const BlPriceScheduleChapterList: FC = () => {
  return (
    <CmsFrontendTable
      title="Liste des groupes de bordereaux"
      route={APIRoute.BlPriceScheduleChapter}
      columns={BlPriceScheduleChapterConfigList}
      navigateTo={(id, cell) => `/castres/billing/priceschedule/${cell.row.original.groupId}/edit?chapter=${id}`}
      setFiltersInUrl
    />
  );
};
//#endregion

//#region BlPriceSchedule

const TypeEnumTypeCell = (value: any) => {
  return ['', "A l'acte", 'Annuel', 'Pour mémoire'][value ?? 0];
};

export const BlPriceScheduleConfigList: CmsColumnDef<BlPriceSchedule>[] = [
  { header: 'Référence', id: 'ref', Filter: CmsTableFilter.Search },
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Search },
  { header: 'Unité', id: 'unit', Filter: CmsTableFilter.Select },
  { header: 'Prix', id: 'price', cell: (x) => `${x.getValue()}€` },
  { header: 'Chapitre', id: 'chapterLabel', Filter: CmsTableFilter.Select },
  { header: 'Groupe', id: 'groupLabel', Filter: CmsTableFilter.Select },
  { header: 'Type', id: 'typeId', cell: (x) => TypeEnumTypeCell(x.getValue()) },
];

export const BlPriceScheduleList: FC = () => {
  return (
    <CmsFrontendTable
      title="Liste des lignes de bordereaux"
      route={APIRoute.BlPriceSchedule}
      columns={BlPriceScheduleConfigList}
      navigateTo={(id, cell) =>
        `/castres/billing/priceschedule/${cell.row.original.groupId}/edit?chapter=${cell.row.original.chapterId}&priceschedule=${id}`
      }
      setFiltersInUrl
    />
  );
};
//#endregion
